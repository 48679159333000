import { Component, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  factoryAddress: string = '';
  accessControlAddress: string = '';
  borrowLendAddress: string = '';
  exchangeAddress: string = '';
  lazyMintAddress: string = '';
  erc721Address: string = '';
  explorerUrl: string = '';
  ownerAddress: string = '';

  /**
  * Constructor
  */
  constructor(
    private toastr: ToastrService,
    private clipboardservice: ClipboardService,
    private contractIntegrationService: ContractIntegrationsService,
  ) {

  }

  /**
   * on init
   */
  public ngOnInit() {
    this.contractIntegrationService.owner().then((response: any) => {
      this.factoryAddress = environment[environment.SUPPORTED_CHAINS[0]].FACTORY_ADDRESS;
      this.accessControlAddress = environment[environment.SUPPORTED_CHAINS[0]].ACCESS_CONTROL_TOKEN;
      this.borrowLendAddress = environment[environment.SUPPORTED_CHAINS[0]].BORROW_LEND_CONTRACT;
      this.exchangeAddress = environment[environment.SUPPORTED_CHAINS[0]].EXCHANGE_CONTRACT;
      this.lazyMintAddress = environment[environment.SUPPORTED_CHAINS[0]].LAZYMINT_CONTRACT;
      this.erc721Address = environment[environment.SUPPORTED_CHAINS[0]].ERC721_CONTRACT;
      this.explorerUrl = environment[environment.SUPPORTED_CHAINS[0]].EXPLORER_URL + '/address/';
      this.ownerAddress = response;
    })
  }

  /**
   * Copy address
   * @param {string} address 
   */
  copyAddress(address: string) {
    this.clipboardservice.copyFromContent(address);
    this.toastr.success('Copied address');
  }
}
