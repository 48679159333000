import { Component, ErrorHandler } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { FeeManagementService } from 'src/app/services/fee-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { pagination } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const web3 = new Web3(window['ethereum']);


@Component({
  selector: 'app-partner-fees-management',
  templateUrl: './partner-fees-management.component.html',
  styleUrls: ['./partner-fees-management.component.scss']
})
export class PartnerFeesManagementComponent {
  partnerFeesForm: FormGroup;
  partnerFeesFormSubmitted: boolean;
  partnerFeesFormLoader: boolean = false;
  partnerFeeEdit: boolean = false;
  partnerFeeEditId: any;
  public transactionHash: string = '';
  partnerFees: any = [];
  public partnerFeesLoader: boolean = true;
  public account: any = {};
  regulated: boolean = false
  fees: any;
  public existPlatformFee: number;
  public existRoyaltyFee: number;
  public transhUrl: string = '';
  paginationsDetails:pagination;
  currentPageLimit:number = 10;

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private contractIntegrationService: ContractIntegrationsService,
    private toastr: ToastrService,
    private storageService: StorageService,
    private feeManagementService: FeeManagementService,
    private manageTransactionsDirective:ManageTransactionsDirective,
    private errorHandler: ErrorHandler
  ){

  }

  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);


    this.partnerFeesForm = this.formBuilder.group({
      partner: ['', [Validators.required]],
      platformFee: ['', [Validators.required]],
      royaltyFee: ['', [Validators.required]],
      creatorFee: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
      clientFee: ['', [Validators.pattern("^[0-9]*$"), Validators.required]]
    });
    this.getPartnerFees(1,this.currentPageLimit);
    setTimeout(() => {
      this.transhUrl = environment[this.account.state.data.chain.id].TRANSH_URL
    }, 1000);
  }

  get partnerFeesFormGet() {
    return this.partnerFeesForm.controls;
  }


  /**
   * Partners fees form submit
   * @returns  
   */
  async partnerFeesFormSubmit() {
    this.partnerFeesFormSubmitted = true;
    if (this.partnerFeesForm.invalid) {
      return;
    }
    const totalFee = +this.partnerFeesForm.controls.creatorFee.value + +this.partnerFeesForm.controls.clientFee.value;
    if (+totalFee != 100) {
      this.toastr.error('The Creator and Client Total fee should be 100%.');
      return;
    }
    this.partnerFeesFormLoader = true;
    let fees = {
      partner: this.partnerFeesForm.controls.partner.value,
      platform_fee: Math.round((this.partnerFeesForm.controls.platformFee.value) * (100)),
      royalty_fee: Math.round((this.partnerFeesForm.controls.royaltyFee.value) * (100)),
      creator_royalty_fee: this.partnerFeesForm.controls.creatorFee.value,
      client_royalty_fee: this.partnerFeesForm.controls.clientFee.value,
      use_partner_fee: true
    }
    this.setPartnerFees(fees);
  }

  /**
   * Sets partner fees
   * @param fees 
   */
  async setPartnerFees(fees) {
    try{
    const { feesAbi, requiredGas } = await this.contractIntegrationService.setPartnerFees(fees, this.account.state.data.account);
    const message = {
      to: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
      data: feesAbi,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(requiredGas * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async (receipt) => {
        if(this.partnerFeeEdit) this.updatePartnerFees(fees);
        else this.addPartnerFees(fees);
        this.transactionHash = receipt['data']['transactionHash'];
      })
      .catch((error) => {
        this.partnerFeesFormLoader = false;
      });
    }
    catch(error){
      this.partnerFeesFormLoader = false;
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Adds partner fees
   * @param fees 
   */
  addPartnerFees(fees) {
    this.feeManagementService.addPartnerFees(fees).subscribe({
      next: async (_data: any) => {
        this.toastr.success('Fees added successfully.');
        this.partnerFeesFormLoader = false;
        this.partnerFeesForm.reset();
        this.partnerFeesFormSubmitted = false;
        this.getPartnerFees(1,this.currentPageLimit);
      },
      error: (error: any) => {
        this.partnerFeesFormLoader = true;
        this.toastr.error(error.error.message);
      },
    });
  }

  /**
   * Updates partner fees
   * @param fees 
   */
  updatePartnerFees(fees) {
    this.feeManagementService.updatePartnerFees(this.partnerFeeEditId, fees).subscribe({
      next: async (_data: any) => {
        this.partnerFeeEditId = '';
        this.partnerFeeEdit = false;
        this.toastr.success('Fees updated successfully.');
        this.partnerFeesFormLoader = false;
        this.partnerFeesForm.reset();
        this.partnerFeesFormSubmitted = false;
        this.getPartnerFees(1,this.currentPageLimit);
      },
      error: (error: any) => {
        this.partnerFeesFormLoader = true;
        this.toastr.error(error.error.message);
      },
    });
  }

  /**
   * Gets partner fees
   * @param {number} page
   * @param {number} limit
   */
  public getPartnerFees(page:number,limit:number) {
    this.partnerFeesLoader = true;
    this.feeManagementService.getPartnerFees(page,limit).subscribe({
      next: async (response: any) => {
        this.partnerFees = response.data.docs;
        this.paginationsDetails = response.data;
        this.partnerFeesLoader = false;
      },
      error: (error: any) => {
        this.partnerFeesLoader = false;
        this.partnerFeesFormLoader = true;
        this.toastr.error(error.error.message);
      },
    });
  }


  

  /**
   * Cancels edit
   */
  cancelEdit() {
    this.partnerFeeEdit = false;
    this.partnerFeeEditId = '';
    this.partnerFeesForm.reset();
  }

  /**
   * Edits partner fee
   * @param fee 
   */
  editPartnerFee(fee) {
    this.transactionHash = '';
    this.partnerFeeEdit = true;
    this.partnerFeeEditId = fee._id;
    this.partnerFeesForm.patchValue({
      partner: fee.partner,
      platformFee: Number(fee.platform_fee) / 100,
      royaltyFee: Number(fee.royalty_fee) / 100,
      creatorFee: fee.creator_royalty_fee,
      clientFee: fee.client_royalty_fee
    })
  }


  /**
   * Deletes partner fee
   * @param fee 
   */
  async deletePartnerFee(fee) {
    try{
    let fees = {
      partner: fee.partner,
      platform_fee: 0,
      royalty_fee: 0,
      creator_royalty_fee: 0,
      client_royalty_fee: 0,
      use_partner_fee: false
    }
    const { feesAbi, requiredGas } = await this.contractIntegrationService.setPartnerFees(fees, this.account.state.data.account);
    const message = {
      to: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
      data: feesAbi,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(requiredGas * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async (_receipt) => {
        this.feeManagementService.deletePartnerFees(fee._id).subscribe({
          next: async (_data: any) => {
            this.toastr.success('Deleted successfully.');
            this.getPartnerFees(1,this.currentPageLimit);
          },
          error: (error: any) => {
            this.partnerFeesFormLoader = true;
          },
        })
      })
      .catch((error) => {
        this.partnerFeesFormLoader = false;
      });
    }
    catch(error){
      this.partnerFeesFormLoader = false;
      this.errorHandler.handleError(error);
    }
  }

}
