import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    regulated: boolean = false;

    constructor(
        private storageService: StorageService
    ) { }

    ngOnInit(): void {
        this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    }
}
