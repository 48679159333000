import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NftTraitsService {

  /**
   * Creates an instance of nft traits service.
   * @param http
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Adds trait
   * @param params
   * @returns
   */
  public addTrait(params: any) {
    return this.http.post(`${environment.API_BASE_URL}/admin/create-nfttraits`, params);
  }

  /**
   * Gets all traits
   * @returns
   */
  public getAllTraits() {
    return this.http.get(`${environment.API_BASE_URL}/admin/nfttraits`);
  }

  /**
   * Updates trait
   * @param id
   * @param params
   * @returns
   */
  public updateTrait(id: string, params: any) {
    return this.http.patch(`${environment.API_BASE_URL}/admin/nfttrait?id=${id}`, params);
  }

  /**
   * Deletes trait
   * @param id
   * @returns
   */
  public deleteTrait(id: string) {
    return this.http.delete(`${environment.API_BASE_URL}/admin/nfttrait?id=${id}`);
  }

  /**
   * Deletes collections
   * @param {string} address 
   * @returns  
   */
  public deleteCollections(address: string) {
    return this.http.delete(`${environment.API_BASE_URL}/admin/collection?address=${address}`);
  }

}
