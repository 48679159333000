<div class="container"
  *ngIf="this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader class="container-fluid">
  <div class="m-header container-fluid">
    <a class="b-brand" href="fees-management">
      <img id="main-logo" *ngIf="showLogo" src="assets/images/logo.png" alt="" class="logo">
      <img *ngIf="!showLogo" src="assets/images/logo.png" alt="" class="logo logo-icone-img">
    </a>
    <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
      </svg>  
    </a>

      <app-nav-right [collapseStyle]="this.collapseStyle"></app-nav-right>
  </div>
   
  
</ng-template>
