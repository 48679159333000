<div class="section">
  <div class="container-fluid">
    <ul class="tab-menu">
      <li class="nav-item " [routerLink]="['/faq']" routerLinkActive="menuactivities"
        [routerLinkActiveOptions]="{exact: true}">FAQ management.</li>
      <li class="nav-item" [routerLink]="['/faq/category']">Category management.</li>
    </ul>
    <div class="row">
      <div class="col-lg-12">
        <app-card cardTitle="FAQ management." [options]="false">
          <div class="row">
            <div class="col-lg-12">
              <div class="position-abs">
                <select class="form-control category-filter" (change)="searchFilter($event.target['value'],'selected')">
                  <option value="">All.</option>
                  <option value="true">Checked.</option>
                  <option value="false">Unchecked.</option>
                </select>
                <select class="form-control category-filter" (change)="searchFilter($event.target['value'],'category')">
                  <option hidden value="" disabled selected>Filter by category</option>
                  <option value="">All</option>
                  <option *ngFor="let data of categoryData" value="{{data._id}}">{{data.category}}</option>
                </select>
                <input type="text" class="form-control category-filter" (keyup)="searchFilter($event.target['value'],'search')"
                  placeholder="Search">
                <button type="button" class="btn btn-green float-right mb-4 add-faq-btn"
                  (click)="faqModalOpen(faqmodal,'0')"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    fill="currentColor" viewBox="0 0 16 16">
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg> Add
                  FAQ.</button>
                <button type="button" class="btn btn-green float-right mb-4"
                  (click)="openChangeOrderPopup(changeOrderContent)"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  Change order.</button>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.no.</th>
                      <th>Question.</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="!loader">
                      <tr *ngFor="let data of faqData; let i = index">
                        <td>{{i + 1}}</td>
                        <td>{{data.question}}</td>
                        <td>
                          <div class="d-flex gap-2">
                            <button type="button" class="btn btn-warning position-relative"
                              (click)="faqModalOpen(faqmodal,data._id)"><svg xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16" fill="currentColor" viewBox="0 0 16 16" class="bi bi-pencil">
                                <path
                                  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325">
                                </path>
                              </svg><span class="edit-tooltip">Edit.</span></button>
                            <button type="button" class="btn btn-danger position-relative"
                              (click)="deleteFaq(data._id)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-trash3" viewBox="0 0 16 16">
                                <path
                                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                              </svg>
                              <span class="edit-tooltip">
                                Delete.
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="!faqData?.length  && !loader">
                      <td colspan="4" class="text-center">
                        No data found.
                      </td>
                    </tr>
                    <tr *ngIf="loader">
                      <td colspan="4" class="text-center">
                        <div class="loader-wrap">Loading. <span class="Loaders"></span></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <app-paginations *ngIf="faqData?.length" [isLoading]="loader" [paginationsDetails]="faqDataPaginations"
                  [currentPageLimit]="currentPageLimit"
                  (getDetails)="getFaq('','','',$event.page,$event.limit)"></app-paginations>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</div>

<!--start FAQ'S modal-->
<ng-template #faqmodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">FAQ'S.</h5>
    <a class="btn-close " aria-label="Close" (click)="modal.dismiss('Cross click');"><svg
        xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
      </svg></a>
  </div>
  <div class="modal-body">
    <form [formGroup]="faqForm" (ngSubmit)="faqSubmit()">
      <div class="row">
        <div class="col-12 mb-3">
          <div class="form-group">
            <label>Category. <label class="text-danger"> * </label> </label>
            <select class="form-control" formControlName="category">
              <option *ngFor="let data of categoryData" value="{{data._id}}">{{data.category}}</option>
            </select>
            <div *ngIf="faqFormSubmitted && faqFormGet.category.errors" class="invalid-feedback-custom">
              <small class="text-danger" *ngIf="faqFormGet.category.errors.required">Please select category.</small>
            </div>
          </div>
          <div class="form-group">
            <label>Question. <label class="text-danger"> * </label> </label>
            <input type="text" class="form-control" placeholder="Enter question." formControlName="question">
            <div *ngIf="faqFormSubmitted && faqFormGet.question.errors" class="invalid-feedback-custom">
              <small class="text-danger" *ngIf="faqFormGet.question.errors.required">Please enter
                question.</small>
            </div>
          </div>
          <div class="form-group">
            <label>Answer. <label class="text-danger"> * </label> </label>
            <textarea type="text" class="form-control" placeholder="Enter answer." formControlName="answer"></textarea>
            <div *ngIf="faqFormSubmitted && faqFormGet.answer.errors" class="invalid-feedback-custom">
              <small class="text-danger" *ngIf="faqFormGet.answer.errors.required">Please enter
                answer.</small>
            </div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="check" [ngModelOptions]="{standalone: true}"
                value="true" [(ngModel)]="isChecked">
              <label class="form-check-label" for="check">Selected.</label>
            </div>
          </div>
        </div>
        <div class="col-12 text-sm-left text-center">
          <div class="btn-group">
            <button type="submit" class="btn btn-green" *ngIf="!isUpdate && !faqLoader">Submit.</button>
            <button type="submit" class="btn btn-green" *ngIf="isUpdate && !faqLoader">Update.</button>
            <button class="btn btn-green" *ngIf="faqLoader" disabled>
              Processing. <span class="loader-white"></span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!--end FAQ'S modal-->

<!-- BEGIN Delete Confirmation Faq Modal-->
<ng-template #deleteFaqModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Delete FAQ'S.</h5>
    <a class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><svg
        xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
      </svg></a>
  </div>
  <div class="modal-body">
    <p>
      All values associated to this <span class="text-danger"></span> will be permanently
      deleted.
      <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Close.</button>
    <button type="button" class="btn btn-danger" *ngIf="!faqLoader">Delete.</button>
    <button class="btn btn-green" *ngIf="faqLoader" disabled>
      Processing.<span class="loader-white"></span>
    </button>
  </div>
</ng-template>
<!-- END Delete Confirmation Faq Modal-->


<!-- BEGIN FAQ ORDER CHANGE MODAL -->
<ng-template #changeOrderContent let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Change order.</h5>

    <a class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');closeCurrencyChangeOrderModal()"><svg
        xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
      </svg></a>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="load-section" *ngIf="faqChangeOrderLoader">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="list-group height-view" (drop)="drop($event)" (dragover)="allowDrop($event)">
          <div class="table-responsive">
            <table class="table table-bordered" [ngClass]="faqChangeOrderLoader ? 'table-disable' : ''"
              (click)="preventClick($event)">
              <thead>
                <tr>
                  <th>S.no.</th>
                  <th>Question.</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of faqData; let i = index">
                  <td>{{ i+1 }}</td>
                  <td class="cursor-pointer item-name" draggable="true" (dragstart)="dragStart($event, i)">
                    <span>{{ item.question }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- END FAQ ORDER CHANGE MODAL -->