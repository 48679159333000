<div class="section">
    <div class="container-fluid">
        <ul class="tab-menu">
            <li class="nav-item " [routerLink]="['/faq']" >Faq Management.</li>
            <li class="nav-item" [routerLink]="['/faq/category']" routerLinkActive="menuactivities">Category Management.</li>
          </ul>
      <div class="row">
        <div class="col-lg-12">
          <app-card cardTitle="Category Management." [options]="false">
            <div class="row">
              <div class="col-lg-12">
                <div class="position-abs">
                  <button type="button" class="btn btn-green float-right mb-4" (click)="modalOpen(categorymodal,'0')"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"  viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                  </svg> Add
                    Category.</button>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>S.no.</th>
                        <th>Category.</th>
                        <th>Action.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of categoryData; let i = index">
                        <td>{{i + 1}}</td>
                        <td>{{data.category}}</td>
                        <td>
                          

                          <div class="d-flex gap-2">
                            <button type="button" class="btn btn-warning position-relative"
                              (click)="modalOpen(categorymodal,data._id)"><svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" class="bi bi-pencil"><path  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path></svg><span class="edit-tooltip">Edit.</span></button>
                              <button type="button" class="btn btn-danger position-relative" (click)="deleteCategory(data._id)">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                    </svg>
                                    <span class="edit-tooltip">
                                      Delete.
                                  </span>
                              </button>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="categoryData.length == 0 && !loader">
                        <td colspan="4" class="text-center">
                          No data found.
                        </td>
                      </tr>
                      <tr *ngIf="loader">
                        <td colspan="4" class="text-center">
                          <div class="loader-wrap">Loading. <span class="Loaders"></span></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </div>
  </div>
  
  <!--start Category modal-->
  <ng-template #categorymodal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Category.</h5>
      <a  class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"  viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
      </svg></a>
    </div>
    <div class="modal-body">
      <form [formGroup]="categoryForm" (ngSubmit)="categorySubmit()">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="form-group">
              <label>Category. <label class="text-danger"> * </label> </label>
              <input type="text" class="form-control" placeholder="Enter category." formControlName="category">
              <div *ngIf="categoryFormSubmitted && categoryFormGet.category.errors" class="invalid-feedback-custom">
                <small class="text-danger" *ngIf="categoryFormGet.category.errors.required">Please enter category.</small>
              </div>
            </div>
          </div>
          <div class="col-12 text-sm-left text-center">
            <div class="btn-group">
              <button type="submit" class="btn btn-green" *ngIf="!isUpdate && !categoryLoader">Submit.</button>
              <button type="submit" class="btn btn-green" *ngIf="isUpdate && !categoryLoader">Update.</button>
              <button class="btn btn-green" *ngIf="categoryLoader" disabled>
                Processing. <span class="loader-white"></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <!--end category'S modal--> 
  
  <!-- BEGIN Delete Confirmation category Modal-->
  <ng-template #deletecategoryModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Delete category'S.</h5>
    <a class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"  viewBox="0 0 16 16">
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
    </svg></a>
  </div>
  <div class="modal-body">
    <p>
      All values associated to this <span class="text-danger"></span> will be permanently
      deleted.
      <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close.</button>
    <button type="button" class="btn btn-danger" *ngIf="!categoryLoader">Delete.</button>
    <button class="btn btn-green" *ngIf="categoryLoader" disabled>
      Processing. <span class="loader-white"></span>
    </button>
  </div>
  </ng-template>
  <!-- END Delete Confirmation category Modal-->