import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IApiResponse } from 'src/app/core/interfaces/app.interface';
import { FaqManagementService } from 'src/app/services/faq-management.service';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';
import { ICurrencyChangeOrder, pagination } from 'src/app/shared/interface/interface';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  public faqForm: FormGroup;
  public faqLoader: boolean = false;
  faqFormSubmitted: boolean = false;
  @ViewChild("deleteFaqModal") deleteFaqModal: TemplateRef<any>;
  @ViewChild("changeOrderContent", { static: false }) changeOrderContent: TemplateRef<any>;
  faqData = [];
  isUpdate: boolean = false;
  faqDataId: string = '';
  categoryData = [];
  isChecked: boolean = false;
  filterSelected: string = '';
  filterSearch: string = '';
  filterCategory: string = '';
  faqDataPaginations: pagination;
  loader: boolean = false;
  currentPageLimit: number = 10;
  faqChangeOrderLoader = false;
  draggedIndex = -1;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private faqManagementService: FaqManagementService,
    private errorHandler: ErrorHandlerDirective,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.faqForm = this.formBuilder.group({
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]],
      category: ['', [Validators.required]],
    })
    this.getFaq('', '', '', 1, this.currentPageLimit);
    this.getcategory();
  }

  get faqFormGet() {
    return this.faqForm.controls;
  }

  /**
   * Faqs modal open
   * @param {any} content 
   * @param {string} id 
   */
  public faqModalOpen(content: any, id: string) {
    if (id == '0') {
      this.isUpdate = false;
      this.faqForm.reset();
      this.isChecked = false;
    }
    else {
      this.faqManagementService.getFaqById(id).subscribe((response) => {
        this.faqDataId = response['data']._id
        this.faqForm.patchValue({
          question: response['data'].question,
          answer: response['data'].answer,
          category: response['data'].faq_category
        });
        this.isChecked = response['data'].selected;
      });
      this.isUpdate = true;
    }
    this.modalService.open(content, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
  }

  /**
   * Gets faq
   * @param {string} search 
   * @param {string} category 
   * @param {string} selected 
   * @param {number} page 
   * @param {number} limit 
   */
  getFaq(search: string, category: string, selected: string, page?: number, limit?: number) {
    this.loader = true;
    this.faqManagementService.getFaq(search, category, selected, page, limit).subscribe((response) => {
      this.faqData = response['data']?.docs?.sort((a, b) => a?.order - b?.order);
      this.faqDataPaginations = response['data'];
      this.loader = false;
    },
      (error) => {
        this.loader = false;
      })
  }
  /**
   * Faqs submit
   */
  public faqSubmit() {
    this.faqFormSubmitted = true;
    if (this.faqForm.invalid) {
      return
    }
    this.faqLoader = true;
    let data = {
      question: this.faqForm.value.question,
      answer: this.faqForm.value.answer,
      faq_category: this.faqForm.value.category,
      selected: this.isChecked,
    }
    let faqService;
    if (this.isUpdate) {
      faqService = this.faqManagementService.updateFaq(data, this.faqDataId)
    }
    else {
      faqService = this.faqManagementService.addFaq(data)
    }
    faqService.subscribe((response) => {
      this.toastr.success(response['message']);
      this.modalService.dismissAll();
      this.faqLoader = false;
      this.getFaq('', '', '', 1, this.currentPageLimit);
    },
      (error) => {
        this.faqLoader = false;
      }
    )


  }

  /**
   * Opens delete modal
   * @param {string} id 
   */
  deleteFaq(id: string) {
    this.faqLoader = true;
    Swal.fire({
      title: 'Are you sure want to Delete?',
      text: 'Click OK for delete',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      Swal.fire({
        onBeforeOpen: () => {
          Swal.showLoading()
        },
        allowOutsideClick: false,
        text: 'Processing, Please wait...',
      });
      if (willDelete.dismiss) {
        Swal.fire('', 'Delete cancelled', 'error');
      } else {
        this.deleteFaqProcess(id);
      }
    },
      (error) => {
        this.faqLoader = false;
        Swal.close();
      });
  }

  /**
   * Deletes faq process
   * @param {string} id 
   */
  deleteFaqProcess(id: string) {
    this.faqManagementService.deleteFaq(id).subscribe((response) => {
      this.toastr.success(response['message']);
      this.faqLoader = false;
      Swal.close();
      this.getFaq('', '', '', 1, this.currentPageLimit);
    },
      (error) => {
        this.faqLoader = false;
        Swal.close();
      })

  }

  /**
   * Gets category
   */
  getcategory() {
    this.faqManagementService.getCategory().subscribe((response) => {
      this.categoryData = response['data'];
    },
      (error) => {
      })
  }


  /**
   * Searchs filter
   * @param {string} value 
   * @param {string} type
   */
  searchFilter(value: string, type: string) {
    switch (type) {
      case 'selected': {
        this.filterSelected = value;
        break;
      }
      case 'category': {
        this.filterCategory = value;
        break;
      }
      case 'search': {
        this.filterSearch = value;
        break;
      }
      default: {
        this.filterSelected = '';
        this.filterCategory = '';
        this.filterSearch = '';
      }

    }
    this.getFaq(this.filterSearch, this.filterCategory, this.filterSelected, 1, this.currentPageLimit)
  }

  /**
 * close currency change order modal
 */
  closeCurrencyChangeOrderModal() {
    this.getFaq(this.filterSearch, this.filterCategory, this.filterSelected, 1, this.currentPageLimit)
    this.faqChangeOrderLoader = false;
  }


  /**
   * Prevents click
   * @param event 
   */
  preventClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
  /**
   * Drags start
   * @param {DragEvent} _event 
   * @param {number} index 
   */
  dragStart(_event: DragEvent, index: number) {
    this.draggedIndex = index;
  }

  /**
   * Allows drop
   * @param {DragEvent} event 
   */
  allowDrop(event: DragEvent) {
    event.preventDefault(); // Necessary to allow dropping
  }

  /**
   * Finds drop index
   * @param {DragEvent} event 
   * @returns drop index 
   */
  findDropIndex(event: DragEvent): number | undefined {
    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();
    const offsetY = event.clientY - rect.top;
    const targetIndex = this.faqData.findIndex(item => item.question === target.innerText);
    if (targetIndex >= 0) {
      const before = offsetY < rect.height / 2;
      if (before) {
        return targetIndex;
      } else {
        return Math.min(targetIndex + 1, this.faqData.length);
      }
    }
  }

  /**
   * Drops currency order list
   * @param {DragEvent} event 
   */
  drop(event: DragEvent) {
    this.faqChangeOrderLoader = true;
    event.preventDefault();
    const dropIndex = this.findDropIndex(event);
    if (dropIndex !== undefined && dropIndex !== this.draggedIndex) {
      const item = this.faqData[this.draggedIndex];
      this.faqData.splice(this.draggedIndex, 1); // Remove the item from its original position
      this.faqData.splice(dropIndex, 0, item); // Insert the item at its new position
      let newOrderArray: any = [];

      this.faqData.map((item, index) => {
        newOrderArray.push({
          order: item.order,
          _id: item._id,
          chain_id: item.chain_id
        })
        if (this.faqData.length == index + 1) {
          this.orderChangeProcess(newOrderArray);
        }
      })

    } else {
      this.faqChangeOrderLoader = false;
      this.draggedIndex = -1;
    }
  }

  /**
   * Orders change process
   * @param {ICurrencyChangeOrder} newOrderArray 
   */
  orderChangeProcess(newOrderArray: ICurrencyChangeOrder) {
    this.faqManagementService.changFAQOrder(newOrderArray).subscribe({
      next: (response: IApiResponse) => {
        this.faqChangeOrderLoader = false;
        this.toastr.success(response.message);
        this.draggedIndex = -1;
      },
      error: (error: HttpErrorResponse) => {
        this.faqChangeOrderLoader = false;
        this.toastr.error(error.message);
      }
    }
    )
  }

  /*** 
   * open modal
  */
  public openChangeOrderPopup(content: any) {
    this.getFaq(this.filterSearch, this.filterCategory, this.filterSelected)
    this.modalService.open(content, { size: 'xl', centered: true, backdrop: 'static', keyboard: false });
  }

}
