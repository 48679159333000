import { Component, ErrorHandler, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { StorageService } from 'src/app/services/storage.service';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { FeeHelper } from 'src/app/shared/helpers/fee-validation.helper';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const web3 = new Web3(window['ethereum']);

@Component({
  selector: 'app-royalty-splitter',
  templateUrl: './royalty-splitter.component.html',
  styleUrls: ['./royalty-splitter.component.scss'],
})
export class RoyaltySplitterComponent implements OnInit, OnDestroy {
  public account: any = {};
  regulated: boolean = false
  public royaltySplitterForm: FormGroup;
  public royaltySplitterFormSubmitted: boolean = false;
  public royaltySplitterFormLoader: boolean = false;
  public transactionHashRoyaltySplitter: string = '';
  public transhUrl: string = '';
  fees: any;
  public transactionHash: string = '';
  public feesFormLoader: boolean = false;
  public royaltyFormLoader: boolean = false;
  isDisabledForm: { [key: string]: boolean } = {
    'royaltyFee': false,
  }
  royaltyFeeFormSubscription!: Subscription;

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private contractIntegrationService: ContractIntegrationsService,
    private toastr: ToastrService,
    private manageTransactionsDirective: ManageTransactionsDirective,
    private errorHandler: ErrorHandler,
    private feeHelper: FeeHelper
  ) {

  }
  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);

    this.royaltySplitterForm = this.formBuilder.group({
      creatorFee: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
      clientFee: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
    });
    setTimeout(() => {
      this.getFeeDetails();
    }, 1000);
  }

  /**
   * Gets platform fee
   */
  public getFeeDetails() {
    this.contractIntegrationService.getFees(this.regulated).then((fees: any) => {
      this.fees = {
        platformFee: fees[0],
        royaltyFee: fees[1],
        creatorRoyaltyFee: fees[2],
        clientRoyaltyFee: fees[3]
      };
      this.royaltySplitterForm.patchValue({
        creatorFee: fees[2]
      })

      this.royaltySplitterForm.patchValue({
        clientFee: fees[3]
      })
    });
  }

  get royaltySplitterFormGet() {
    return this.royaltySplitterForm.controls;
  }

  public async royaltySplitterSubmit() {
    this.royaltySplitterFormSubmitted = true;
    if (this.royaltySplitterForm.invalid) {
      return;
    }
    const totalFee = +this.royaltySplitterForm.controls.creatorFee.value + +this.royaltySplitterForm.controls.clientFee.value;
    if (+totalFee != 100) {
      this.toastr.error('The Creator and Client Total fee should be 100%.');
      return;
    }
    else {
      this.royaltySplitterFormLoader = true;
      let fees = {
        platformFee: this.fees.platformFee,
        royaltyFee: this.fees.royaltyFee,
        creatorRoyaltyFee: this.royaltySplitterForm.controls.creatorFee.value,
        clientRoyaltyFee: this.royaltySplitterForm.controls.clientFee.value
      }
      this.setFees(fees);
    }
  }

  async setFees(fees: { platformFee: any; royaltyFee: any; creatorRoyaltyFee: any; clientRoyaltyFee: any; }) {
    try {
      const { feesAbi, requiredGas } = await this.contractIntegrationService.setFees(fees, this.account.state.data.account, this.regulated);
      const message = {
        to: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
        data: feesAbi,
        gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
        gasLimit: await web3.utils.toHex(requiredGas * 2)
      };
      this.manageTransactionsDirective.makeTransactions(message)
        .then(async (receipt) => {
          this.toastr.success('Fees updated successfully.');
          this.transactionHash = receipt['data']['transactionHash'];
          this.feesFormLoader = false;
          this.royaltyFormLoader = false;
          this.royaltySplitterFormLoader = false;
          this.getFeeDetails();
        })
        .catch((error) => {
          this.feesFormLoader = false;
          this.royaltyFormLoader = false;
          this.royaltySplitterFormLoader = false;
        });
    }
    catch (error) {
      this.feesFormLoader = false;
      this.royaltyFormLoader = false;
      this.royaltySplitterFormLoader = false;
      this.errorHandler.handleError(error);
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.royaltyFeeFormSubscription?.unsubscribe();
  }
  /**
   * Validate the royalty fee fields to check if it is valid
   * @param creatorFee The fee for the creator
   * @param clientFee The fee for the client
   *
   * This function checks if the fees are valid and if they are not, it will disable the form
   * until the user enters valid fees.
   */
  validationRoyaltyCheck(creatorFee: number, clientFee: number) {
    this.isDisabledForm['royaltyFee'] = this.feeHelper.validationCheck(creatorFee, clientFee);
  }
  /**
   * Calculates the creator royalty fee based on the client royalty fee and updates the 'creatorFee' form control
   * @param clientFee The client royalty fee to calculate the creator royalty fee from
   */
  patchCreatorFee(clientFee: number) {
    const creatorFee = this.feeHelper.calculateRoyaltyFee(clientFee);
    this.patchRoyaltyFee('creatorFee', Math.max(0, creatorFee));
  }
  /**
   * Calculates the client royalty fee based on the creator royalty fee and updates the 'clientFee' form control
   * @param creatorFee The creator royalty fee to calculate the client royalty fee from
   */
  patchClientFee(creatorFee: number) {
    const clientFee = this.feeHelper.calculateRoyaltyFee(creatorFee);
    this.patchRoyaltyFee('clientFee', Math.max(0, clientFee));
  }
  /**
   * Patches the value of the given form control in the royaltySplitterForm
   * @param formControlName The name of the form control to patch
   * @param feeValue The value to set the form control to
   */
  patchRoyaltyFee(formControlName: string, feeValue: number) {
    const formControl = this.royaltySplitterForm.get(formControlName);
    if (formControl) {
      formControl.patchValue(feeValue);
    }
  }
}

//226lines to 205 lines 