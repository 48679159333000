import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KycManagementService } from 'src/app/services/kyc-management.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-kyc-management',
    templateUrl: './kyc-management.component.html',
    styleUrls: ['./kyc-management.component.scss']
})
export class KycManagementComponent {

    kycStatus!: boolean;
    regulated: boolean = false;

    constructor(
        private kycManagementService: KycManagementService,
        private storageService: StorageService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.regulated = JSON.parse(this.storageService.getItem('regulated'));
        if (this.regulated) this.getKycStatus();
        else this.router.navigate(['/']);
    }

    /**
   * Confirms alert
   * @param {boolean} status
   */
    public kycUploadStatus(status: boolean) {
        this.kycStatus = status;
        let name = status ? 'Enabled' : 'Disabled';
        Swal.fire({
            title: `Are you sure you want to ${name} KYC verification for marketplace platform?`,
            text: `Click OK for KYC option ${name}`,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
        }).then((willDelete) => {
            Swal.fire({
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: false,
                text: 'Processing, Please wait...',
            });
            if (willDelete.dismiss) {
                Swal.fire('', `Not ${name}`, 'error');
                this.getKycStatus();
                this.kycStatus = !status;
            } else {
                this.kycUploadStatusProcess(status);
            }
        });

    }

    /**
     * Kycs upload status process
     * @param {string} status 
     */
    kycUploadStatusProcess(status: boolean) {
        let name = status ? 'Enabled' : 'Disabled';
        const data = {
            status: status
        }
        this.kycManagementService.updateKycStatus(data).subscribe((response) => {
            Swal.fire('', `${name} successfully`, 'success');
            this.getKycStatus();
        },
            (error) => {
                Swal.fire('', `Not ${name}`, 'error');
                this.getKycStatus();
                this.kycStatus = !status;
            }
        )
    }

    /**
     * Gets kyc status
     */
    getKycStatus() {
        this.kycManagementService.getKycStatus().subscribe((response) => {
            this.kycStatus = response['data']['kyc_enable'];
        })
    }
}
