import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class FeeHelper {
    validationCheck(creatorFee: number, clientFee: number) {
        const valuesToCheck = [100, 0];
        const conditions = {
            isMissingValues: !creatorFee || !clientFee,
            exceedsMaxValue: creatorFee > 100 || clientFee > 100,
            containsInvalidValues: valuesToCheck.includes(creatorFee) || valuesToCheck.includes(clientFee)
        };
        // Check all conditions using the object
        for (const condition in conditions) {
            if (conditions[condition]) {
                return true;
            }
        }
        // Enable the form if no conditions are met
        return false;
    }

    /**
     * Calculates the royalty fee as a percentage of 100
     * @param fee The client fee to calculate the royalty fee from
     * @returns The calculated royalty fee
     */
    calculateRoyaltyFee(fee: number) {
        const adjustedClientFee = fee < 0 ? 0 : fee;
        return 100 - adjustedClientFee;
    }

    checkZeroValues(fee: number) {
        return fee === 0 || !fee;
    }
}