import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { navItems } from './nav-data';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    navData = navItems;
}
