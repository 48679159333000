<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <app-card cardTitle="Collections list." [options]="false" [style]="'position:relative'"
                    id="collectionTab">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive">
                                <!-- collections -->
                                <table class="table">
                                    <thead>
                                        <th>S.no.</th>
                                        <th>Address.</th>
                                        <th>Name.</th>
                                        <th>Client address.</th>
                                        <th>Creator.</th>
                                        <th>Current owner.</th>
                                        <th>Total<br>item.</th>
                                        <th>Status.</th>
                                        <th>Actions.</th>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                    <tr *ngFor="let data of collections; trackBy: trackByFn">
                                        <td>{{data.id + 1}}</td>
                                        <td>
                                            <div class="d-flex gap-2 align-items-center">
                                                {{data.collection_address | contentSlice :0:5}}
                                                <span class="cursor-pointer copyfile" (click)="copyAddress(data.collection_address)"> <svg viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%" anim:duration="1" anim:ease="ease-in-out">
                    <g id="Frame">
                    <path id="Vector" d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path id="Vector_2" d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                    </svg></span>
                                            </div>
                                        </td>
                                        <td>{{data.name}}</td>
                                        <td>
                                            <div class="d-flex gap-2 align-items-center">
                                                {{data.owner_address | contentSlice :0:5}}
                                                <span class="cursor-pointer copyfile" (click)="copyAddress(data.owner_address)"> <svg viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%" anim:duration="1" anim:ease="ease-in-out">
                    <g id="Frame">
                    <path id="Vector" d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path id="Vector_2" d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                    </svg></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex gap-2 align-items-center">
                                                {{data.collection_owner_address | contentSlice :0:5}}
                                                <span class="cursor-pointer copyfile" (click)="copyAddress(data.collection_owner_address)"><svg viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%" anim:duration="1" anim:ease="ease-in-out">
                    <g id="Frame">
                    <path id="Vector" d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path id="Vector_2" d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                    </svg></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex gap-2 align-items-center">
                                                {{data.collection_owner_address | contentSlice :0:5}}
                                                <span class="cursor-pointer copyfile" (click)="copyAddress(data.collection_owner_address)"><svg viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%" anim:duration="1" anim:ease="ease-in-out">
                    <g id="Frame">
                    <path id="Vector" d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path id="Vector_2" d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                    </svg></span>
                                            </div>
                                        </td>
                                        <td>{{data.no_of_nft}}</td>
                                        <td>Active</td>
                                        <td>
                                            <div class="btn-group" *ngIf="!data.on_loan; else emptyData">
                                                <button type="button" class="btn btn-danger position-relative" (click)="openDeleteCollectionPopup(data.id)" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                                  </svg><span class="edit-tooltip">
                                                    Delete.
                                                </span></button>
                                            </div>
                                            <ng-template #emptyData>
                                                <div class="text-center">
                                                    <button type="button" class="btn position-relative disabled-btn"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                                      </svg><span class="reset-tooltip">
                                                        Disabled due to live trade/exchange of the items
                                                    </span></button>
                                                </div>
                                            </ng-template>
                                        </td>

                                    </tr>
                                    <tr *ngIf="collections.length == 0 && !loader" class="text-center">
                                        <td colspan="9">No data found.</td>
                                    </tr>
                                    <tr *ngIf="loader">
                                        <td colspan="9"><div class="loader-wrap">Loading. <span class="Loaders"></span></div></td>
                                    </tr>
                                </table>
                                <app-paginations [isLoading]="loader" [paginationsDetails]="paginationsDetails" [currentPageLimit]="currentPageLimit" (getDetails)="collectionsList($event.page,$event.limit)"></app-paginations>
                            </div>
                        </div>
                    </div>
                </app-card>
            </div>
        </div>
    </div>
</div>


<!-- BEGIN Delete Collection Confirmation Modal-->
<ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Delete Collection.</h5>
      <a  class="btn-close " aria-label="Close" *ngIf="!traitProcessing" (click)="modal.dismiss('Cross click')"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"  viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
      </svg></a>
    </div>
    <div class="modal-body">
      <p>
        All values associated to this <span class="text-danger">{{deleteCollectionData.name}}</span> will be permanently
        deleted.
        <span class="text-danger">This operation can not be undone.</span>
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" [disabled]="traitProcessing" (click)="modal.close('Close click')">Close</button>
      <button type="button" class="btn btn-danger" *ngIf="!traitProcessing" (click)="deleteCollections()">Delete</button>
     
      <div class="loader-wrap" *ngIf="traitProcessing">
         <button class="btn btn-green"  disabled>
            Processing.<span class="loader-white"></span>
        </button>
      </div>
    </div>
  </ng-template>
  <!-- END Delete Collection Confirmation Modal-->