import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

@Injectable()
export class RegulatedInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const regulated = localStorage.getItem('regulated') || 'false';
    const token = localStorage.getItem('token');
    const bearerToken = `bearer ${token}`;
    const modifiedReq = request.clone({ 
      setHeaders: {
        'Regulated' : regulated,
        'Authorization': bearerToken
      },
    });
    return next.handle(modifiedReq);
  }
}