import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICurrencyChangeOrder } from '../shared/interface/interface';

@Injectable({
  providedIn: 'root'
})
export class BorrowAndLendService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Gets all currencies
   * @param {number} page 
   * @param {number} limit 
   * @returns  
   */
  public getAllCurrencies(page: number, limit: number) {
    return this.http.get(`${environment.API_BASE_URL}/admin/currencies?page=${page}&limit=${limit}`);
  }

  public addCurrency(data: any) {
    return this.http.post(`${environment.API_BASE_URL}/admin/currency`, data);
  }

  public updateCurrency(id: any, data: any) {
    return this.http.patch(`${environment.API_BASE_URL}/admin/currency?id=${id}`, data);
  }

  public deleteCurrency(id: any) {
    return this.http.delete(`${environment.API_BASE_URL}/admin/currency?id=${id}`);
  }

  public getLoanRequestDays() {
    return this.http.get(`${environment.API_BASE_URL}/admin/days`);
  }

  public addLoanRequestDays(data: any) {
    return this.http.post(`${environment.API_BASE_URL}/admin/day`, data);
  }

  public deleteLoanRequestDays(days: any) {
    return this.http.delete(`${environment.API_BASE_URL}/admin/day?days=${days}`);
  }

  /**
 * Image upload
 * @param {File} file 
 * @returns  
 */
  imageUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('service_type', 'currency-image');
    return this.http.post(`${environment.API_BASE_URL}/file-upload/document`, formData)
  }

  /**
   * Changes currency order
   * @param {currencyOrderChangeRequest} updates 
   */
  changeCurrencyOrder(data: ICurrencyChangeOrder) {
    let obj = { updates: data };
    return this.http.patch(`${environment.API_BASE_URL}/admin/multiple-currency`, obj)
  }

}
