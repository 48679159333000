<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <app-card cardTitle="Privacy policy document." [options]="false">
                    <div class="row">
                        <div class="col-lg-12">
                            <form [formGroup]="privacyForm" (ngSubmit)="privacyFormSubmit()">
                                <div class="row">
                                    <div class="col-12 col-sm-8 mb-3">
                                        <div class="form-group">
                                            <label>Privacy policy. <label class="text-danger"> * </label> <a
                                                    *ngIf="privacyPolicyUrl" href="{{privacyPolicyUrl}}" target="_blank"
                                                    class="ml-4">Click to view preview.</a> </label>
                                            <input type="file" class="form-control"  #privacyFileInput
                                                (change)="fileChange($event,'privacy_policy')">

                                            <small class="text-danger">{{privacyFileError}}</small>

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 text-sm-left text-center">
                                        <button type="submit" class="btn btn-green" [disabled]="privacyFormLoader || privacyFileError || privacyForm.invalid">
                                            <span *ngIf="!privacyFormLoader" > Update.</span>
                                            <span *ngIf="privacyFormLoader"> Processing. <span class="loader-white"></span></span>
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </app-card>

                <app-card cardTitle="User agreement document." [options]="false">
                    <div class="row">
                        <div class="col-lg-12">
                            <form [formGroup]="agreementForm" (ngSubmit)="agreementFormSubmit()">
                                <div class="row">
                                    <div class="col-12 col-sm-8 mb-3">
                                        <div class="form-group">
                                            <label>User agreement. <label class="text-danger"> * </label> <a
                                                    *ngIf="useragreementUrl" href="{{useragreementUrl}}" target="_blank"
                                                    class="ml-4">Click to view preview.</a> </label>
                                            <input type="file" class="form-control"   #userAgreementFileInput
                                                (change)="fileChange($event,'user_agreement')">

                                            <small class="text-danger">{{agreementFileError}}</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 text-sm-left text-center">
                                        <button type="submit" class="btn btn-green" [disabled]="agreementFormLoader || agreementFileError || agreementForm.invalid">
                                            <span *ngIf="!agreementFormLoader"> Update.</span>
                                            <span *ngIf="agreementFormLoader"> Processing. <span class="loader-white"></span></span>
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </app-card>
            </div>
        </div>
    </div>
</div>