import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FaqManagementService } from 'src/app/services/faq-management.service';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent {
  public categoryForm: FormGroup;
  public categoryLoader: boolean = false;
  categoryFormSubmitted: boolean = false;
  @ViewChild("deletecategoryModal") deletecategoryModal: TemplateRef<any>;
  categoryData = [];
  isUpdate: boolean = false;
  categoryDataId: string = '';
  loader:boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private faqManagementService: FaqManagementService,
    private errorHandler: ErrorHandlerDirective,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.categoryForm = this.formBuilder.group({
      category: ['', [Validators.required]]
    })
    this.getcategory();
  }

  get categoryFormGet() {
    return this.categoryForm.controls;
  }

  /**
   * categorys modal open
   * @param {any} content 
   * @param {string} id 
   */
  public modalOpen(content: any, id: string) {
    if (id == '0') {
      this.isUpdate = false;
      this.categoryForm.reset();
    }
    else {
      this.faqManagementService.getCategoryById(id).subscribe((response) => {
        this.categoryDataId = response['data']._id
        this.categoryForm.patchValue({
          category: response['data'].category
        });
      },
        (error) => {
        });
      this.isUpdate = true;
    }
    this.modalService.open(content, { size: 'lg', centered: true,  backdrop : 'static', keyboard : false });
  }

  /**
   * Gets category
   */
  getcategory() {
    this.loader = true;
    this.faqManagementService.getCategory().subscribe((response) => {
      this.categoryData = response['data'];
      this.loader = false;
    },
      (error) => {
        this.loader = false;
      })
  }
  /**
   * Category submit
   */
  public categorySubmit() {
    this.categoryFormSubmitted = true;
    if (this.categoryForm.invalid) {
      return
    }
    this.categoryLoader = true;
    let data = {
      category: this.categoryForm.value.category
    }
    let categoryService;
    if (this.isUpdate) {
      categoryService = this.faqManagementService.updateCategory(data, this.categoryDataId)
    }
    else {
      categoryService = this.faqManagementService.addCategory(data)
    }
    categoryService.subscribe((response) => {
      this.toastr.success(response['message']);
      this.modalService.dismissAll();
      this.categoryLoader = false;
      this.getcategory();
    },
      (error) => {
        this.categoryLoader = false;
      }
    )


  }

  /**
   * Opens delete modal
   * @param {string} id 
   */
  deleteCategory(id: string) {
    this.categoryLoader = true;
    Swal.fire({
      title: 'Are you sure want to Delete?',
      text: 'Click OK for delete',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      Swal.fire({
        onBeforeOpen: () => {
          Swal.showLoading()
        },
        allowOutsideClick: false,
        text: 'Processing, Please wait...',
      });
      if (willDelete.dismiss) {
        Swal.fire('', 'Delete cancelled', 'error');
      } else {
        this.deletecategoryProcess(id);
      }
    },
      (error) => {
        this.categoryLoader = false;
        Swal.close();
      });
  }

  /**
   * Deletes category process
   * @param {string} id 
   */
  deletecategoryProcess(id: string) {
    this.faqManagementService.deleteCategory(id).subscribe((response) => {
      this.toastr.success(response['message']);
      this.categoryLoader = false;
      Swal.close();
      this.getcategory();
    },
      (error) => {
        this.categoryLoader = false;
        Swal.close();
      })

  }
}
