<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <app-card cardTitle="Partner fees details (common to Custodial and Self-Custody environments)." [options]="false">
                    <form [formGroup]="partnerFeesForm" (ngSubmit)="partnerFeesFormSubmit()">
                      <div class="row mb-3">
                        <div class="col-6 form-group">
                          <label>Partner address. <label class="text-danger"> * </label></label>
                          <input type="text" class="form-control" placeholder="Partner address." formControlName="partner" [readonly]="partnerFeeEdit" >
                          <div *ngIf="partnerFeesFormSubmitted && partnerFeesFormGet.partner.errors"
                            class="invalid-feedback-custom">
                            <small class="text-danger" *ngIf="partnerFeesFormGet.partner.errors.required">Please enter partner address.</small>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-6 form-group">
                          <label>Platform fee. <label class="text-danger"> * </label></label> (Fee in MATIC)
                          <input type="number" min="0" step=".01" oninput="validity.valid||(value='');" 
                            class="form-control" placeholder="Platform fee." formControlName="platformFee">
                          <div *ngIf="partnerFeesFormSubmitted && partnerFeesFormGet.platformFee.errors"
                            class="invalid-feedback-custom">
                            <small class="text-danger" *ngIf="partnerFeesFormGet.platformFee.errors.required">Please enter
                              platform fee.</small>
                          </div>
                        </div>
                        <div class="col-6 form-group">
                          <label>Royalty fee. (%)<label class="text-danger">*</label></label>
                          <input type="number" min="0" max="100" step=".01" oninput="validity.valid||(value='');"
                            class="form-control" placeholder="Royalty fee." formControlName="royaltyFee">
                          <div *ngIf="partnerFeesFormSubmitted && partnerFeesFormGet.royaltyFee.errors"
                            class="invalid-feedback-custom">
                            <small class="text-danger" *ngIf="partnerFeesFormGet.royaltyFee.errors.required">Please enter royalty fee.</small>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3"><b>Royalty splitter.</b></div>
                      <div class="row">
                        <div class="col-12 col-sm-6 mb-3">
                          <div class="form-group">
                            <label>Creator royalty fee. (%) <label class="text-danger"> * </label> </label>
                            <input type="number" class="form-control" placeholder="Creator royalty fee."
                              formControlName="creatorFee">
                            <div *ngIf="partnerFeesFormSubmitted && partnerFeesFormGet.creatorFee.errors" class="invalid-feedback-custom">
                              <small class="text-danger" *ngIf="partnerFeesFormGet.creatorFee.errors.required">
                                Please enter creator royalty fee.</small>
                              <small class="text-danger" *ngIf="partnerFeesFormGet.creatorFee.errors.pattern">Accepts
                                only whole number.</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 mb-3">
                          <div class="form-group">
                            <label>Client royalty fee. (%)<label class="text-danger"> * </label> </label>
                            <input type="number" class="form-control" placeholder="Client royalty fee."
                              formControlName="clientFee">
                            <div
                              *ngIf="partnerFeesFormSubmitted && partnerFeesFormGet.clientFee.errors"
                              class="invalid-feedback-custom">
                              <small class="text-danger" *ngIf="partnerFeesFormGet.clientFee.errors.required">Please
                                enter client royalty fee.</small>
                              <small class="text-danger" *ngIf="partnerFeesFormGet.clientFee.errors.pattern">Accepts
                                only whole number.</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-4 text-sm-left text-center">
                        <div class="d-flex gap-2">
                          <button type="submit" class="btn btn-green" *ngIf="!partnerFeesFormLoader && !partnerFeeEdit">Submit.</button>
                          <button type="submit" class="btn btn-green" *ngIf="!partnerFeesFormLoader && partnerFeeEdit">Edit.</button>
                          <button class="btn btn-danger" *ngIf="!partnerFeesFormLoader && partnerFeeEdit" (click)="cancelEdit()" >Cancel.</button>
                          <button class="btn btn-green" *ngIf="partnerFeesFormLoader" disabled>
                            Processing. <span class="loader-white" role="status"></span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <a *ngIf="transactionHash != ''" href="{{transhUrl}}{{transactionHash}}" target="_blank"
                      class="d-block text-center">Click to view transaction.</a>
                  </app-card>
                  <app-card cardTitle="Partner based fees list." [options]="false">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Partner.</th>
                                <th scope="col">Platform fee.</th>
                                <th scope="col">Royalty fee.</th>
                                <th scope="col">Creator royalty fee.</th>
                                <th scope="col">Client royalty fee.</th>
                                <th scope="col">Action.</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let fees of partnerFees; index as i">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>{{ fees.partner }}</td>
                                <td>{{ fees.platform_fee / 100}}</td>
                                <td>{{ fees.royalty_fee / 100}} %</td>
                                <td>{{ fees.creator_royalty_fee }} %</td>
                                <td>{{ fees.client_royalty_fee }} %</td>
                                <td>
                                  <div class="d-flex gap-2">
                                    <button type="button" class="btn btn-warning position-relative " (click)="editPartnerFee(fees)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                                    </svg><span class="edit-tooltip">Edit.</span></button>
                                    <button type="button" class="btn btn-danger position-relative" (click)="deletePartnerFee(fees)"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
                                      <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
                                    </svg>
                                    <span class="edit-tooltip">Reset.</span>
                                  </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="loader-wrap" *ngIf="partnerFeesLoader">Loading. <span class="Loaders"></span></div>
                          <p class="text-center" *ngIf="partnerFees?.length == 0 && !partnerFeesLoader">Partner based fees not found.</p>
                          <app-paginations [isLoading]="partnerFeesLoader" [paginationsDetails]="paginationsDetails" [currentPageLimit]="currentPageLimit" (getDetails)="getPartnerFees($event.page,$event.limit)"></app-paginations>
                        </div>
                      </div>
                    </div>
                  </app-card>
            </div>
        </div>
    </div>
</div>