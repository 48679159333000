<div class="sidemenu">
    <ul class="sidemenu-view">
        <li class="navitems ">
            <a class="nav-link" [routerLink]="['/fees-management']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Fees Management. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/partner-fees-management']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Partner fees Management.</span> </div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/whitelist-management']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Whitelist Management. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/royalty-splitter']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Royalty Splitter. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/nft-trait']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">NFT Trait. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/collections-management']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Collections Management. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/borrow-and-lend']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Borrow and Lend. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/currency-management']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Currency Management. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/manage-document']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Manage Document. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/faq']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Faq Management. </span></div>
            </a>
        </li>
        <li class="navitems" *ngIf="regulated">
            <a class="nav-link" [routerLink]="['/kyc-management']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">KYC Management. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/settings']" routerLinkActive="active">
                <div class="tooltipsview"><span class="nav_name">Settings.</span></div>
            </a>
        </li>
    </ul>
</div>