import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeeManagementService {

  constructor(private http: HttpClient) { }

  /**
   * Gets partner fees
   * @param {number} page 
   * @param {number} limit 
   * @returns  
   */
  public getPartnerFees(page:number,limit:number) {
    return this.http.get(`${environment.API_BASE_URL}/admin/fee?page=${page}&limit=${limit}`);
  }
  
  public addPartnerFees(params: any) {
    return this.http.post(`${environment.API_BASE_URL}/admin/fees`, params);
  }

  public updatePartnerFees(id: string, params: any) {
    return this.http.patch(`${environment.API_BASE_URL}/admin/fees?id=${id}`, params);
  }

  public deletePartnerFees(id: string) {
    return this.http.delete(`${environment.API_BASE_URL}/admin/fees?id=${id}`);
  }
}
