<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <app-card cardTitle="Normal mint NFT trait list." [options]="false">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="position-abs">
                                <button type="button" class="btn btn-green float-right mb-4" (click)="openXl(longContent)">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"  viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                  </svg> Add trait.</button>
                            </div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>S.no.</th>
                                        <th>Attribute.</th>
                                        <th style="width:75%">Value.</th>
                                        <th>Actions.</th>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                    <tr *ngFor="let data of nftTrait">
                                        <td>{{data.id + 1}}</td>
                                        <td>{{data.attribute}}</td>
                                        <td>
                                          <ng-container *ngIf="data.value.length ==''"  class="text-center"> 
                                            -
                                          </ng-container>
                                          <div class="w-100 d-flex gap-2 flex-wrap" >
                                            <ng-container *ngFor="let datas of data.value; let i=index; let last=last">
                                              <p>{{datas}}<span *ngIf="!last">,</span> <span *ngIf="last">.</span></p>
                                            </ng-container>
                                          </div>
                                        </td>
                                        
                                        <td class=" text-left">
                                          <div class="action-section">
                                            <button type="button" class="btn btn-warning position-relative" (click)="editTrait(data._id)"><svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" class="bi bi-pencil"><path  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path></svg><span class="edit-tooltip">Edit.</span></button>
                                            <button *ngIf="!data.mandatory" type="button" class="btn btn-danger position-relative" (click)="deleteTrait(data._id)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                            </svg><span class="edit-tooltip">Delete.</span></button>
                                          </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="nftTrait.length == 0 && !loader" class="text-center">
                                      <td colspan="5">No data found.</td>
                                  </tr>
                                  <tr *ngIf="loader" class="text-center">
                                    <td colspan="5"><div class="loader-wrap" *ngIf="loader">Loading. <span class="Loaders"></span></div></td>
                                </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </app-card>

                
                <app-lazymint-trait [allNFTLocations]="allNFTLocations"></app-lazymint-trait>
            </div>
        </div>
    </div>
</div> 

<!-- BEGIN Create Trait Modal -->
<ng-template #longContent let-modal>
    <form [formGroup]="nftTraitForm" (ngSubmit)="nftTraitSubmit()">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="!updateStatus">Create item trait.</h5>
        <h5 class="modal-title" *ngIf="updateStatus">Update item trait.</h5>
        <input type="text" class="form-control" hidden formControlName="_id">
        <a  class="btn-close" aria-label="Close"
          (click)="nftTraitFormSubmitted = false; modal.dismiss('Cross click')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"  viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
          </svg>
        </a>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-group">
              <label>Attribute. <label class="text-danger"> * </label> </label>
              <input type="text" class="form-control" placeholder="Enter attribute." formControlName="attribute">
              <div
                *ngIf="(nftTraitControls.attribute.touched ||  nftTraitFormSubmitted) && nftTraitControls.attribute.errors?.required"
                class="invalid-feedback-custom">
                <small class="text-danger">This field is required.</small>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="form-group">
              <label>Value. </label>
              <tag-input class="tag-chips" [theme]="'minimal-danger'" [addOnPaste]="true"
                name="value" formControlName="value" placeholder="Enter value." secondaryPlaceholder="Enter the new tag ."></tag-input>
              <div class="text-notes py-2">(Note: enter the value and hit enter.)</div>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-3">
            <div class="form-group">
              <ng-container *ngIf="updateStatus">
                <input type="checkbox" class="cursor-pointer" (change)="normalMandatoryChange()" value="{{normalMandatoryCheckbox}}" *ngIf="!editData.mandatory"> Mandatory.
                <div class="text-notes py-2 text-danger" *ngIf="normalMandatoryCheckbox || editData.mandatory">(Note: Mandatory attributes can't able to edit and delete.)</div>
            </ng-container>
            <ng-container *ngIf="!updateStatus">
                <input type="checkbox" class="cursor-pointer" (change)="normalMandatoryChange()" value="{{normalMandatoryCheckbox}}"> Mandatory.
                <div class="text-notes py-2 text-danger" *ngIf="normalMandatoryCheckbox">(Note: Mandatory attributes can't able to edit and delete.)</div>
            </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="nftTraitFormSubmitted = false; modal.dismiss('cancel click')">Cancel.</button>
        <button type="submit" class="btn btn-green" *ngIf="!updateStatus && !traitProcessing">Create.</button>
        <button type="submit" class="btn btn-green" *ngIf="updateStatus && !traitProcessing">Update.</button>
        <button class="btn btn-green" *ngIf="traitProcessing" disabled>
          Processing.<span class="loader-white"></span>
        </button>
      </div>
    </form>
  </ng-template>
  <!-- END Create Trait Modal -->

  <!-- BEGIN Delete Confirmation Trait Modal-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Delete trait.</h5>
    <a class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"  viewBox="0 0 16 16">
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
    </svg></a>
  </div>
  <div class="modal-body">
    <p>
      All values associated to this <span class="text-danger">
        <span *ngIf="deleteTraitData">
          {{deleteTraitData.attribute}}
        </span>
        
      </span> will be permanently
      deleted.
      <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close.</button>
    <button type="button" class="btn btn-danger" *ngIf="!traitProcessing" (click)="deleteAttribute()">Delete.</button>
    <button class="btn btn-green" *ngIf="traitProcessing" disabled>
      Processing.<span class="loader-white"></span>
    </button>
  </div>
</ng-template>
<!-- END Delete Confirmation Trait Modal-->



