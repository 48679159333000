import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from '../../navigation';
import { Location } from '@angular/common';
import { NextConfig } from 'src/app/app-config';
import { Const } from 'src/app/core/const/const';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  styleUrls: ['./nav-group.component.scss'],
})
/**
 * Export Class
 */
export class NavGroupComponent implements OnInit {
  @Input() item: NavigationItem;
  @Input() layout1: boolean = false;
  @Input() activeId: any;
  public nextConfig: any;

  /**
   * Constructor
   */
  constructor(private location: Location,
    private constVal:Const) {
    this.nextConfig = NextConfig.config;
  }

  /**
   * Initial Loader
   */
  ngOnInit() {
    // at reload time active and trigger link
    let currentUrl = this.location.path();
    if (this.location['_baseHref']) {
      currentUrl = this.location['_baseHref'] + this.location.path();
    }
    const link = 'a.nav-link[ href=\'' + currentUrl + '\' ]';
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const upParent = parent.parentElement.parentElement;
      const lastParent = upParent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === this.constVal.CONST_VAR.vertical) {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if (upParent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === this.constVal.CONST_VAR.vertical) {
          upParent.classList.add('pcoded-trigger');
        }
        upParent.classList.add('active');
      } else if (lastParent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === this.constVal.CONST_VAR.vertical) {
          lastParent.classList.add('pcoded-trigger');
        }
        lastParent.classList.add('active');
      }
    }
  }
}
