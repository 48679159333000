import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Spinkit } from './spinkits';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: [
    './spinner.component.scss',
    './spinkit-css/sk-line-material.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
/**
 * Export Class
 */
export class SpinnerComponent implements OnDestroy {
    public isSpinnerVisible = true;
    public Spinkit = Spinkit;
    @Input() public backgroundColor = '#2196f3';
    @Input() public spinner = Spinkit.skLine;

    /**
     * Constructor
     */
    constructor(private router: Router) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true;
        } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.isSpinnerVisible = false;
        }
      }, () => {
        this.isSpinnerVisible = false;
      });
    }

    /**
     * Ng Destroy
     */
    ngOnDestroy(): void {
      this.isSpinnerVisible = false;
    }
}
