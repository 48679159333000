<div class="section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <app-card cardTitle="Partner fees details (common to Custodial and Self-Custody environments)."
          [options]="false">
          <form [formGroup]="partnerFeesForm" (ngSubmit)="partnerFeesFormSubmit()">
            <div class="row mb-3">
              <div class="col-lg-6 form-group">
                <label>Partner address. <label class="text-danger"> * </label></label>
                <input type="text" class="form-control" placeholder="Partner address." formControlName="partner"
                  [readonly]="partnerFeeEdit">
                <div *ngIf="partnerFeesFormSubmitted && partnerFeesFormGet.partner.errors"
                  class="invalid-feedback-custom">
                  <small class="text-danger" *ngIf="partnerFeesFormGet.partner.errors.required">Please enter partner
                    address.</small>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-6 form-group">
                <label>Minting fee. <label class="text-danger"> * </label></label> (Fee in MATIC)
                <input type="number" min="0" step=".01" oninput="validity.valid||(value='');" class="form-control"
                  placeholder="Minting fee." formControlName="platformFee"
                  (input)="validateFees(partnerFeesForm.value.platformFee, 'platformFee')">
                <div class="col-12 col-lg-12 mb-3 p-0 mt-2" *ngIf="isDisabledForm['platformFee']">
                  <div class="warning">
                    <div class="warning__icon">
                      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m13 14h-2v-5h2zm0 4h-2v-2h2zm-12 3h22l-11-19z" fill="#393a37"></path>
                      </svg>
                    </div>
                    <div class="warning__title">Minting fee can't be 0, and it cannot be blank.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <label>Royalty fee. (%)<label class="text-danger">*</label></label>
                <input type="number" min="0" max="100" step=".01" oninput="validity.valid||(value='');"
                  class="form-control" placeholder="Royalty fee." formControlName="royaltyFee"
                  (input)="validateFees(partnerFeesForm.value.royaltyFee, 'royaltyFee')">
                <div class="col-12 col-lg-12 mb-3 p-0 mt-2" *ngIf="isDisabledForm['royaltyFee']">
                  <div class="warning">
                    <div class="warning__icon">
                      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m13 14h-2v-5h2zm0 4h-2v-2h2zm-12 3h22l-11-19z" fill="#393a37"></path>
                      </svg>
                    </div>
                    <div class="warning__title">Collection creator royalty must be a valid number greater than 0 and up
                      to 100, and it
                      cannot be left empty.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3"><b>Sales Royalty splitter.</b></div>
            <div class="row">
              <div class="col-12 col-lg-6 mb-2">
                <div class="form-group">
                  <label>Collection creator royalty fee. (%) <label class="text-danger"> * </label> </label>
                  <input type="number" class="form-control" placeholder="Collection creator royalty fee."
                    formControlName="creatorFee"
                    (input)="patchClientFee(partnerFeesForm.value.creatorFee); validationRoyaltyCheck(partnerFeesForm.value.creatorFee, partnerFeesForm.value.clientFee, 'salesRoyaltyFee')">
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-2">
                <div class="form-group">
                  <label>Sales royalty fee. (%)<label class="text-danger"> * </label> </label>
                  <input type="number" class="form-control" placeholder="Sales royalty fee." formControlName="clientFee"
                    (input)="patchCreatorFee(partnerFeesForm.value.clientFee); validationRoyaltyCheck(partnerFeesForm.value.creatorFee, partnerFeesForm.value.clientFee,'salesRoyaltyFee')">
                </div>
              </div>
              <div class="col-12 col-lg-12 mb-3" *ngIf="isDisabledForm['salesRoyaltyFee']">
                <div class="warning">
                  <div class="warning__icon">
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                      <path d="m13 14h-2v-5h2zm0 4h-2v-2h2zm-12 3h22l-11-19z" fill="#393a37"></path>
                    </svg>
                  </div>
                  <div class="warning__title">Royalty fee should be a valid number between 0 and 100, and it cannot be
                    blank.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 text-sm-left text-center">
              <div class="d-flex gap-2">
                <button type="submit" class="btn btn-green" *ngIf="!partnerFeesFormLoader && !partnerFeeEdit"
                  [disabled]="isDisableButton()">Submit.</button>
                <button type="submit" class="btn btn-green" *ngIf="!partnerFeesFormLoader && partnerFeeEdit"
                  [disabled]="isDisableButton()">Edit.</button>
                <button class="btn btn-danger" *ngIf="!partnerFeesFormLoader && partnerFeeEdit"
                  (click)="cancelEdit()">Cancel.</button>
                <button class="btn btn-green" *ngIf="partnerFeesFormLoader" disabled>
                  Processing. <span class="loader-white" role="status"></span>
                </button>
              </div>
            </div>
          </form>
          <a *ngIf="transactionHash != ''" href="{{transhUrl}}{{transactionHash}}" target="_blank"
            class="d-block text-center">Click to view transaction.</a>
        </app-card>
        <app-card cardTitle="Partner based fees list." [options]="false">
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Partner.</th>
                      <th scope="col">Minting fee.</th>
                      <th scope="col">Royalty fee.</th>
                      <th scope="col">Collection creator royalty fee.</th>
                      <th scope="col">Sales royalty fee.</th>
                      <th scope="col">Action.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let fees of partnerFees; index as partnerFeeIndex">
                      <th scope="row">{{ partnerFeeIndex + 1 }}</th>
                      <td>{{ fees.partner }}</td>
                      <td>{{ fees.platform_fee / 100}}</td>
                      <td>{{ fees.royalty_fee / 100}} %</td>
                      <td>{{ fees.creator_royalty_fee }} %</td>
                      <td>{{ fees.client_royalty_fee }} %</td>
                      <td>
                        <div class="d-flex gap-2">
                          <!-- Button : Edit Partner Fee -->
                          <button type="button" class="btn btn-warning position-relative" (click)="editPartnerFee(fees)"
                            [disabled]="isDisableButton()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-pencil" viewBox="0 0 16 16">
                              <path
                                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                            </svg>
                            <span class="edit-tooltip">Edit.</span>
                          </button>
                          <!-- Button : Reset Partner Fee -->
                          <button type="button" class="btn btn-danger position-relative"
                            [ngClass]="{'reset-button-disabled' : isResetButtonLoading[partnerFeeIndex]}"
                            (click)="deletePartnerFee(fees,partnerFeeIndex)" [disabled]="isDisableButton()">
                            <svg [ngClass]="{ 'reset-loading': isResetButtonLoading[partnerFeeIndex] }"
                              xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                              viewBox="0 0 16 16">
                              <path
                                d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                              <path fill-rule="evenodd"
                                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
                            </svg>
                            <span class="edit-tooltip">Reset.</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="loader-wrap" *ngIf="partnerFeesLoader">Loading. <span class="Loaders"></span></div>
                <p class="text-center" *ngIf="partnerFees?.length == 0 && !partnerFeesLoader">Partner based fees not
                  found.</p>
                <app-paginations [isLoading]="partnerFeesLoader" [paginationsDetails]="paginationsDetails"
                  [currentPageLimit]="currentPageLimit"
                  (getDetails)="getPartnerFees($event.page,$event.limit)"></app-paginations>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</div>