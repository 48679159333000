import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * Export Class
 */
export class AccountService {
  public account;
  public authorization;
  public addressValidation;
  public authValidationsObserve: Observable<boolean>;
  public authValidations = new BehaviorSubject(false);
  public authorizationObserve: Observable<{
    status: boolean;
  }>
  public accountObserve: Observable<{
    address: string,
    chainId: string,
    network: string,
    provider: string
  }>;

  public addressValidationObserve: Observable<{
    address: any;
  }>

  /**
   * Constructor
   */
  constructor(
  ) {
    this.account = new BehaviorSubject({
      address: '',
      chainId: '',
      network: '',
      provider: ''
    });
    this.accountObserve = this.account.asObservable();
    this.authorization = new BehaviorSubject({
      status: '',
    });
    this.authorizationObserve = this.authorization.asObservable();

    this.addressValidation = new BehaviorSubject({
      address: '',
    });
    this.addressValidationObserve = this.addressValidation.asObservable();
    this.authValidationsObserve = this.authValidations.asObservable();
  }

  /**
   * Set Account
   * @param {object}  data
   */
  public setAccount(data: {
    address: string,
    chainId: string,
    network: string,
    provider: string
  }) {
    this.account.next(data);
  }

  /**
   * Check Authorization
   * @param {object} data
   */
  public checkAuthorization(data: {
    status: boolean,
  }) {
    this.authorization.next(data);
  }

  /**
   * Check Address
   * @param {object} data
   */
  public checkAddress(data: {
    status: any,
  }) {
    this.addressValidation.next(data);
  }

  /**
   * Auths validations update
   * @param {boolean} data 
   */
  public authValidationsUpdate(data:boolean) {
    this.authValidations.next(data);
  }
}
