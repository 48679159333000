import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})

/**
 * Export Class
 */
export class ModalComponent implements OnInit {
  @Input() step1;
  @Input() step2;
  @Input() step3;
  @Input() step4;
  @Input() status;

  /**
   * Constructor
   */
  constructor(public activeModal: NgbActiveModal) {}

  /**
   * Initial Loader
   */
  ngOnInit(): void {
  }
}
