import { Component, Input, NgZone, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { StorageService } from 'src/app/services/storage.service';
import { AccountService } from 'src/app/services/account.service';
import { Const } from 'src/app/core/const/const';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { environment } from 'src/environments/environment';
import { polygon } from '@wagmi/core/chains';
import { watchAccount, watchNetwork, disconnect } from '@wagmi/core';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { ToastrService } from 'ngx-toastr';
import { switchNetwork } from '@wagmi/core'
import { polygonAmoy } from 'src/app/shared/helpers/polygonAmoy';

@Component({
    selector: 'app-nav-right',
    templateUrl: './nav-right.component.html',
    styleUrls: ['./nav-right.component.scss'],
    providers: [NgbDropdownConfig],
})

/**
 * Export Class
 */
export class NavRightComponent implements OnInit, OnDestroy {
    account: any = {};
    connector: any;
    ethereum: any;
    authSubscribe: any;
    regulated = false;
    @Input('collapseStyle') collapseStyle;
    unwatchAccount: any;
    unwatchNetwork: any;
    public web3Modal: any;
    @ViewChild('switchNetworkModal') switchNetworkModal: { show: () => void; hide: () => void; };
    defaultNetwork: any;
    walletProvider: string = '';

    /**
     * Constructors
     */
    constructor(
        private accountService: AccountService,
        private storageService: StorageService,
        private router: Router,
        private toastr: ToastrService,
        private zone: NgZone,
        private contractIntegrationService: ContractIntegrationsService,
        private modalService: NgbModal,
    ) {

    }

    /**
     * Initial Loader
     */
    ngOnInit() {
        this.wagmiConfig();
        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);

        this.walletProvider = JSON.parse(this.storageService.getItem('wagmi.connectedRdns') || 'null');

        this.regulated = JSON.parse(this.storageService.getItem('regulated'));

        if (this.regulated != false) {
            this.storageService.setItem('regulated', true);
            this.regulated = JSON.parse(this.storageService.getItem('regulated'));
        }

        this.accountService.authValidationsObserve.subscribe((response) => {
            if (response) {
                this.logout();
            }
        })

        setTimeout(() => {
            this.defaultNetwork = environment.SUPPORTED_CHAINS;
        }, 1000);
    }

    /**
      * Wagmis config
      */
    public wagmiConfig() {
        // wallet connect initialization

        const metadata = {
            name: 'Real World',
            description: 'Real World - Market',
            url: environment.WEB_SITE_URL,
            icons: ['https://avatars.githubusercontent.com/u/37784886']
        }
        const chains = [polygon, polygonAmoy]
        const projectId = environment.WALLET_CONNECT_PROJECT_ID;
        const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
        this.web3Modal = createWeb3Modal({ wagmiConfig, projectId, chains, themeVariables: { '--w3m-accent': '#006736', '--w3m-z-index': 10000, '--w3m-font-family': 'Source Code Pro' } })

        this.unwatchNetwork = watchNetwork(async (network) => {
            if (network.chain != undefined) {
                if (!environment.SUPPORTED_CHAINS.includes(network.chain.id.toString())) {
                    this.switchNetworkModal.show();
                }
                else {
                    this.switchNetworkModal.hide();
                }
            }
        })
        this.unwatchAccount = watchAccount((connection) => {
            if (connection.isConnected) {
                setTimeout(() => {
                    this.modalService.dismissAll();
                    // this.getWalletAddress();
                }, 1000);
            }
            else {
                this.storageService.clearItem();
                this.router.navigate(['/login']);

            }
        })

    }
    /**
     * Destroy
     */
    public ngOnDestroy() {
        this.unwatchAccount();
        this.unwatchNetwork();
    }


    /**
     * Gets wallet address
     */
    public async getWalletAddress() {
        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);

        const ownerAddress = await this.contractIntegrationService.owner();
        if (this.account.state.data.account != '' && ownerAddress != this.account.state.data.account) {
            // this.toastr.error('Owner Address Not Found');
            // await disconnect();
            this.zone.run(() => {

                this.router.navigate(['/login']);
            })
        }
    }


    slideToggle() {
        this.regulated = !this.regulated;
        this.storageService.setItem('regulated', this.regulated);
        window.location.reload();
    }

    /**
       * Logout
       */
    async logout() {
        this.toastr.error('Authentication failed. Please login again');
        await disconnect();
        this.accountService.authValidationsUpdate(false);
        setTimeout(() => {
            this.router.navigate(['/']);
        }, 1000);
    }

    /**
    * Switchs network
    * @param {string} chainId 
    */
    switchNetwork(chainId: string) {
        switchNetwork({ chainId: Number(chainId) });
    }

}
