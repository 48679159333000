import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'ekta-nft',
    title: 'RealWorld',
    type: 'group',
    // icon: 'feather icon-align-left',
  },
];

@Injectable()
/**
 * Export Class
 */
export class NavigationItem {
  /**
   * get
   * @return {any}
   */
  public get() {
    return NavigationItems;
  }
}
