<div>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="card">
        <div class="row align-items-center text-center">
          <div class="col-md-12">
            <div class="card-body">
              <img id="main-logo" src="assets/images/logo.png" alt="" class="logo">
              <h4 class="mt-2 mb-4">Admin.</h4>
              <div class="d-flex justify-content-center">
                <w3m-button balance='hide'></w3m-button>
              </div>
              <!-- <a class="btn bg--them btn-rounded d-flex justify-content-between align-items-center text--bold font--regular w-100 mb-2"
                 role="button">MetaMask.
                <div class="bg-white rounded-pill p-2 img--bg">
                  <img src="assets/images/metamask.svg" width="20" alt="">
                </div> -->
              <!-- </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ui-modal #switchNetworkModal [hideHeader]="true" [hideFooter]="true">
  <div class="app-modal-body">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
            <div class="row my-4">
              <div class="col-lg-12">
                <div class="text-center">
                  <div class="mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#ff0000"  viewBox="0 0 16 16">
                      <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
                      <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                    </svg>
                  </div>
                  <h4>Incorrect Network.</h4>
                  <p class="text-center" >Please change your Network to use our admin application.</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <ng-container *ngFor="let data of defaultNetwork">
                  <ng-container>
                <div class="sign-in-wallet">
                  <div class="d-flex gap-2">
                      <p style="color: #fff;" (click)="switchNetwork(data)" *ngIf="data === '80002'">Switch to Polygon Amoy</p>
                      <p style="color: #fff;" (click)="switchNetwork(data)" *ngIf="data === '137'">Switch to Polygon</p>
                      <img src="assets/images/polygon.webp" width="30" >
                  </div>
                </div>
              </ng-container>
            </ng-container>
                <!-- <div *ngIf="walletProvider !== 'io.metamask'">
                  <p class="mb-3">Unsupported chain. Logout and change network to access application</p>
                  <div class="sign-in-wallet" (click)="logout()">
                    <p>Log out.</p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</app-ui-modal>