import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDropdownModule, NgbTooltipModule, NgbDatepickerModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { TagInputModule } from 'ngx-chips';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './shared/components/card/card.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { UiModalComponent } from './shared/components/ui-modal/ui-modal.component';
import { AdminComponent } from './shared/components/nav/admin.component';
import { NavigationComponent } from './shared/components/nav/navigation/navigation.component';
import { NavContentComponent } from './shared/components/nav/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './shared/components/nav/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './shared/components/nav/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './shared/components/nav/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './shared/components/nav/nav-bar/nav-bar.component';
import { NavRightComponent } from './shared/components/nav/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './shared/components/configuration/configuration.component';
import { NavigationItem } from './shared/components/nav/navigation/navigation';
import { LoginComponent } from './pages/login/login.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { CommonHelper } from './shared/helpers/common-helper';
import { Const } from './core/const/const';
import { SearchCollectionsComponent } from './pages/search-collections/search-collections.component';
import { EditPlatformFeeComponent } from './pages/edit-platform-fee/edit-platform-fee.component';
import { EditRoyaltyFeeComponent } from './pages/edit-royalty-fee/edit-royalty-fee.component';
import { ClipboardModule } from 'ngx-clipboard';
import { RegulatedInterceptor } from './core/interceptors/regulated.interceptor';
import { FaqComponent } from './pages/faq/faq.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FeesManagementComponent } from './pages/fees-management/fees-management.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { PartnerFeesManagementComponent } from './pages/partner-fees-management/partner-fees-management.component';
import { WhitelistManagementComponent } from './pages/whitelist-management/whitelist-management.component';
import { SearchPipe } from './shared/pipes/search.pipe';
import { RoyaltySplitterComponent } from './pages/royalty-splitter/royalty-splitter.component';
import { NftTraitComponent } from './pages/nft-trait/nft-trait.component';
import { CollectionsManagementComponent } from './pages/collections-management/collections-management.component';
import { ManageDocumentComponent } from './pages/manage-document/manage-document.component';
import { BorrowLendComponent } from './pages/borrow-lend/borrow-lend.component';
import { ManageTransactionsDirective } from './shared/directives/manage-transactions.directive';
import { ErrorHandlerDirective } from './shared/directives/error-handler.directive';
import { ContentSlicePipe } from './shared/pipes/content-slice.pipe';
import { CurrencyManagementComponent } from './pages/currency-management/currency-management.component';
import { CategoryComponent } from './pages/faq/category/category.component';
import { KycManagementComponent } from './pages/kyc-management/kyc-management.component';
import { PaginationsComponent } from './shared/components/paginations/paginations.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LazymintTraitComponent } from './pages/nft-trait/lazymint-trait/lazymint-trait.component';
import { NotFoundComponent } from './pages/not-found/not-found.component'

@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        NavigationComponent,
        NavContentComponent,
        NavGroupComponent,
        NavCollapseComponent,
        NavItemComponent,
        NavBarComponent,
        NavRightComponent,
        ConfigurationComponent,
        LoginComponent,
        ModalComponent,
        CardComponent,
        SpinnerComponent,
        UiModalComponent,
        SearchCollectionsComponent,
        EditPlatformFeeComponent,
        EditRoyaltyFeeComponent,
        FaqComponent,
        FeesManagementComponent,
        SidebarComponent,
        PartnerFeesManagementComponent,
        WhitelistManagementComponent,
        SearchPipe,
        RoyaltySplitterComponent,
        NftTraitComponent,
        CollectionsManagementComponent,
        ManageDocumentComponent,
        BorrowLendComponent,
        ManageTransactionsDirective,
        ErrorHandlerDirective,
        ContentSlicePipe,
        CurrencyManagementComponent,
        CategoryComponent,
        KycManagementComponent,
        PaginationsComponent,
        SettingsComponent,
        LazymintTraitComponent,
        NotFoundComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        // CardComponent,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        DataTablesModule,
        TagInputModule,
        ReactiveFormsModule,
        FormsModule,
        NgbDatepickerModule,
        NgSelectModule,
        ClipboardModule,
        NgbPaginationModule,
        NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: RegulatedInterceptor, multi: true },
        NavigationItem, CommonHelper, Const, DecimalPipe, ManageTransactionsDirective, ErrorHandlerDirective],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
/**
 * Export Class
 */
export class AppModule {

}
