import { Component, ErrorHandler, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { CollectionService } from 'src/app/services/collection.service';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { NftTraitsService } from 'src/app/services/nft-traits.service';
import { StorageService } from 'src/app/services/storage.service';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { pagination } from 'src/app/shared/interface/interface';
import Web3 from 'web3';

const web3 = new Web3(window['ethereum']);

@Component({
  selector: 'app-collections-management',
  templateUrl: './collections-management.component.html',
  styleUrls: ['./collections-management.component.scss']
})
export class CollectionsManagementComponent {
  regulated: boolean = false
  public account: any = {};
  public collections: Array<any> = [];
  @ViewChild("content") deleteCollectionModal: TemplateRef<any>;
  public deleteCollectionData: any = null;
  public traitProcessing: boolean = false;
  public showDeleteCollectionTransaction: boolean = false;
  public deleteCollectionFormLoader: boolean = false;
  public transactionHashCollectionDelete: any = '';
  public loader:boolean = false;
  paginationsDetails:pagination;
  currentPageLimit:number = 10;

   /**
   * Constructor
   */
   constructor(
    private collectionService: CollectionService,
    private storageService: StorageService,
    private toastr: ToastrService,
    private clipboardservice:ClipboardService,
    private contractIntegrationService: ContractIntegrationsService,
    private modalService: NgbModal,
    private manageTransactionsDirective:ManageTransactionsDirective,
    private nftTraitsService: NftTraitsService,
    private errorHandler: ErrorHandler
  ){
    
  }
  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.collectionsList(1,this.currentPageLimit); 
  }

  /**
   * Collections list
   * @param {number} page
   * @param {number} limit
   */
  public async collectionsList(page:number,limit:number) {
    this.collections = [];
    this.loader = true; 
    this.collectionService.getCollections(page,limit).subscribe(async(response: any) => {
      this.collections = response.data.collections;
      this.paginationsDetails = response.data;
      this.collections.map((collection, index) => collection.id = index)
      this.loader = false;
    },(_error) => {
      this.loader = false;
    });
  }
  
  /**
     * Track by function for ngFor loops
     * @param index
     * @param item
     */
  public trackByFn(index: number, item: any): any {
    return item._id || index;
  }

  /**
   * copy collection wallet address
   */
  copyAddress(add:string){
    this.clipboardservice.copyFromContent(add);
    this.toastr.success('Copied address');
  }

  /**
   * Deletes collections
   */
  public async deleteCollections() {
    this.traitProcessing = true;
    this.showDeleteCollectionTransaction = false;
    this.contractIntegrationService.isCollectionDestroyed(this.deleteCollectionData.collection_address).then((destroyedResponse) => {
      if (destroyedResponse) {
        this.toastr.error("Collection Already Destroyed.");
        this.traitProcessing = false;
        this.modalService.dismissAll();
      }
      else {
        this.destroyedProcess(this.deleteCollectionData.collection_address);
      }
    });
  }

   /**
   * Destroyed process
   * @param {string} collectionAddress
   */
   public async destroyedProcess(collectionAddress) {
    try {
      let { destroyedAbi, requiredGas } = await this.contractIntegrationService.destroyCollection(collectionAddress, this.account.state.data.account);
      const message = {
        method: 'eth_sendTransaction',
        from: this.account.state.data.account,
        to: collectionAddress,
        data: destroyedAbi,
        chainId: this.account.state.data.chain.id,
        gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
        gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
      };
      this.manageTransactionsDirective.makeTransactions(message)
        .then(async (receipt) => {
          this.deleteCollectionProcess(collectionAddress,receipt['data']['transactionHash']);
        })
        .catch((_error) => {
          this.traitProcessing = false;
          this.deleteCollectionFormLoader = false;
        });
      } catch(error: any) {
        this.traitProcessing = false;
        this.deleteCollectionFormLoader = false;
        this.errorHandler.handleError(error);
      }
    }

  /**
   * Deletes collection process
   * @param {string} address 
   * @param {string} receipt 
   */
  public deleteCollectionProcess(address:string,receipt:string){
    this.nftTraitsService.deleteCollections(address).subscribe(() => {
      this.toastr.success('Collection Destroyed Successfully.');
      this.transactionHashCollectionDelete = receipt;
      this.traitProcessing = false;
      this.deleteCollectionFormLoader = false;
      this.modalService.dismissAll();
      this.collectionsList(1,this.currentPageLimit);
    },
    (error) => {
      this.traitProcessing = false;
      this.deleteCollectionFormLoader = false;
      if (error.code === 4001) this.toastr.error('User rejected.');
      else this.toastr.error('Transaction Failed.');
    }
    )
 }

 /**
   * Opens delete collection popup
   * @param {number} index
   */
 public openDeleteCollectionPopup(index: number) {
  this.deleteCollectionData = this.collections[index];
  this.modalService.open(this.deleteCollectionModal, { centered: true,  backdrop : 'static', keyboard : false });
}

}
