
/**
 * Const functions
 */
export class Const {
  CONST_VAR = {
    'invalidTknAuth': 'invalid token authorization',
    'typeZip': 'application/zip',
    'typeExcel': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'space': 'Space',
    'ERC721': 'ERC721',
    'ERC1155': 'ERC1155',
    'RENTAL': 'RENTAL',
    'attributes': 'attributes',
    'features': 'features',
    'Buy': 'Buy',
    'Bid': 'Bid',
    'TimedBid': 'TimedBid',
    'fullCard': 'full-card',
    'vertical': 'vertical',
    'menuLight': 'menu-light',
    'dark': 'dark',
    'reset': 'reset',
    'metamask': 'metamask',
    'horizontal': 'horizontal',
  }
}
