import { Component, ErrorHandler } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { StorageService } from 'src/app/services/storage.service';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const web3 = new Web3(window['ethereum']);

@Component({
  selector: 'app-fees-management',
  templateUrl: './fees-management.component.html',
  styleUrls: ['./fees-management.component.scss']
})
export class FeesManagementComponent {
  public feesDetailsForm: FormGroup;
  public feesDetailsFormSubmitted: boolean = false;
  regulated: boolean = false
  fees: any;
  public existPlatformFee: number;
  public feesFormLoader: boolean = false;
  public transactionHash: string = '';
  public royaltyDetailsForm: FormGroup;
  public existRoyaltyFee: number;
  public royaltyDetailsFormSubmitted: boolean = false;
  public royaltyFormLoader: boolean = false;
  public account: any = {};
  public royaltySplitterFormLoader: boolean = false;
  public transactionHashRoylty: string = '';
  public transhUrl: string = '';

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private contractIntegrationService: ContractIntegrationsService,
    private toastr: ToastrService,
    private storageService: StorageService,
    private manageTransactionsDirective:ManageTransactionsDirective,
    private errorHandler: ErrorHandler
  ){

  }

  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);

    this.feesDetailsForm = this.formBuilder.group({
      platformFee: ['', [Validators.required]],
    });
    
    this.royaltyDetailsForm = this.formBuilder.group({
      royaltyFee: ['', [Validators.required]],
    });
    setTimeout(() => {
      this.getFeeDetails();
      this.transhUrl = environment[this.account.state.data.chain.id].TRANSH_URL;
    }, 1000);
  }

  /**
   * Gets platform fee
   */
  public getFeeDetails() {
    this.contractIntegrationService.getFees(this.regulated).then((fees:any) => {
      this.fees = {
        platformFee: fees[0],
        royaltyFee: fees[1],
        creatorRoyaltyFee: fees[2],
        clientRoyaltyFee: fees[3]
      };
      
      this.existPlatformFee = Number(fees[0]) / 100;
      this.feesDetailsForm.patchValue({
        platformFee: this.existPlatformFee
      })

      this.existRoyaltyFee = Number(fees[1]) / 100;
      this.royaltyDetailsForm.patchValue({
        royaltyFee: this.existRoyaltyFee
      })

    
    });
  }


  /**
   * Gets fees details form get
   */
  get feesDetailsFormGet() {
    return this.feesDetailsForm.controls;
  }

  /**
   * Gets royalty details form get
   */
  get royaltyDetailsFormGet() {
    return this.royaltyDetailsForm.controls;
  }

   /**
   * Royaltys details submit
   */
   public async royaltyDetailsSubmit() {
    this.royaltyDetailsFormSubmitted = true;
    if (this.royaltyDetailsForm.invalid) {
      return;
    }
    if (this.royaltyDetailsForm.controls.royaltyFee.value <= 0) {
      this.toastr.error('Fee should be greater than 0.');
      return;
    }
    this.royaltyFormLoader = true;
    let royaltyFeeAmount = Math.round((this.royaltyDetailsForm.controls.royaltyFee.value) * (100));
    let fees = {
      platformFee: this.fees.platformFee,
      royaltyFee: royaltyFeeAmount,
      creatorRoyaltyFee: this.fees.creatorRoyaltyFee,
      clientRoyaltyFee: this.fees.clientRoyaltyFee
    }
    this.setFees(fees,1);
  }

  /**
   * Sets fees
   * @param {object} fees 
   * @param {number} formType 
   */
  async setFees(fees,formType) {
    try{
    const { feesAbi, requiredGas } = await this.contractIntegrationService.setFees(fees, this.account.state.data.account, this.regulated);
    const message = {
      to: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
      data: feesAbi,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(requiredGas * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async (receipt) => {
        this.toastr.success('Fees updated successfully.');
        if(formType == 0)this.transactionHash = receipt['data']['transactionHash'];
        else this.transactionHashRoylty = receipt['data']['transactionHash'];
        
        this.feesFormLoader = false;
        this.royaltyFormLoader = false;
        this.royaltySplitterFormLoader = false;
        this.getFeeDetails();
      })
      .catch((error) => {
        this.feesFormLoader = false;
        this.royaltyFormLoader = false;
        this.royaltySplitterFormLoader = false;
      });
    }
    catch(error){
      this.feesFormLoader = false;
      this.royaltyFormLoader = false;
      this.royaltySplitterFormLoader = false;
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Fees Details submit
   */
  public async feesDetailsSubmit() {
    this.feesDetailsFormSubmitted = true;
    if (this.feesDetailsForm.invalid) {
      return;
    }
    if (this.feesDetailsForm.controls.platformFee.value < 0) {
      this.toastr.error('Invalid Fees.');
      return;
    }
    this.feesFormLoader = true;
    const platformFeeAmount = Math.round((this.feesDetailsForm.controls.platformFee.value) * (100));
    let fees = {
      platformFee: platformFeeAmount,
      royaltyFee: this.fees.royaltyFee,
      creatorRoyaltyFee: this.fees.creatorRoyaltyFee,
      clientRoyaltyFee: this.fees.clientRoyaltyFee
    }
    this.setFees(fees,0);
  }
  
}
