<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <app-card cardTitle="Currency list." [options]="false">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="position-abs">
                                <button type="button" class="btn btn-green float-right mb-4"
                                    (click)="openCurrencyPopup(addcurrencycontent,null)"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                        class="bi bi-plus" viewBox="0 0 16 16">
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                    </svg> Add
                                    currency.</button>

                                <button type="button" class="btn btn-green float-right mb-4"
                                    (click)="openChangeOrderPopup(changeOrderContent)"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                        class="bi bi-plus" viewBox="0 0 16 16">
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                    </svg>
                                    Change order.</button>

                            </div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>S.no.</th>
                                        <th>Name.</th>
                                        <th>Symbol.</th>
                                        <th>Image.</th>
                                        <th>Address.</th>
                                        <th>Network.</th>
                                        <th>Action.</th>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                    <ng-container *ngIf="!loader">
                                        <tr *ngFor="let data of currencyList; trackBy: trackByFn">
                                            <td>{{data.id + 1}}</td>
                                            <td>{{data.name}}</td>
                                            <td>{{data.symbol}}</td>
                                            <td><img src="{{data.image}}" alt="image" class="img-fluid" width="40"
                                                    height="40" onerror="this.src='assets/images/default-nft.svg'"></td>
                                            <td>
                                                <div class="d-flex gap-2 align-items-center">
                                                    {{data.address}}
                                                    <span class="cursor-pointer copyfile"
                                                        (click)="copyAddress(data.address)"> <svg viewBox="0 0 17 16"
                                                            width="20" height="20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:anim="http://www.w3.org/2000/anim" anim=""
                                                            anim:transform-origin="50% 50%" anim:duration="1"
                                                            anim:ease="ease-in-out">
                                                            <g id="Frame">
                                                                <path id="Vector"
                                                                    d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                                                                    stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round"></path>
                                                                <path id="Vector_2"
                                                                    d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                                                                    stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round"></path>
                                                            </g>
                                                        </svg></span>
                                                </div>
                                            </td>
                                            <td>{{data.network_name}} {{data.chain_id}}</td>
                                            <td class="action-section">
                                                <button type="button" class="btn btn-warning position-relative"
                                                    (click)="editCurrency(data.id)"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" viewBox="0 0 16 16" class="bi bi-pencil">
                                                        <path
                                                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325">
                                                        </path>
                                                    </svg><span class="edit-tooltip">Edit.</span></button>
                                                <button type="button" class="btn btn-danger position-relative"
                                                    (click)="openDeleteCurrency(data.id)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                        <path
                                                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                    </svg>
                                                    <span class="edit-tooltip">
                                                        Delete.
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr *ngIf="currencyList.length == 0 && !loader" class="text-center">
                                        <td colspan="6">No data found.</td>
                                    </tr>
                                    <tr *ngIf="loader" class="text-center">
                                        <td colspan="6">
                                            <div class="loader-wrap">Loading. <span class="Loaders"></span></div>
                                        </td>
                                    </tr>
                                </table>

                            </div>
                        </div>
                    </div>
                </app-card>
            </div>
        </div>
    </div>
</div>

<!-- BEGIN CURRENCY MODAL -->
<ng-template #addcurrencycontent let-modal>
    <form [formGroup]="addCustomCurrency" (ngSubmit)="currencySubmit()">
        <div class="modal-header">
            <h5 class="modal-title" *ngIf="!currencyUpdateStatus">Add Currency.</h5>
            <h5 class="modal-title" *ngIf="currencyUpdateStatus">Update Currency.</h5>
            <input type="text" class="form-control" hidden formControlName="_id">
            <a class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><svg
                    xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                    <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                </svg></a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 col-sm-6 mb-3">
                    <div class="form-group">
                        <label>Name. <label class="text-danger"> * </label> </label>
                        <input type="text" class="form-control" placeholder="Enter currency name."
                            formControlName="currencyName">
                        <div *ngIf="(addcustomcurrencyControls.currencyName.touched ||  customCurrencyFormSubmitted) && addcustomcurrencyControls.currencyName.errors?.required"
                            class="invalid-feedback-custom">
                            <small class="text-danger">This field is required.</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                    <div class="form-group">
                        <label>Symbol. <label class="text-danger"> * </label> </label>
                        <input type="text" class="form-control" placeholder="Enter currency symbol."
                            formControlName="currencySymbol">
                        <div *ngIf="(addcustomcurrencyControls.currencySymbol.touched ||  customCurrencyFormSubmitted) && addcustomcurrencyControls.currencySymbol.errors?.required"
                            class="invalid-feedback-custom">
                            <small class="text-danger">This field is required.</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                    <div class="form-group">
                        <label>Contract Address. ({{this.account.state.data.chain.id == '80002' ? 'TESTNET' :
                            'MAINNET'}}) <label class="text-danger"> * </label> </label>
                        <input type="text" class="form-control" placeholder="Enter currency address."
                            formControlName="currencyAddress">
                        <div *ngIf="(addcustomcurrencyControls.currencyAddress.touched ||  customCurrencyFormSubmitted) && addcustomcurrencyControls.currencyAddress.errors?.required"
                            class="invalid-feedback-custom">
                            <small class="text-danger">This field is required.</small>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                    <div class="form-group">
                        <label>Network. <label class="text-danger"> * </label> </label>
                        <input type="text" class="form-control" placeholder="Enter Network."
                            formControlName="networkName" readonly>
                    </div>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                    <div class="form-group">
                        <label>Image. <label class="text-danger"> * </label></label>
                        <input type="file" class="form-control" (change)="onChangeImage($event)">
                        <small class="text-danger">{{currencyImageError}}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">Cancel.</button>
            <button type="submit" class="btn btn-green"
                *ngIf="!currencyUpdateStatus && !customCurrencyFormProcessing">Create.</button>
            <button type="submit" class="btn btn-green"
                *ngIf="currencyUpdateStatus && !customCurrencyFormProcessing">Update.</button>
            <button class="btn btn-green" *ngIf="customCurrencyFormProcessing" disabled>
                Processing.<span class="loader-white"></span>
            </button>
        </div>
    </form>
</ng-template>
<!-- END CURRENCY MODAL -->

<!-- BEGIN CHANGE ORDER MODAL -->
<ng-template #changeOrderContent let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Change order.</h5>

        <a class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click');closeCurrencyChangeOrderModal()"><svg
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                <path
                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg></a>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="load-section" *ngIf="orderChangeLoading">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="list-group" (drop)="drop($event)" (dragover)="allowDrop($event)">
                    <table class="table table-bordered" [ngClass]="orderChangeLoading ? 'table-disable' : ''"
                        (click)="preventClick($event)">
                        <thead>
                            <tr>
                                <th>S.no.</th>
                                <th>Name.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of currencyList; let i = index">
                                <td>{{ i+1 }}</td>
                                <td class="cursor-pointer item-name" draggable="true"
                                    (dragstart)="dragStart($event, i)">
                                    <span>{{ item.name }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- END CHANGE ORDER MODAL -->


<!-- BEGIN Delete Currency Modal-->
<ng-template #deleteCurrencyContent let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Delete Currency.</h5>
        <a class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><svg
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                <path
                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg></a>
    </div>
    <div class="modal-body">
        <p>
            Are you sure want to delete this currency?.
            <span class="text-danger">This operation can not be undone.</span>
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close.</button>
        <button type="button" class="btn btn-danger" *ngIf="!customCurrencyFormProcessing"
            (click)="deleteCurrency()">Delete.</button>
        <button class="btn btn-green" *ngIf="customCurrencyFormProcessing" disabled>
            Processing.<span class="loader-white"></span>
        </button>
    </div>
</ng-template>
<!-- END Delete Currency Modal-->