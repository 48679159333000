import { Component, ElementRef, ErrorHandler, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { NftTraitsService } from 'src/app/services/nft-traits.service';
import { StorageService } from 'src/app/services/storage.service';
import Web3 from 'web3';

const web3 = new Web3(window['ethereum']);

@Component({
  selector: 'app-nft-trait',
  templateUrl: './nft-trait.component.html',
  styleUrls: ['./nft-trait.component.scss']
})
export class NftTraitComponent {
  regulated: boolean = false
  public account: any = {};
  @ViewChild("longContent") editTriatModal: TemplateRef<any>;
  @ViewChild("content") deleteTriatModal: TemplateRef<any>;
  @ViewChild("longLazymintContent") editTriatLazymintModal: TemplateRef<any>;
  @ViewChild("contentLazymint") deleteTriatLazymintModal: TemplateRef<any>;
  mandatoryCheckbox = false;
  public updateStatus: boolean = false;
  public currencyUpdateStatus: boolean = false;
  public nftTrait: any = [];
  public nftTraitForm: FormGroup;
  public nftTraitFormSubmitted: boolean = false;
  public nftTraitLazymintForm: FormGroup;
  public nftTraitLazymintFormSubmitted: boolean = false;
  public traitProcessing: boolean = false;
  public deleteTraitData: any = null;
  loader:boolean = true;
  nftTraitLazymint: any;
  nftCategory: any;
  normalMandatoryCheckbox = false;
  updateLazymintStatus = false;
  editData: any;
  allNFTLocations:string[] = []

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private nftTraitsService: NftTraitsService,
    private errorHandler: ErrorHandler
  ) {

  }
  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.nftTraitForm = this.formBuilder.group({
      attribute: ['', [Validators.required]],
      value: ['',],
      mandatory:[''],
      _id: ['']
    });

    this.nftTraitLazymintForm = this.formBuilder.group({
      category: ['', [Validators.required]],
      attribute: ['', [Validators.required]],
      value: ['',],
      mandatory:[''],
      _id: ['']
    });
    this.getAllTraits();
  }

  /**
  * Opens xl
  * @param content
  * @param [index]
  */
  openXl(content, id?: any) {
    this.normalMandatoryCheckbox = false;
    if (id != null || id != undefined) {
      this.updateStatus = true;
      const editData = this.nftTrait.find((item) => item._id === id);
      this.editData = editData;
      this.normalMandatoryCheckbox = editData.mandatory;
  let valueArray = [];
      editData.value.forEach(element => {
        element = { display: element, value: element, readonly: this.normalMandatoryCheckbox ? true : false }
        valueArray.push(element);
      });
      this.nftTraitForm.patchValue({ 'attribute': editData.attribute });
      this.nftTraitForm.patchValue({ 'value': valueArray });
      this.nftTraitForm.patchValue({ '_id': editData._id });
      
    } else {
      this.updateStatus = false;
      this.nftTraitForm.reset();
    }
    this.modalService.open(content, { size: 'xl', centered: true, backdrop : 'static', keyboard : false });
  }

  /**
  * Nfts trait submit
  * @returns
  */
  public async nftTraitSubmit() {
    try {
      this.nftTraitFormSubmitted = true;
      this.traitProcessing = true;
      if (this.nftTraitForm.invalid) {
        this.traitProcessing = false;
        return;
      }
      let attributeValues = [];
      if (this.nftTraitForm.controls.value.value != undefined && this.nftTraitForm.controls.value.value != '' && this.nftTraitForm.controls.value.value != null) {

        await this.nftTraitForm.controls.value.value.forEach(element => {
          if (element.value != undefined || element.value != '' || element.value != null) {
            // element = { display: element, value: element }

            attributeValues.push(element.value);
          }
        });
      }
      const params = {
        attribute: this.nftTraitForm.value.attribute,
        value: attributeValues,
        mandatory:this.normalMandatoryCheckbox
      }
      if(!this.updateStatus){
        params['type'] = 0
        params['mandatory'] = this.normalMandatoryCheckbox;
      }
      if(this.updateStatus && this.normalMandatoryCheckbox){
        params['mandatory'] = this.normalMandatoryCheckbox;
      }
      // Create Trait
      if (!this.updateStatus) {
        this.nftTraitsService.addTrait(params).subscribe({
          next: async (data: any) => {
            this.traitProcessing = false;
            this.nftTraitFormSubmitted = false;
            this.toastr.success(data.message);
            this.modalService.dismissAll();
            this.getAllTraits();
            this.nftTraitsService.emitNftTraitsRefreshFlag(true)
          },
          error: (error: any) => {
            this.traitProcessing = false;
            this.nftTraitFormSubmitted = false;
          },
        });
      } else {
        // Update Trait
        this.nftTraitsService.updateTrait(this.nftTraitForm.value._id, params).subscribe({
          next: async (data: any) => {
            this.nftTraitFormSubmitted = false;
            this.traitProcessing = false;
            this.toastr.success(data.message);
            this.modalService.dismissAll();
            this.getAllTraits();
            this.nftTraitsService.emitNftTraitsRefreshFlag(true)
          },
          error: (error: any) => {
            this.nftTraitFormSubmitted = false;
            this.traitProcessing = false;
            this.toastr.error(error.error.message);
          },
        });
      }
    } catch (err) {
      this.errorHandler.handleError(err);
    }
  }

  /**
   * Gets nft trait controls
   */
  get nftTraitControls() {
    return this.nftTraitForm.controls;
  }

  /**
   * Gets all traits
   */
  public getAllTraits() {
    this.loader = true;
    this.nftTraitsService.getAllTraits().subscribe(async (response: any) => {
      this.nftCategory = response.data.filter((triat) => {  if(triat.attribute == 'Category'){ return triat.value; }})[0].value;
      this.nftTrait = response.data.filter((triat) => {  if(triat.type == 0){ return triat; }});
      this.nftTrait.map((triat, index) => this.getAttribute(triat, index))
      this.allNFTLocations = response.data.find((trait:{value:string[],attribute:string}) => trait.attribute.toLowerCase() === 'location' ).value
      this.loader = false;
    },(_error) => {
      this.loader = false;
    });
  }

  /**
   * Gets attribute
   * @param trait 
   */
  private getAttribute(trait: any, index: number) {
    trait.id = index;
  }

  /**
   * Edits trait
   * @param {number} id 
   */
  editTrait(id: number) {
    this.openXl(this.editTriatModal, id);
  }
  /**
     * Track by function for ngFor loops
     * @param index
     * @param item
     */
  public trackByFn(index: number, item: any): any {
    return item._id || index;
  }

  /**
   * Deletes trait
   * @param {number} id
   */
  public deleteTrait(id: number) {
    this.deleteTraitData = id;
    this.modalService.open(this.deleteTriatModal, { centered: true, backdrop : 'static', keyboard : false });
  }

  /**
   * Deletes attribute
   */
  public deleteAttribute() {
    this.traitProcessing = true;
    this.nftTraitsService.deleteTrait(this.deleteTraitData).subscribe({
      next: async (data: any) => {
        this.traitProcessing = false;
        this.toastr.success(data.message);
        this.modalService.dismissAll();
        this.deleteTraitData = null;
        this.nftTraitsService.emitNftTraitsRefreshFlag(true)
        this.getAllTraits();
      },
      error: (error: any) => {
        this.traitProcessing = false;
        this.toastr.error(error.error.message);
      },
    });
  }

  /**
   * Normals mandatory change
   */
  normalMandatoryChange(){
    this.normalMandatoryCheckbox = !this.normalMandatoryCheckbox;
  }


  
}
