import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IApiResponse } from 'src/app/core/interfaces/app.interface';
import { CommonService } from 'src/app/services/common-service.service';
interface TagInput {
  display: string;
  value: string;
  readonly: boolean;
}

@Component({
  selector: 'app-partner-management',
  templateUrl: './partner-management.component.html',
  styleUrls: ['./partner-management.component.scss']
})


/**
 * Partner Management Component
 */
export class PartnerManagementComponent implements OnInit {
  partnerDetailsForm: FormGroup
  submitLoader: boolean;
  emails: string[] = [];
  validators = [this.checkPattern];
  errorMessages = {
    'pattern': 'Email must be in format abc@abc.com',
  };
  public emailData: TagInput[] = [];


  /**
   * constructor
   */
  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private toastr: ToastrService,
  ) { }

  /**
   * loaded on component initialization
   */
  ngOnInit(): void {
    this.partnerDetailsForm = this.fb.group({
      email: ['']
    })
    this.getPartnerEmail();
  }

  /**
   * get partner email and patch the value to the form
   */
  getPartnerEmail() {
    this.commonService.getPartnerEmail().subscribe({
      next: (response: IApiResponse) => {
        this.emailData = response.data['email'].map(item => ({
          display: item,
          value: item,
          readonly: false
        }));
        this.partnerDetailsForm.patchValue({ 'email': this.emailData });
      },
      error: (error: HttpErrorResponse) => {
        console.log(error.error.message);

      }
    })
  }

  private checkPattern(control: FormControl) {
    const patternRegex = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
    if (patternRegex.test(control.value)) {
    }
    else {
      return { 'pattern': true }
    }
  }

  /**
   * getter for submit button status
   */
  get isDisableButton(): boolean {
    return this.partnerDetailsForm.value.email.length === 0;
  }


  /**
   * on submitting the form
   */
  onSubmit() {
    this.submitLoader = true;
    const emailArray = this.partnerDetailsForm.value.email.map(item => item.value);
    const payload = { email: emailArray };
    this.commonService.storePartnerEmail(payload).subscribe({
      next: (response: IApiResponse) => {
        this.toastr.success(response.message);
        this.submitLoader = false;
      },
      error: (error: HttpErrorResponse) => {
        this.submitLoader = false;
        this.toastr.error(error.error.message);
      }
    })
  }
}
