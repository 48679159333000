<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="">
                    <app-card cardTitle="Enter Partner email to get delivery updates." [options]="false">
                        <form [formGroup]="partnerDetailsForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-12 col-sm-8 mb-3">
                                    <div class="form-group">
                                        <label for="label">Partner email. <label class="text-danger"> * </label>
                                        </label>
                                        <span class="ms-2 warning-text">(Type email and hit ENTER.)</span>
                                        <tag-input [(ngModel)]="emails" class="tag-chips" [theme]="'minimal-danger'"
                                            [addOnPaste]="true" [validators]="validators" name="attribute"
                                            formControlName="email" [errorMessages]="errorMessages"
                                            placeholder="Enter email address"
                                            secondaryPlaceholder="Enter email address"></tag-input>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4 text-sm-left text-center mt-4">
                                    <div class="btn-group">
                                        <button type="submit" class="btn btn-green" [disabled]="isDisableButton"
                                            *ngIf="!submitLoader">Submit.</button>
                                        <button class="btn btn-green" *ngIf="submitLoader" disabled>
                                            Processing. <span class="loader-white"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </app-card>
                </div>
            </div>
        </div>
    </div>
</div>