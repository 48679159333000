<div class="section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="mt-4 align-items-center d-flex">
          <div class="pcoded-content container">
            <app-card cardTitle="Edit royalty fees details." [options]="false">
              <form [formGroup]="feesDetailsForm" (ngSubmit)="feesDetailsSubmit()">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Collection address. </label>
                      <input type="text" class="form-control" value="{{collectionAddress}}" readonly
                        placeholder="Collection address.">
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="form-group">
                      <label>Collection name. </label>
                      <input type="text" class="form-control" value="{{collectionName}}" readonly
                        placeholder="Collection Name.">
                    </div>
                  </div>
                  <div class="col-12 col-sm-8">
                    <div class="form-group">
                      <label>Royalty fee. <label class="text-danger"> * </label> (Existing royalty fee
                        {{existRoyaltyFee}})</label>
                      <input type="number" class="form-control" placeholder="Royalty Fee." formControlName="royaltyFee"
                        min="0" step=".01" oninput="validity.valid||(value='');">
                      <div *ngIf="feesDetailsFormSubmitted && feesDetailsFormGet.royaltyFee.errors"
                        class="invalid-feedback-custom">
                        <small class="text-danger" *ngIf="feesDetailsFormGet.royaltyFee.errors.required">Please enter
                          royalty
                          fee.</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 text-sm-left text-center">
                    <div class="btn-group mt-4">
                      <button type="submit" class="btn btn-green" *ngIf="!feesFormLoader">Submit.</button>
                      <button class="btn btn-green" *ngIf="feesFormLoader" disabled>
                        Processing. <span class="loader-white"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <a *ngIf="transactionHash != ''" href="{{transhUrl}}{{transactionHash}}" target="_blank"
                class="d-block text-center">Click to view transaction.</a>
            </app-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>