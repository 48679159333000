import { HttpErrorResponse } from '@angular/common/http';
import { Component, ErrorHandler } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { BorrowAndLendService } from 'src/app/services/borrow-lend.service';
import { StorageService } from 'src/app/services/storage.service';
import Web3 from 'web3';
const web3 = new Web3(window['ethereum']);


@Component({
  selector: 'app-borrow-lend',
  templateUrl: './borrow-lend.component.html',
  styleUrls: ['./borrow-lend.component.scss']
})
export class BorrowLendComponent {
  regulated: boolean = false
  public account: any = {};
  public showDatePicker: boolean = false;
  public loanRequestDays: any = [];
  public customLoanDurationForm: FormGroup;
  public customLoanDurationSubmit: boolean = false;
  public customLoanDurationProcessing: boolean = false;
  public deleteLoanRequestDaysData: any = null;
  draggedIndex = -1;
  orderChangeLoading: boolean = false;
  loader:boolean = true;

  /**
   * Creates an instance of borrow lend component.
   */
  constructor(
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private borrowAndLendService: BorrowAndLendService,
    private clipboardservice:ClipboardService,
    private errorHandler: ErrorHandler
  ){

  }
  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
 
    this.customLoanDurationForm = this.formBuilder.group({
      days: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
    });

    this.getLoanRequestDays();
    
  }
  
   

  /**select custom duration */
  public showDurationCount() {
    this.showDatePicker = !this.showDatePicker;
  }

  /**
   * get all detail from form
   */
  get datevalueGet() {
    return this.customLoanDurationForm.controls;
  }

  /**
   * Opens delete loan request days popup
   * @param content 
   * @param days 
   */
  public openDeleteLoanRequestDaysPopup(content, days) {
    this.deleteLoanRequestDaysData = days;
    this.modalService.open(content, { centered: true, backdrop : 'static', keyboard : false });
  }

  public addLoanRequestDays() {
    try {
      this.customLoanDurationSubmit = true;
      this.customLoanDurationProcessing = true;
      if (this.customLoanDurationForm.invalid) {
        this.customLoanDurationProcessing = false;
        return;
      }
      const params = {
        days: this.customLoanDurationForm.get('days').value,
      }
      this.borrowAndLendService.addLoanRequestDays(params).subscribe({
        next: (res: any) => {
          this.customLoanDurationProcessing = false;
          this.customLoanDurationSubmit = false;
          this.customLoanDurationForm.reset();
          this.toastr.success(res.message)
          this.getLoanRequestDays();
        },
        error: (err: HttpErrorResponse) => {
          // this.toastr.error(err.error.message) // because handled in http-error-interceptor
          this.customLoanDurationProcessing = false;
          this.customLoanDurationSubmit = false;
        }
      })
    }
    catch (err) {
      this.customLoanDurationSubmit = false;
      this.customLoanDurationProcessing = false;
      this.errorHandler.handleError(err);
    }
  }

  /**
   * Gets loan request days
   */
  public getLoanRequestDays() {
    this.loader = true;
    this.borrowAndLendService.getLoanRequestDays().subscribe({
      next: (res: any) => {
        this.loanRequestDays = res.data;
        this.loader = false;
      },
      error: (_err) => {
        this.loader = false;
      }
    })
  }
  
  /**
   * Deletes loan request days
   */
  public deleteLoanRequestDays() {
    this.customLoanDurationProcessing = true;
    this.borrowAndLendService.deleteLoanRequestDays(this.deleteLoanRequestDaysData).subscribe({
      next: async (data: any) => {
        this.toastr.success(data.message);
        this.modalService.dismissAll();
        this.getLoanRequestDays();
        this.customLoanDurationProcessing = false;
      },
      error: (error: any) => {
        this.customLoanDurationProcessing = false;
        this.toastr.error(error.error.message);
      },
    });
  }

}
