<div class="section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <app-card cardTitle="Loan duration." [options]="false">
              <!--loan duration and time-->
              <div class="row">
                <div class="col-lg-12 mb-2">
                  <div class="position-abs">
                    <div><a class="btn btn-green" (click)="showDurationCount()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                    </svg> Add custom duration.</a></div>
                  </div>
                  <ul class="currency_list">
                    <li *ngFor="let day of loanRequestDays">{{day.days}} days. <a
                        (click)="openDeleteLoanRequestDaysPopup(deleteLoanRequestDaysContent,day.days)"
                        class="cancel-button">x</a></li>
                    <h5 *ngIf="loanRequestDays.length === 0 && !loader">No records found</h5>
                    <div class="loader-wrap" *ngIf="loader">Loading. <span class="Loaders"></span></div>
                  </ul>
                </div>
              </div>
              <div class="row" *ngIf="showDatePicker">
                <div class="col-lg-6 mb-3">
                  <form [formGroup]="customLoanDurationForm" (ngSubmit)="addLoanRequestDays()">
                    <div class="row ">
                      <div class="col-lg-12 mb-3">
                        <label for="label">Days.</label>
                        <input type="text" class="form-control" placeholder="30 days." formControlName="days">
                        <div *ngIf="customLoanDurationSubmit && datevalueGet.days.errors?.required">
                          <small class="text-danger">Please
                            enter day. </small>
                        </div>
                        <div *ngIf="datevalueGet.days.errors?.pattern">
                          <small class="text-danger">Accepts only whole numbers.</small>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="text-right">
                          <button *ngIf="!customLoanDurationProcessing" type="submit"
                            class="btn btn-green">Submit.</button>
                          <button class="btn btn-green" *ngIf="customLoanDurationProcessing" disabled>
                            Processing. <span class="loader-white"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <!--end-->
            </app-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- BEGIN Delete LOAN REQUEST DAYS Modal-->
<ng-template #deleteLoanRequestDaysContent let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Delete loan request day.</h5>
    <a class="btn-close" aria-label="Close"
      (click)="modal.dismiss('Cross click')"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"  viewBox="0 0 16 16">
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
    </svg></a>
  </div>
  <div class="modal-body">
    <p>
      Are you sure want to delete?.
      <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close.</button>
    <button type="button" class="btn btn-danger" *ngIf="!customLoanDurationProcessing"
      (click)="deleteLoanRequestDays()">Delete.</button>
    <button class="btn btn-green" *ngIf="customLoanDurationProcessing" disabled>
      Processing. <span class="loader-white"></span>
    </button>
  </div>
</ng-template>
<!-- END Delete LOAN REQUEST DAYS Modal-->