import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { IApiResponse } from '../core/interfaces/app.interface';

const web3 = new Web3(window['ethereum']);

@Injectable({
  providedIn: 'root',
})
/**
 * Export Class
 */
export class CommonService {
  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
  ) { }
  /**
   * Orders
   * @param {string} tokenType
   * @param {string} saleType
   * @param {string} seller
   * @param {string} tokenAddress
   * @param {number} tokenId
   * @param {string} tokenAmount
   * @param {number} price
   * @param {time} startTime
   * @param {time} endTime
   * @param {number} nonce
   * @return {object}
   */
  public async Order(tokenType: string, saleType: string, seller: string, tokenAddress: string, tokenId: number, tokenAmount: number, price: number, startTime: number, endTime: number, nonce: number) {
    return {
      tokenType,
      saleType,
      seller,
      tokenAddress,
      tokenId,
      tokenAmount,
      price: price > 0 ? await web3.utils.toWei(price.toString(), 'ether') : 0,
      startTime,
      endTime,
      nonce,
    };
  }

  /**
   * Bid Order
   * @param {response} order
   * @param {string} buyer
   * @param {string} bidAmount
   * @return {object}
   */
  public async BidOrder(order: any = {}, buyer: string, bidAmount: any) {
    return {
      ...order,
      buyer,
      bidAmount: await web3.utils.toWei(bidAmount.toString(), 'ether'),
    };
  }

  /**
   *
   * @param {string} url
   * @param {file} file
   */
  public async uploadToPresignedUrl(url: string, file) {
    const response = await this.http.put(url, file).toPromise();
    return response;
  }

  /**
   * store email address which is used to receive delivery updates
   */
  storePartnerEmail(emails: { email: string[] }): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.API_BASE_URL_V2}/admin/partner-email`, emails);

  }

  /**
   * get partner emails
   */
  getPartnerEmail(): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.API_BASE_URL_V2}/admin/partner-email`);

  }
}
