<div class="modal-body">
  <h4 class="modal-title text-center mb-3">Bulk Mint is Processing</h4>
  <div class="d-flex justify-content-between align-items-center gap-4 mb-3">
    <div>
      <h6>Step 1</h6>
      <p class="mb-0">Uploading files to backend</p>
    </div>
    <div class="text-primary" *ngIf="step1 == 0" role="status">
      - -
    </div>
    <div class="spinner-border text-primary" *ngIf="step1 == 1" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <img src="assets/images/green-tick.svg" width="24" height="24" *ngIf="step1 == 2" alt="">
    <img src="assets/images/red-cross.svg" width="24" height="24" *ngIf="step1 == -1" alt="">
  </div>
  <div class="d-flex justify-content-between align-items-center gap-4 mb-3">
    <div>
      <h6>Step 2</h6>
      <p class="mb-0 ">Uploading images to IPFS</p>
      <p *ngIf="status?.nftCount"><span>{{status?.nftCount == status?.nftProcessed ? "Processed:" : "Processing:"}}</span> {{status?.nftProcessed}}/{{status?.nftCount}}</p>
    </div>
    <div class="text-primary" *ngIf="step2 == 0" role="status">
      - -
    </div>
    <div class="spinner-border text-primary" *ngIf="step2 == 1" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <img src="assets/images/green-tick.svg" width="24" height="24" *ngIf="step2 == 2" alt="">
    <img src="assets/images/red-cross.svg" width="24" height="24" *ngIf="step2 == -1" alt="">
  </div>
  <div class="d-flex justify-content-between align-items-center gap-4 mb-3">
    <div>
      <h6>Step 3</h6>
      <p class="mb-0 ">Uploading attributes to IPFS</p>
      <p *ngIf="status?.nftCount"><span>{{status?.nftCount == status?.attributesProcessed ? "Processed:" : "Processing:"}}</span> {{status?.attributesProcessed}}/{{status?.nftCount}}</p>
    </div>
    <div class="text-primary" *ngIf="step3 == 0" role="status">
      - -
    </div>
    <div class="spinner-border text-primary" *ngIf="step3 == 1" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <img src="assets/images/green-tick.svg" width="24" height="24" *ngIf="step3 == 2" alt="">
    <img src="assets/images/red-cross.svg" width="24" height="24" *ngIf="step3 == -1" alt="">
  </div>
  <div class="d-flex justify-content-between align-items-center gap-4 mb-3">
    <div>
      <h6>Step 4</h6>
      <p class="mb-0 ">Bulk Mint Ready to be Minted</p>

    </div>
    <div class="text-primary" *ngIf="step4 == 0" role="status">
      - -
    </div>
    <div class="spinner-border text-primary" *ngIf="step4 == 1" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <img src="assets/images/green-tick.svg" width="24" height="24" *ngIf="step4 == 2" alt="">
    <img src="assets/images/red-cross.svg" width="24" height="24" *ngIf="step4 == -1" alt="">
  </div>
  <div class="proceed_btn">
    <a href="/list-mint" class="btn btn-green" *ngIf="step4 == 2">Proceed to Batch Mint</a>
  </div>
  <div class="proceed_btn">
    <div href="/list-mint" class="btn btn-green" *ngIf="step1 == -1 || step2 == -1 || step3 == -1 || step4 == -1"
      (click)="activeModal.dismiss('Cross click')">
      Close
    </div>
  </div>
</div>
