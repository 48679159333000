import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApiResponse } from '../core/interfaces/app.interface';
import { IFaqChangeOrder } from '../shared/interface/interface';

@Injectable({
  providedIn: 'root'
})
export class FaqManagementService {

  constructor(private http: HttpClient) { }

  /**
   * Gets faq
   * @param {string} search 
   * @param {string} categoryId 
   * @param {string} selected 
   * @param {number} limit 
   * limit is set to 1000 by default because in the backend it is set to 1000
   * @returns  
   */
  getFaq(search: string, categoryId: string, selected: string, page: number = 0, limit: number = 1000) {
    return this.http.get(`${environment.API_BASE_URL}/admin/faq-list?search=${search}&categoryId=${categoryId}&selected=${selected}&page=${page}&limit=${limit}`);
  }

  /**
   * Gets faq by id
   * @param {string} id 
   * @returns  
   */
  getFaqById(id: string) {
    return this.http.get(`${environment.API_BASE_URL}/admin/faq?id=${id}`);
  }

  /**
   * Adds faq
   * @param {object} data 
   * @returns  
   */
  addFaq(data: object) {
    return this.http.post(`${environment.API_BASE_URL}/admin/faq`, data);
  }

  /**
   * Adds faq
   * @param {object} data 
   * @param {string} id 
   * @returns  
   */
  updateFaq(data: object, id: string) {
    return this.http.patch(`${environment.API_BASE_URL}/admin/faq?id=${id}`, data);
  }

  /**
   * Deletes faq
   * @param {string} id 
   * @returns  
   */
  deleteFaq(id) {
    return this.http.delete(`${environment.API_BASE_URL}/admin/faq?id=${id}`);
  }

  /**
 * Changes faq order
 * @param {currencyOrderChangeRequest} updates 
 */
  changFAQOrder(data: IFaqChangeOrder): Observable<IApiResponse> {
    let obj = { updates: data };
    return this.http.patch<IApiResponse>(`${environment.API_BASE_URL_V2}/admin/update-faq-order`, obj)
  }



  /**
   * Gets category
   * @returns  
   */
  getCategory() {
    return this.http.get(`${environment.API_BASE_URL}/admin/faq-categories`);
  }

  /**
   * Gets Category by id
   * @param {string} id 
   * @returns  
   */
  getCategoryById(id: string) {
    return this.http.get(`${environment.API_BASE_URL}/admin/faq-category?id=${id}`);
  }

  /**
   * Adds Category
   * @param {object} data 
   * @returns  
   */
  addCategory(data: object) {
    return this.http.post(`${environment.API_BASE_URL}/admin/faq-category`, data);
  }

  /**
   * Adds Category
   * @param {object} data 
   * @param {string} id 
   * @returns  
   */
  updateCategory(data: object, id: string) {
    return this.http.patch(`${environment.API_BASE_URL}/admin/faq-category?id=${id}`, data);
  }

  /**
   * Deletes Category
   * @param {string} id 
   * @returns  
   */
  deleteCategory(id) {
    return this.http.delete(`${environment.API_BASE_URL}/admin/faq-category?id=${id}`);
  }

}
