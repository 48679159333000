import { Component, ErrorHandler } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { StorageService } from 'src/app/services/storage.service';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const web3 = new Web3(window['ethereum']);

@Component({
  selector: 'app-royalty-splitter',
  templateUrl: './royalty-splitter.component.html',
  styleUrls: ['./royalty-splitter.component.scss']
})
export class RoyaltySplitterComponent {
  public account: any = {};
  public feesDetailsForm: FormGroup;
  public feesDetailsFormSubmitted: boolean = false;
  regulated: boolean = false
  public royaltySplitterForm: FormGroup;
  public royaltySplitterFormSubmitted: boolean = false;
  public royaltySplitterFormLoader: boolean = false;
  public transactionHashRoyaltySplitter: string = '';
  public transhUrl: string = '';
  fees: any;
  public transactionHash: string = '';
  public feesFormLoader: boolean = false;
  public royaltyFormLoader: boolean = false;
  
  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private contractIntegrationService: ContractIntegrationsService,
    private toastr: ToastrService,
    private manageTransactionsDirective:ManageTransactionsDirective,
    private errorHandler: ErrorHandler
  ){
    
  }
  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);

    this.royaltySplitterForm = this.formBuilder.group({
      creatorFee: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
      clientFee: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
    });
    
    this.feesDetailsForm = this.formBuilder.group({
      platformFee: ['', [Validators.required]],
    });
    setTimeout(() => {
      this.getFeeDetails();
    }, 1000);
  }
 
  /**
   * Gets platform fee
   */
  public getFeeDetails() {
    this.contractIntegrationService.getFees(this.regulated).then((fees:any) => {
      this.fees = {
        platformFee: fees[0],
        royaltyFee: fees[1],
        creatorRoyaltyFee: fees[2],
        clientRoyaltyFee: fees[3]
      };
      this.royaltySplitterForm.patchValue({
        creatorFee: fees[2]
      })

      this.royaltySplitterForm.patchValue({
        clientFee: fees[3]
      })
    });
  }

  get royaltySplitterFormGet() {
    return this.royaltySplitterForm.controls;
  }

  public async royaltySplitterSubmit() {
    this.royaltySplitterFormSubmitted = true;
    if (this.royaltySplitterForm.invalid) {
      return;
    }
    const totalFee = +this.royaltySplitterForm.controls.creatorFee.value + +this.royaltySplitterForm.controls.clientFee.value;
    if (+totalFee != 100) {
      this.toastr.error('The Creator and Client Total fee should be 100%.');
      return;
    }
    else {
      this.royaltySplitterFormLoader = true;
      let fees = {
        platformFee: this.fees.platformFee,
        royaltyFee: this.fees.royaltyFee,
        creatorRoyaltyFee: this.royaltySplitterForm.controls.creatorFee.value,
        clientRoyaltyFee: this.royaltySplitterForm.controls.clientFee.value
      }
      this.setFees(fees);
    }
  }

  async setFees(fees) {
    try{
    const { feesAbi, requiredGas } = await this.contractIntegrationService.setFees(fees, this.account.state.data.account, this.regulated);
    const message = {
      to: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
      data: feesAbi,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(requiredGas * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async (receipt) => {
        this.toastr.success('Fees updated successfully.');
        this.transactionHash = receipt['data']['transactionHash'];
        this.feesFormLoader = false;
        this.royaltyFormLoader = false;
        this.royaltySplitterFormLoader = false;
        this.getFeeDetails();
      })
      .catch((error) => {
        this.feesFormLoader = false;
        this.royaltyFormLoader = false;
        this.royaltySplitterFormLoader = false;
      });
    }
    catch(error){
      this.feesFormLoader = false;
      this.royaltyFormLoader = false;
      this.royaltySplitterFormLoader = false;
      this.errorHandler.handleError(error);
    }
  }
}

