import { Directive } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appErrorHandler]'
})
export class ErrorHandlerDirective {

  constructor(
    private toastr:ToastrService,
  ) { }

  /**
   * Handles error
   * @param {any} response 
   */
  public handleError(response: any) {
    switch (response) {
      case 4001: {
        this.toastr.error('User rejected');
        break;
      }
      case 404: {
        this.toastr.error('Requested route not found');
        break;
      }
      case 500: {
        this.toastr.error('Internal Server error');
        break;
      }
      case 503: {
        this.toastr.error('Service Unavailable');
        break;
      }
      default: {
        this.toastr.error('Transaction failed');
        break;
      }
    }
  }
  
}

