import { Component, ErrorHandler, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { BorrowAndLendService } from 'src/app/services/borrow-lend.service';
import { StorageService } from 'src/app/services/storage.service';
import { ICurrencyChangeOrder } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
const web3 = new Web3(window['ethereum']);

@Component({
  selector: 'app-currency-management',
  templateUrl: './currency-management.component.html',
  styleUrls: ['./currency-management.component.scss']
})
export class CurrencyManagementComponent {
  regulated: boolean = false
  public account: any = {};
  @ViewChild("addcurrencycontent", { static: false }) addcurrencycontent: TemplateRef<any>;
  @ViewChild("deleteCurrencyContent", { static: false }) deleteCurrencyContent: TemplateRef<any>;
  @ViewChild("changeOrderContent", { static: false }) changeOrderContent: TemplateRef<any>;
  public customCurrencyFormSubmitted: boolean = false;
  public currencyUpdateStatus: boolean = false;
  public currencyList: any = [];
  public addCustomCurrency: FormGroup;
  public customCurrencyFormProcessing: boolean = false;
  public showDatePicker: boolean = false;
  public isCurrencyListLoader: boolean = true;
  public deleteCurrencyData: any = null;
  currencyImage: any;
  draggedIndex = -1;
  orderChangeLoading: boolean = false;
  loader: boolean = true;
  currentPageLimit: number = 10;
  currencyImageError = '';

  /**
   * Creates an instance of borrow lend component.
   */
  constructor(
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private borrowAndLendService: BorrowAndLendService,
    private clipboardservice: ClipboardService,
    private errorHandler: ErrorHandler
  ) {

  }
  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);

    this.addCustomCurrency = this.formBuilder.group({
      currencyName: ['', [Validators.required]],
      currencySymbol: ['', [Validators.required]],
      currencyAddress: ['', [Validators.required]],
      networkName: ['', [Validators.required]],
      _id: ['']
    });

    this.getAllCurrencies(1, this.currentPageLimit);

  }

  /**
   * Prevents click
   * @param event 
   */
  preventClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
  /**
   * Drags start
   * @param {DragEvent} _event 
   * @param {number} index 
   */
  dragStart(_event: DragEvent, index: number) {
    this.draggedIndex = index;
  }

  /**
   * Allows drop
   * @param {DragEvent} event 
   */
  allowDrop(event: DragEvent) {
    event.preventDefault(); // Necessary to allow dropping
  }

  /**
   * Finds drop index
   * @param {DragEvent} event 
   * @returns drop index 
   */
  findDropIndex(event: DragEvent): number | undefined {
    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();
    const offsetY = event.clientY - rect.top;
    const targetIndex = this.currencyList.findIndex(item => item.name === target.innerText);
    if (targetIndex >= 0) {
      const before = offsetY < rect.height / 2;
      if (before) {
        return targetIndex;
      } else {
        return Math.min(targetIndex + 1, this.currencyList.length);
      }
    }
  }

  /**
   * Drops currency order list
   * @param {DragEvent} event 
   */
  drop(event: DragEvent) {
    this.orderChangeLoading = true;
    event.preventDefault();
    const dropIndex = this.findDropIndex(event);
    if (dropIndex !== undefined && dropIndex !== this.draggedIndex) {
      const item = this.currencyList[this.draggedIndex];
      this.currencyList.splice(this.draggedIndex, 1); // Remove the item from its original position
      this.currencyList.splice(dropIndex, 0, item); // Insert the item at its new position
      let newOrderArray: any = [];

      this.currencyList.map((item, index) => {
        newOrderArray.push({
          order: index,
          _id: item._id,
          chain_id: item.chain_id
        })
        if (this.currencyList.length == index + 1) {
          this.orderChangeProcess(newOrderArray);
        }
      })

    } else {
      this.orderChangeLoading = false;
      this.draggedIndex = -1;
    }
  }

  /**
   * Orders change process
   * @param {ICurrencyChangeOrder} newOrderArray 
   */
  orderChangeProcess(newOrderArray: ICurrencyChangeOrder) {
    this.borrowAndLendService.changeCurrencyOrder(newOrderArray).subscribe((response: any) => {
      this.orderChangeLoading = false;
      this.toastr.success(response.message);
      this.draggedIndex = -1;
    },
      (error: any) => {
        this.orderChangeLoading = false;
        this.toastr.error(error.message);
      }
    )
  }

  /**
   * Opens currency popup
   * @param {any} content 
   * @param {number} index 
   */
  public openCurrencyPopup(content: any, index: number) {
    this.currencyImageError = '';
    this.customCurrencyFormSubmitted = false;
    if (index != null) {
      this.currencyUpdateStatus = true;
      const editData = this.currencyList[index];
      this.addCustomCurrency.patchValue({ 'currencyName': editData.name });
      this.addCustomCurrency.patchValue({ 'currencySymbol': editData.symbol });
      this.addCustomCurrency.patchValue({ 'currencyAddress': editData.address });
      this.addCustomCurrency.patchValue({ 'networkName': editData.network_name });
      this.addCustomCurrency.patchValue({ '_id': editData._id });
    } else {
      this.currencyUpdateStatus = false;
      this.addCustomCurrency.reset();
      setTimeout(() => {
        this.account.state.chains.map((item) => {
          if (item.id == this.account.state.data.chain.id) {
            this.addCustomCurrency.patchValue({
              networkName: item.name
            })
          }
        })
      }, 100);
    }
    this.modalService.open(content, { size: 'xl', centered: true, backdrop: 'static', keyboard: false });
  }

  /**Add or Update currency*/
  public async currencySubmit() {
    try {
      this.customCurrencyFormSubmitted = true;
      this.customCurrencyFormProcessing = true;
      if (this.addCustomCurrency.invalid) {
        this.customCurrencyFormProcessing = false;
        return;
      }
      if (!this.currencyImage && !this.currencyUpdateStatus) {
        this.customCurrencyFormProcessing = false;
        this.currencyImageError = 'This field is required.';
        return;
      }
      else {
        this.currencyImageError = '';
      }

      let networkName = this.addCustomCurrency.get('networkName').value;

      let web3 = new Web3(environment[this.account.state.data.chain.id].PROVIDER);
      let currencyAddress = await web3.utils.toChecksumAddress(this.addCustomCurrency.get('currencyAddress').value);
      let code = await web3.eth.getCode(currencyAddress);

      if (code === '0x') {
        this.customCurrencyFormProcessing = false;
        return this.toastr.error('Not valid currency address.');
      }

      const params = {
        name: this.addCustomCurrency.get('currencyName').value,
        symbol: this.addCustomCurrency.get('currencySymbol').value,
        address: this.addCustomCurrency.get('currencyAddress').value,
        network_name: networkName,
        chain_id: this.account.state.data.chain.id
      }
      if (this.currencyImage) {
        params['image'] = await this.imageUploadProcess()
      }
      if (!this.currencyUpdateStatus) {
        this.borrowAndLendService.addCurrency(params).subscribe({
          next: (res: any) => {
            this.toastr.success(res.message)
            this.customCurrencyFormProcessing = false;
            this.modalService.dismissAll();
            this.getAllCurrencies(1, this.currentPageLimit);
          },
          error: (err) => {
            this.toastr.error(err.error.message || err.message)
            this.customCurrencyFormProcessing = false;
          }
        })
      } else {
        this.borrowAndLendService.updateCurrency(this.addCustomCurrency.get('_id').value, params).subscribe({
          next: (res: any) => {
            this.toastr.success(res.message)
            this.customCurrencyFormProcessing = false;
            this.modalService.dismissAll();
            this.getAllCurrencies(1, this.currentPageLimit);
          },
          error: (err) => {
            this.toastr.error(err.message)
            this.customCurrencyFormProcessing = false;
          }
        })
      }
    }
    catch (err) {
      this.customCurrencyFormSubmitted = false;
      this.customCurrencyFormProcessing = false;
      this.errorHandler.handleError(err);
    }
  }

  /**
 * Gets add currency controls
 */
  get addcustomcurrencyControls() {
    return this.addCustomCurrency.controls;
  }


  /**
   * Gets all currencies
   * @param {number} page 
   * @param {number} limit 
   */
  public getAllCurrencies(page: number, limit: number) {
    this.loader = true;
    this.borrowAndLendService.getAllCurrencies(page, limit).subscribe(async (response: any) => {
      this.loader = false;
      // filters the list based on the is_deleted key and sort the list based on the order key (ascending order key)
      this.currencyList = response.data.filter(currency => !currency.is_deleted).sort((a, b) => a?.order - b?.order);
      this.currencyList.map((currency, index) => currency.id = index);
      this.loader = false;
    }, (_error) => {
      this.loader = false;
    });

  }

  /**select custom duration */
  public showDurationCount() {
    this.showDatePicker = !this.showDatePicker;
  }


  /**
     * Track by function for ngFor loops
     * @param index
     * @param item
     */
  public trackByFn(index: number, item: any): any {
    return item._id || index;
  }

  /**
   * copy collection wallet address
   */
  copyAddress(add: string) {
    this.clipboardservice.copyFromContent(add);
    this.toastr.success('Copied address');
  }

  /**
   * Edits currency
   * @param {number} id 
   */
  editCurrency(id: number) {
    this.openCurrencyPopup(this.addcurrencycontent, id);
  }

  /**
   * Opens delete currency
   * @param {number} index 
   */
  public openDeleteCurrency(index: number) {
    this.deleteCurrencyData = this.currencyList[index];
    this.modalService.open(this.deleteCurrencyContent, { centered: true, backdrop: 'static', keyboard: false });
  }

  /**
   * Deletes currency
   */
  public deleteCurrency() {
    this.customCurrencyFormProcessing = true;
    this.borrowAndLendService.deleteCurrency(this.deleteCurrencyData._id).subscribe({
      next: async (data: any) => {
        this.toastr.success(data.message);
        this.modalService.dismissAll();
        this.customCurrencyFormProcessing = false;
        this.deleteCurrencyData = null;
        this.getAllCurrencies(1, this.currentPageLimit);
      },
      error: (error: any) => {
        this.customCurrencyFormProcessing = false;
        this.toastr.error(error.error.message);
      },
    });
  }

  /**
  * Determines whether change image on
  * @param {any} event 
  */
  onChangeImage(event: any) {
    this.currencyImage = event.target.files[0];
  }

  /**
   * Images upload process
   */
  imageUploadProcess() {
    return new Promise((resolve, reject) => {
      this.borrowAndLendService.imageUpload(this.currencyImage).subscribe({
        next: (response: any) => {
          resolve(response['data'].file[0].Url);
        },
        error: (error: any) => {
          reject({ status: false, data: error });
        }
      })
    })
  }

  /*** 
 * modal view
*/
  public openChangeOrderPopup(content: any) {
    this.modalService.open(content, { size: 'md', centered: true, backdrop: 'static', keyboard: false });
  }

  /**
   * close currency change order modal
   */
  closeCurrencyChangeOrderModal() {
    this.getAllCurrencies(1, this.currentPageLimit);
    this.orderChangeLoading = false;
  }
}
