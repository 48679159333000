import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailSubscriptionService {

  constructor(private http: HttpClient) { }

  /**
   * Fetches a list of email subscriptions, paginated.
   *
   * @param pagination A string in the form of "?page=<page number>&limit=<items per page>"
   * @returns An observable of an IApiResponse containing the list of email subscriptions
   */
  fetchEmailSubscription(pagination: string): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL_V2}/admin/visitors-list${pagination}`);
  }
}
