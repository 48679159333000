import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IApiResponse } from 'src/app/core/interfaces/app.interface';
import { ManageDocumentService } from 'src/app/services/manage-document.service';

@Component({
  selector: 'app-manage-document',
  templateUrl: './manage-document.component.html',
  styleUrls: ['./manage-document.component.scss']
})
export class ManageDocumentComponent implements OnInit {
  public privacyForm:FormGroup;
  privacyFormSubmitted: boolean = false;
  public privacyFormLoader: boolean = false;
  public agreementForm:FormGroup;
  agreementFormSubmitted: boolean = false;
  public agreementFormLoader: boolean = false;
  privacyFileError: string = '';
  agreementFileError: string = '';
  privacyPolicyUrl: string = '';
  useragreementUrl: string = '';
  @ViewChild('privacyFileInput') privacyFileInput!: ElementRef;
  @ViewChild('userAgreementFileInput') userAgreementFileInput!: ElementRef;

  constructor(
    public formBuilder:FormBuilder,
    private manageDocumentService:ManageDocumentService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

    this.privacyForm = this.formBuilder.group({
      privacyPolicy:[null,Validators.required],
    })

    this.agreementForm = this.formBuilder.group({
      useragreement:[null,Validators.required],
    })

    this.getDocument();
  }

  get privacyFormGet(){
    return this.privacyForm.controls;
  }
 
  get agreementFormGet(){
    return this.agreementForm.controls;
  }

  /**
   * Privacys form submit
   */
  privacyFormSubmit(){
    this.privacyFormSubmitted = true;
    if(this.privacyForm.value.privacyPolicy == ''){
      this.privacyFileError = 'Please upload privacy policy document.'
      return
    }
    else{
      this.privacyFileError = '';
      this.privacyFormLoader = true;
      this.fileUploadProcess(this.privacyForm.value.privacyPolicy,'privacy_policy')
    }
    
  }

  /**
   * agreements form submit
   */
  agreementFormSubmit(){
    this.agreementFormSubmitted = true;
    if(this.agreementForm.value.useragreement == ''){
      this.agreementFileError = 'Please upload user agreement document.'
      return
    }
    else{
      this.agreementFileError = '';
      this.agreementFormLoader = true;
      this.fileUploadProcess(this.agreementForm.value.useragreement,'user_agreement');
    }
  }

  /**
   * Files change
   * @param {any} event 
   * @param {string} type 
   */
  fileChange(event: any, type: string) {
    if (type == 'privacy_policy') {
      if (event.target.files[0].type != 'application/pdf') {
        this.agreementFileError = '';
        this.privacyFileError = 'Only pdf format is supported.'
        this.privacyForm.patchValue({
          privacyPolicy: ''
        })
      }
      else {
        this.privacyFileError = '';
        this.privacyForm.patchValue({
          privacyPolicy: event.target.files[0]
        })
      }
    }
    else {
      if (event.target.files[0].type != 'application/pdf') {
        this.privacyFileError = '';
        this.agreementFileError = 'Only pdf format is supported.'
        this.agreementForm.patchValue({
          useragreement: ''
        })

      }
      else {
        this.agreementFileError = '';
        this.agreementForm.patchValue({
          useragreement: event.target.files[0]
        })
      }
    }
  }

  /**
   * Files upload process
   * @param {File} file 
   * @param {string} type 
   */
  fileUploadProcess(file:File,type:string){
    this.manageDocumentService.uploadDocument(file,type).subscribe((_response:IApiResponse) => {
      this.toastr.success('File uploaded successfully.');
      if (type === 'privacy_policy') {
        this.privacyFormLoader = false;
        this.privacyForm.patchValue({
          privacyPolicy: null
        });
        if (this.privacyFileInput) {
          this.privacyFileInput.nativeElement.value = '';
        }
      } else if (type === 'user_agreement') {
        this.agreementFormLoader = false;
        this.agreementForm.patchValue({
          userAggrement: null
        });
        if (this.userAgreementFileInput) {
          this.userAgreementFileInput.nativeElement.value = '';
        }
      }

      this.getDocument();
    },
    (_error) => {
      this.toastr.error('Something went .wrong');
      if(type == 'privacy_policy'){
        this.privacyFormLoader = false;
      }
      else{
        this.agreementFormLoader = false;
      }
    }
    )
  }

  /**
   * Gets document
   */
  getDocument(){
    this.manageDocumentService.getDocument('privacy_policy').subscribe((response) => {
      if(response['data'].file){
      this.privacyPolicyUrl = response['data'].file[0].Url;
      }
    })
    this.manageDocumentService.getDocument('user_agreement').subscribe((response) => {
      if(response['data'].file){
      this.useragreementUrl = response['data'].file[0].Url;
      }
    })
  }
}
