<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <app-card cardTitle="Website Visitors." [options]="false" [style]="'position:relative'">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>S.no.</th>
                                        <th>Email.</th>
                                        <th>Visited At.</th>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                    <tr *ngFor="let item of emailList; let i = index">
                                        <td>{{i + 1}}</td>
                                        <td>{{item.email}}</td>
                                        <td>{{item.createdAt | date: 'dd/MM/yyyy, hh:mm a'}}</td>
                                    </tr>
                                </table>
                                <ng-container *ngIf="paginationsDetails?.totalDocs > 10">
                                    <app-paginations [isLoading]="apiLoader" [paginationsDetails]="paginationsDetails"
                                        [currentPageLimit]="10"
                                        (getDetails)="fetchEmailSubscription($event.page,$event.limit)"></app-paginations>
                                </ng-container>
                                <div class="loader-wrap" *ngIf="apiLoader">Loading. <span class="Loaders"></span></div>
                                <p class="text-center" *ngIf="emailList?.length == 0 && !apiLoader">Visitors not found.
                                </p>
                            </div>
                        </div>
                    </div>
                </app-card>
            </div>
        </div>
    </div>
</div>