import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EmailSubscriptionService } from 'src/app/services/email-subscription.service';
import { pagination } from 'src/app/shared/interface/interface';
import { IEmailSubscriptionResponse } from './email-subscription.interface';
@Component({
  selector: 'app-email-subscription',
  templateUrl: './email-subscription.component.html',
  styleUrls: ['./email-subscription.component.scss']
})
export class EmailSubscriptionComponent {
  emailList: any = [] //To store the email list
  apiLoader = false; //To show the loader
  paginationsDetails: pagination; //To store the pagination details
  constructor(private emailService: EmailSubscriptionService, private toastr: ToastrService) {
    this.fetchEmailSubscription();
  }
  /**
   * Fetches email subscription data.
   * 
   * @param page The page number. Defaults to 1.
   * @param limit The number of items per page. Defaults to 10.
   */
  fetchEmailSubscription(page: number = 1, limit: number = 10) {
    this.emailList = [];
    const pageQuery = `?page=${page}&limit=${limit}`; //Pagination query
    this.apiLoader = true;
    this.emailService.fetchEmailSubscription(pageQuery).subscribe({
      next: (res: IEmailSubscriptionResponse) => {
        this.apiLoader = false;
        this.emailList = res.data.docs;
        this.paginationsDetails = res.data;
      },
      error: (err) => {
        this.apiLoader = false;
        this.toastr.error(err.error.message);
      }
    })
  }
}