<div class="section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <app-card cardTitle="Sales Royalty Splitter." [options]="false">
          <form [formGroup]="royaltySplitterForm" (ngSubmit)="royaltySplitterSubmit()">
            <div class="row">
              <div class="col-12 col-lg-6 mb-1">
                <div class="form-group">
                  <label>Collection creator royalty fee. (%) <label class="text-danger"> * </label> </label>
                  <input type="number" class="form-control" placeholder="Collection Address."
                    formControlName="creatorFee"
                    (input)="patchClientFee(royaltySplitterForm.value.creatorFee); validationRoyaltyCheck(royaltySplitterForm.value.creatorFee, royaltySplitterForm.value.clientFee);">
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-1">
                <div class="form-group">
                  <label>Sales royalty fee. (%)<label class="text-danger"> * </label> </label>
                  <input type="number" class="form-control" placeholder="Collection Address."
                    formControlName="clientFee"
                    (input)="patchCreatorFee(royaltySplitterForm.value.clientFee); validationRoyaltyCheck(royaltySplitterForm.value.clientFee, royaltySplitterForm.value.clientFee);">
                </div>
              </div>
              <div class="col-12 col-lg-12 mb-3" *ngIf="isDisabledForm['royaltyFee']">
                <div class="warning">
                  <div class="warning__icon">
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                      <path d="m13 14h-2v-5h2zm0 4h-2v-2h2zm-12 3h22l-11-19z" fill="#393a37"></path>
                    </svg>
                  </div>
                  <div class="warning__title">The fee should be a valid number between 0 and 100, and it cannot be
                    blank.
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <div class="btn-group">
                  <button type="submit" class="btn btn-green" *ngIf="!royaltySplitterFormLoader"
                    [ngClass]="{'disabled': isDisabledForm['royaltyFee']}"
                    [disabled]="isDisabledForm['royaltyFee']">Submit.</button>
                  <button class="btn btn-green" *ngIf="royaltySplitterFormLoader" disabled>
                    Processing. <span class="loader-white"></span>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <a *ngIf="transactionHashRoyaltySplitter != ''" href="{{transhUrl}}{{transactionHashRoyaltySplitter}}"
            target="_blank" class="d-block text-center">Click to view transaction.</a>
        </app-card>
      </div>
    </div>
  </div>
</div>