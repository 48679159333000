import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'src/environments/environment';
import { StorageService } from '../../services/storage.service';
import { AccountService } from 'src/app/services/account.service';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { polygon } from '@wagmi/core/chains';
import { watchAccount, watchNetwork, disconnect, switchNetwork } from '@wagmi/core';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { LoginService } from 'src/app/services/login.service';
import { polygonAmoy } from 'src/app/shared/helpers/polygonAmoy';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})

/**
 * Export Class
*/
export class LoginComponent implements OnInit, OnDestroy {
    public ethereum: any;
    public account: any = {};
    public unwatchAccount: any;
    public unwatchNetwork: any;
    public web3Modal: any;
    public connector: any;
    @ViewChild('switchNetworkModal') switchNetworkModal: { show: () => void; hide: () => void; };
    defaultNetwork: any;
    walletProvider: string = '';

    /**
     * Constructors
     */
    constructor(
        private accountService: AccountService,
        private storageService: StorageService,
        private router: Router,
        private toastr: ToastrService,
        private zone: NgZone,
        private contractIntegrationService: ContractIntegrationsService,
        private loginService: LoginService
    ) { }

    /**
     * Initial Loader
     */
    public ngOnInit() {
        this.wagmiConfig();
        this.ethereum = window['ethereum'];
        this.accountService.accountObserve.subscribe(res => {
            if (this.account.chainId != '')
                this.account = res
        })

        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);

        this.walletProvider = JSON.parse(this.storageService.getItem('wagmi.connectedRdns') || 'null');

        setTimeout(() => {
            this.defaultNetwork = environment.SUPPORTED_CHAINS;
        }, 1000);

    }

    /**
     * Wagmis config
     */
    public wagmiConfig() {
        // wallet connect initialization

        const metadata = {
            name: 'Real World',
            description: 'Real World - Market',
            url: environment.WEB_SITE_URL,
            icons: ['https://avatars.githubusercontent.com/u/37784886']
        }
        const chains = [polygon, polygonAmoy]
        const projectId = environment.WALLET_CONNECT_PROJECT_ID;
        const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
        this.web3Modal = createWeb3Modal({ wagmiConfig, projectId, chains, themeVariables: { '--w3m-accent': '#006736', '--w3m-z-index': 10000, '--w3m-font-family': 'Source Code Pro' } })
        // watchNetwork(async (network) => {
        //   if (network.chain != undefined) {
        //     if(!environment.SUPPORTED_CHAINS.includes(network.chain.id.toString()) ){
        //       this.router.navigate(['/']);
        //     }
        //   }
        // })
        this.unwatchNetwork = watchNetwork(async (network) => {
            if (network.chain != undefined) {
                if (!environment.SUPPORTED_CHAINS.includes(network.chain.id.toString())) {
                    this.switchNetworkModal.show();
                }
                else {
                    this.switchNetworkModal.hide();
                }
            }
        })
        this.unwatchAccount = watchAccount((connection) => {
            if (connection.isConnected) {
                setTimeout(() => {
                    this.getWalletAddress();
                }, 1000);
            }
            else {
                this.router.navigate(['/']);

            }
        })

    }

    /**
     * Connects wallet button
     */
    public connectWalletButton() {
        this.connector.openModal();
    }

    /**
  * Switchs network
  * @param {string} chainId 
  */
    switchNetwork(chainId: string) {
        switchNetwork({ chainId: Number(chainId) });
    }

    /**
     * Gets wallet address
     */
    public async getWalletAddress() {
        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);

        if (!this.account.state?.data?.chain?.unsupported) {
            const ownerAddress = await this.contractIntegrationService.owner();
            if (this.account.state.data.account != '' && ownerAddress != this.account.state.data.account) {
                this.toastr.error('Owner Address Not Found');
                // await disconnect();
                this.zone.run(() => {
                    this.router.navigate(['/login']);
                })
            } else {
                const data = {
                    wallet_address: this.account.state.data.account
                }
                this.loginService.signin(data).subscribe((response) => {
                    this.storageService.setItem('token', response['data'].token);
                    this.storageService.setItem('adminId', response['data']._id);
                    this.router.navigate(['/fees-management']);
                },
                    (error) => {
                        this.toastr.error(error.message);
                        this.zone.run(() => {
                            this.router.navigate(['/login']);
                        })
                    })
            }
        }
    }

    /**
     * Destroy
     */
    public ngOnDestroy() {
        this.unwatchAccount();
        this.unwatchNetwork();
    }

}
