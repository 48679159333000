
<div class="section">
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="">
                <app-card cardTitle="Platform fees details." [options]="false" hrefLink1="fees-management/search-collections/1"
                rightSideButton="Platform fees management.">
                <form [formGroup]="feesDetailsForm" (ngSubmit)="feesDetailsSubmit()">
                  <div class="row">
                    <div class="col-12 col-sm-8 mb-3">
                      <div class="form-group">
                        <label>Platform fee. <label class="text-danger"> * </label> (Existing platform fee
                          {{existPlatformFee}})</label>
                        <input type="number" min="0" step=".01" oninput="validity.valid||(value='');" 
                          class="form-control" placeholder="Platform Fee." formControlName="platformFee">
                        <div *ngIf="feesDetailsFormSubmitted && feesDetailsFormGet.platformFee.errors"
                          class="invalid-feedback-custom">
                          <small class="text-danger" *ngIf="feesDetailsFormGet.platformFee.errors.required">Please enter
                            platform fee.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 text-sm-left text-center">
                      <div class="btn-group">
                        <button type="submit" class="btn btn-green" *ngIf="!feesFormLoader">Submit.</button>
                        <button class="btn btn-green" *ngIf="feesFormLoader" disabled>
                          Processing. <span class="loader-white"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <a *ngIf="transactionHash != ''" href="{{transhUrl}}{{transactionHash}}" target="_blank"
                  class="d-block text-center">Click to view transaction.</a>
              </app-card>
              <app-card cardTitle="Royalty fees details." [options]="false" hrefLink1="fees-management/search-collections/2"
                rightSideButton="Royalty fees management.">
                <form [formGroup]="royaltyDetailsForm" (ngSubmit)="royaltyDetailsSubmit()">
                  <div class="row">
                    <div class="col-12 col-sm-8 mb-3">
                      <div class="form-group">
                        <label>Royalty fee.(%)<label class="text-danger">*</label> (Existing royalty fee
                          {{existRoyaltyFee}}%)</label>
                        <input type="number" min="0" max="100" step=".01" oninput="validity.valid||(value='');"
                          class="form-control" placeholder="Royalty Fee." formControlName="royaltyFee">
                        <div *ngIf="royaltyDetailsFormSubmitted && royaltyDetailsFormGet.royaltyFee.errors"
                          class="invalid-feedback-custom">
                          <small class="text-danger" *ngIf="royaltyDetailsFormGet.royaltyFee.errors.required">Please
                            enter royalty fee</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 text-sm-left text-center">
                      <div class="btn-group ">
                        <button type="submit" class="btn btn-green" *ngIf="!royaltyFormLoader">Submit.</button>
                        <button class="btn btn-green" *ngIf="royaltyFormLoader" disabled>
                          Processing. <span class="loader-white"></span>                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <a *ngIf="transactionHashRoylty != ''" href="{{transhUrl}}{{transactionHashRoylty}}" target="_blank"
                  class="d-block text-center">Click to view transaction.</a>
              </app-card>
            </div>
        </div>
    </div>
</div>
</div>