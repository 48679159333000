import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { CollectionService } from 'src/app/services/collection.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

@Component({
  selector: 'app-search-collections',
  templateUrl: './search-collections.component.html',
  styleUrls: ['./search-collections.component.scss']
})
/**
 * Collection Search
 */
export class SearchCollectionsComponent implements OnInit {
  public collectionSearchForm: FormGroup;
  public collectionLoader: boolean = false;
  public collectionFormSubmitted: boolean = false;
  public account: any = {};
  public urlId: string = '';
  regulated: boolean = false;

  /**
   * Creates an instance of search collections component.
   */
  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private storageService: StorageService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private collectionService: CollectionService
  ) { }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.urlId = this.route.snapshot.paramMap.get('id');
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.collectionSearchForm = this.formBuilder.group({
      address: ['', Validators.required]
    });
    this.accountService.accountObserve.subscribe((response) => {
      this.account = response;
    });
  }

  /**
   * Collections search
  */
  public collectionSearch() {
    this.collectionFormSubmitted = true;
    if (this.collectionSearchForm.invalid) {
      return;
    }
    this.collectionLoader = true;
    let web3 = new Web3(window['ethereum'] || environment[this.account.state.data.chain.id].PROVIDER);
    const inputAddress = this.collectionSearchForm.controls.address.value.trim();
    let collectionAddress = web3.utils.toChecksumAddress(inputAddress);

    this.collectionService.getCollectionsByAddress(collectionAddress).subscribe({
      next: async (response: any) => {
        if (Object.keys(response.data).length > 0) {
          this.collectionLoader = false;
          if (this.urlId == '1') {
            this.router.navigate(['/fees-management/edit-minting-fee', collectionAddress]);
          }
          if (this.urlId == '2') {
            this.router.navigate(['/fees-management/edit-royalty-fee', collectionAddress]);
          }
        }
        else {
          this.collectionLoader = false;
          this.toastr.error("Collection Address Not Found")
        }
      },
      error: (error: any) => {
        this.collectionLoader = false;
        if ((error.toString()).includes('is not a valid Ethereum address')) {
          this.toastr.error("Collection Address Not Found")
        } else this.toastr.error(error.error.message);
      }
    })
  }

  /**
   * Gets get nft burn form
   */
  public get collectionSearchFormGet() {
    return this.collectionSearchForm.controls;
  }
}
