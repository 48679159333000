import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KycManagementService {

  constructor(private http: HttpClient) { }

  /**
 * Updates kyc status
 * @param {any} data 
 */
updateKycStatus(data:any){
  const adminId = localStorage.getItem('adminId');
  return this.http.patch(`${environment.API_BASE_URL}/admin/update-kyc?id=${adminId}`,data);
}

/**
 * Gets kyc status
 * @returns  
 */
getKycStatus(){
  const adminId = localStorage.getItem('adminId');
  return this.http.get(`${environment.API_BASE_URL}/admin/get-admin?id=${adminId}`);
}

}
