import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NextConfig } from 'src/app/app-config';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})

/**
 * Export Class
 */
export class NavigationComponent implements OnInit {
  public windowWidth: number;
  public nextConfig: any;
  @Output() onNavMobCollapse = new EventEmitter();

  /**
   * Constructor
   */
  constructor() {
    this.nextConfig = NextConfig.config;
    this.windowWidth = window.innerWidth;
  }

  /**
   * Initial Loader
   */
  ngOnInit() { }

  /**
   * Nav Mob Collapse
   */
  navMobCollapse() {
    if (this.windowWidth < 992) {
      this.onNavMobCollapse.emit();
    }
  }
}
