<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
<div class="full-heights align-items-center d-flex">
    <div class="pcoded-content container">
        <app-card cardTitle="Collection search." [options]="false">
            <form [formGroup]="collectionSearchForm" (ngSubmit)="collectionSearch()">
                <div class="row justify-content-center">
                    <div class="col-sm-8 col-12">
                        <div class="form-group">
                            <input type="text" placeholder="Enter collection address."
                                class="form-control address-text text-center" formControlName="address">
                            <div *ngIf="collectionFormSubmitted && collectionSearchFormGet.address.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger"
                                    *ngIf="collectionSearchFormGet.address.errors.required">Please enter
                                    collection address.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-12 text-sm-left text-center">
                        <div class="btn-group">
                            <button type="submit" [disabled]="collectionLoader || collectionSearchForm.invalid"
                                class="btn btn-green">
                                <span *ngIf="collectionLoader"> Processing. <span class="loader-white"></span></span>
                                <span *ngIf="!collectionLoader"> Search. </span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </app-card>
    </div>
</div>
</div>
</div>
</div>
</div>
