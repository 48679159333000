<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="full-heights align-items-center d-flex">
                    <div class="pcoded-content container">
                        <app-card cardTitle="KYC management." [options]="false">
                            <div class="justify-content-center d-flex">
                                <!-- <ngx-skeleton-loader [theme]="{background: '#f9f9f9',width:'100%'}" *ngIf="!kycUploadStatus" style="width: 100%;"></ngx-skeleton-loader>  -->
                                <div class="kyc-section">
                                    <label class="kyc-label" for="">{{kycStatus ? 'Disable' : 'Enable'}}  KYC upload status.</label>
                                    <label class="switch"> <input type="checkbox" (click)="kycUploadStatus($event.target['checked'])" [checked]="kycStatus"><span class="slider round"></span></label>
                                </div>
                            </div>
                        </app-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>