import { Pipe, PipeTransform } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
   @Pipe({
     name: 'search'
   })
   export class SearchPipe implements PipeTransform {

    constructor(public accountService:AccountService){

    }

    transform(value: any, args?: any): any {
      
      if (!args) {
          return value;
        }
        return value.filter((val) => {
          let rVal = (val.address.toLocaleLowerCase().includes(args));
          return rVal;
        })
    
      }
    }