import { Injectable } from '@angular/core';
import { createPublicClient, http } from 'viem'
import { polygon } from 'viem/chains'
import { polygonAmoy } from '../shared/helpers/polygonAmoy';


@Injectable({
    providedIn: 'root'
  })
  
export class publicClientConfig {

    /**
     * Publics client
     * @param {string} chainId 
     * @returns  
     */
    public publicClient(chainId:string) {
        let networkType:any = polygon;
        if(chainId == '0x13882'){
            networkType = polygonAmoy;
        }
        return createPublicClient({
            chain: networkType,
            transport: http()
        })
    }

}