import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginations',
  templateUrl: './paginations.component.html',
  styleUrls: ['./paginations.component.scss']
})
export class PaginationsComponent {
  @Input() isLoading:boolean;
  @Input() paginationsDetails;
  @Input() currentPageLimit:number;
  @Output() getDetails = new EventEmitter()

  /**
   * Gets data
   * @param {number} page 
   * @param {number} limit 
   */
  getData(page: number, limit: number) {
    const data = {
      page: page,
      limit: limit
    }
    this.getDetails.emit(data);
  }
}
