import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

/**
 * Export Class
 */
export class StorageService {

  /**
 * Store data in localstorage
 * @param {string} key
 * @param {any} value
 */
  public setItem(key, value) {
    localStorage.setItem(key, value);
  }

  /**
   * Get data from localstorage
   * @param {string} key
   * @return {string} value of the key from local storage
   */
  public getItem(key) {
    return localStorage.getItem(key);
  }

  /**
   * Remove data from localstorage
   * @param {string} key
   * @return {string} value
   */
  public removeItem(key) {
    return localStorage.removeItem(key);
  }

  /**
   * clear data from localstorage
   */
  public clearItem() {
    localStorage.clear();
  }
}
