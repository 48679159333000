import { Component, ErrorHandler, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContractIntegrationsService } from 'src/app/services/contract-integrations.service';
import { Const } from 'src/app/core/const/const';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { StorageService } from 'src/app/services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';

const web3 = new Web3(window['ethereum']);
let connector;

@Component({
  selector: 'app-edit-royalty-fee',
  templateUrl: './edit-royalty-fee.component.html',
  styleUrls: ['./edit-royalty-fee.component.scss']
})
export class EditRoyaltyFeeComponent implements OnInit {
  public account: any = {};
  public feesDetailsForm: FormGroup;
  public feesDetailsFormSubmitted: boolean = false;
  public feesFormLoader: boolean = false;
  public transactionHash: string = '';
  public transhUrl : string = '';
  public existRoyaltyFee: number;
  public collectionAddress: string = '';
  public collectionName: string = '';
  regulated: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private constVal: Const,
    private contractIntegrationService: ContractIntegrationsService,
    private toastr: ToastrService,
    private accountService: AccountService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private manageTransactionsDirective:ManageTransactionsDirective,
    private errorHandler: ErrorHandler
  ) { }

  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.collectionAddress = this.route.snapshot.paramMap.get('address');
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.accountService.accountObserve.subscribe(async (response) => {
      if (response.chainId != '')
        this.account = response;
    });
    this.transhUrl = environment[this.account?.state?.data?.chain?.id].TRANSH_URL;
    this.feesDetailsForm = this.formBuilder.group({
      royaltyFee: ['', [Validators.required]],
    });
    this.getRoyaltyFee();
  }

  /**
   * Gets royaltyform fee
   */
  public getRoyaltyFee() {
    this.contractIntegrationService.getCollectionName(this.collectionAddress).then((collectionNameResponse:any) => {
      this.collectionName = collectionNameResponse;
    });

    this.contractIntegrationService.collectionRoyaltyFee(this.collectionAddress).then((royaltyFeeResponse) => {
      this.existRoyaltyFee = (Number(royaltyFeeResponse) / 100);
      this.feesDetailsForm.patchValue({
        royaltyFee: this.existRoyaltyFee
      })
    });


  }
  /**
   * Validate First letter start with Space
   * @param {string} event
  */
  public space(event) {
    if (event.target.selectionStart === 0 && event.code === this.constVal.CONST_VAR.space) {
      event.preventDefault();
    }
  }

  /**
   * Fees Details submit
   */
  public async feesDetailsSubmit() {
    try{
    this.feesDetailsFormSubmitted = true;
    if (this.feesDetailsForm.invalid) {
      return;
    }
    if (this.feesDetailsForm.controls.royaltyFee.value <= 0) {
      this.toastr.error('Fee should be greater than 0');
      return;
    }
    this.feesFormLoader = true;
    const royaltyformFeeAmount = ((this.feesDetailsForm.controls.royaltyFee.value.toString()) * (100)).toString();
    const { royaltyformFeeAbi, requiredGas } = await this.contractIntegrationService.setCollectionRoyaltyFee(royaltyformFeeAmount, this.collectionAddress, this.account.state.data.account);
    const message = {
      to: this.collectionAddress,
      data: royaltyformFeeAbi,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async (receipt) => {
        this.toastr.success('Royaltyform Fees Added Successfully');
        this.transactionHash = receipt['data']['transactionHash'];
        this.feesFormLoader = false;
        this.getRoyaltyFee();
      })
      .catch((error) => {
        this.feesFormLoader = false;
      });
    }
    catch(error){
      this.feesFormLoader = false;
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Gets fees details form get
   */
  get feesDetailsFormGet() {
    return this.feesDetailsForm.controls;
  }
}
