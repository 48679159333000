<div class="section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <app-card cardTitle="Royalty splitter." [options]="false">
                    <form [formGroup]="royaltySplitterForm" (ngSubmit)="royaltySplitterSubmit()">
                      <div class="row">
                        <div class="col-12 col-sm-6 mb-3">
                          <div class="form-group">
                            <label>Creator royalty fee. (%) <label class="text-danger"> * </label> </label>
                            <input type="number" class="form-control" placeholder="Collection Address."
                              formControlName="creatorFee">
                            <div
                              *ngIf="royaltySplitterFormSubmitted && royaltySplitterFormGet.creatorFee.errors && royaltySplitterFormGet.creatorFee.touched"
                              class="invalid-feedback-custom">
                              <small class="text-danger" *ngIf="royaltySplitterFormGet.creatorFee.errors.required">
                                Please  enter creator royalty fee.</small>
                              <small class="text-danger" *ngIf="royaltySplitterFormGet.creatorFee.errors.pattern">Accepts
                                only whole number.</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 mb-3">
                          <div class="form-group">
                            <label>Client royalty fee. (%)<label class="text-danger"> * </label> </label>
                            <input type="text" class="form-control" placeholder="Collection Address."
                              formControlName="clientFee">
                            <div
                              *ngIf="royaltySplitterFormSubmitted && royaltySplitterFormGet.clientFee.errors && royaltySplitterFormGet.clientFee.touched"
                              class="invalid-feedback-custom">
                              <small class="text-danger" *ngIf="royaltySplitterFormGet.clientFee.errors.required">Please
                                Enter client royalty fee.</small>
                              <small class="text-danger" *ngIf="royaltySplitterFormGet.clientFee.errors.pattern">Accepts
                                only whole number.</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center">
                          <div class="btn-group">
                            <button type="submit" class="btn btn-green" *ngIf="!royaltySplitterFormLoader">Submit.</button>
                            <button class="btn btn-green" *ngIf="royaltySplitterFormLoader" disabled>
                              Processing. <span class="loader-white"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <a *ngIf="transactionHashRoyaltySplitter != ''" href="{{transhUrl}}{{transactionHashRoyaltySplitter}}"
                      target="_blank" class="d-block text-center">Click to view transaction.</a>
                  </app-card>
            </div>
        </div>
    </div>
</div>