<div class="section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <app-card cardTitle="Minting fees details." [options]="false" hrefLink1="fees-management/search-collections/1"
            rightSideButton="Minting fees management.">
            <form [formGroup]="feesDetailsForm" (ngSubmit)="feesDetailsSubmit()">
              <div class="row">
                <div class="col-12 col-sm-8 mb-3">
                  <div class="form-group">
                    <label>Minting fee. <label class="text-danger"> * </label> (Existing minting fee
                      {{existingPlatformFee}})</label>
                    <input type="number" min="0" step=".01" oninput="validity.valid||(value='');" class="form-control"
                      placeholder="Minting Fee." formControlName="platformFee"
                      (input)="validateFees(feesDetailsForm.value.platformFee, 'platformFee')">
                    <div *ngIf="isDisabledForm['platformFee']">
                      <div class="warning mt-3">
                        <div class="warning__icon">
                          <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="m13 14h-2v-5h2zm0 4h-2v-2h2zm-12 3h22l-11-19z" fill="#393a37"></path>
                          </svg>
                        </div>
                        <div class="warning__title">The fee can't be 0, and it cannot be blank.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4 text-sm-left text-center">
                  <div class="btn-group">
                    <button type="submit" class="btn btn-green" *ngIf="!feesFormLoader"
                      [ngClass]="{'disabled': isDisabledForm['platformFee']}"
                      [disabled]="isDisabledForm['platformFee']">Submit.</button>
                    <button class="btn btn-green" *ngIf="feesFormLoader" disabled>
                      Processing. <span class="loader-white"></span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <a *ngIf="transactionHash != ''" href="{{transhUrl}}{{transactionHash}}" target="_blank"
              class="d-block text-center">Click to view transaction.</a>
          </app-card>
          <app-card cardTitle="Royalty fees details." [options]="false" hrefLink1="fees-management/search-collections/2"
            rightSideButton="Royalty fees management.">
            <form [formGroup]="royaltyDetailsForm" (ngSubmit)="royaltyDetailsSubmit()">
              <div class="row">
                <div class="col-12 col-sm-8 mb-3">
                  <div class="form-group">
                    <label>Royalty fee.(%)<label class="text-danger">*</label> (Existing royalty fee
                      {{existingRoyaltyFee}}%)</label>
                    <input type="number" min="0" max="100" step=".01" oninput="validity.valid||(value='');"
                      class="form-control" placeholder="Royalty Fee." formControlName="royaltyFee"
                      (input)="validateFees(royaltyDetailsForm.value.royaltyFee, 'royaltyFee')">
                    <div *ngIf="isDisabledForm['royaltyFee']">
                      <div class="warning mt-3">
                        <div class="warning__icon">
                          <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="m13 14h-2v-5h2zm0 4h-2v-2h2zm-12 3h22l-11-19z" fill="#393a37"></path>
                          </svg>
                        </div>
                        <div class="warning__title">The fee must be a valid number greater than 0 and up to 100, and it
                          cannot be left empty.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4 text-sm-left text-center">
                  <div class="btn-group ">
                    <button type="submit" class="btn btn-green" *ngIf="!royaltyFormLoader"
                      [ngClass]="{'disabled': isDisabledForm['royaltyFee']}"
                      [disabled]="isDisabledForm['royaltyFee']">Submit.</button>
                    <button class="btn btn-green" *ngIf="royaltyFormLoader" disabled>
                      Processing. <span class="loader-white"></span> </button>
                  </div>
                </div>
              </div>
            </form>
            <a *ngIf="transactionHashRoylty != ''" href="{{transhUrl}}{{transactionHashRoylty}}" target="_blank"
              class="d-block text-center">Click to view transaction.</a>
          </app-card>
        </div>
      </div>
    </div>
  </div>
</div>