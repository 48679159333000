import { Injectable } from '@angular/core';
import Web3 from 'web3';
import { environment } from 'src/environments/environment';
import { readContract } from '@wagmi/core';
import { getAddress, encodeFunctionData } from 'viem';
import { publicClientConfig } from './publicClientConfig.service';
import { StorageService } from './storage.service';

const abi = require('src/app/core/common/abis/token.json');
const nftAbi = require('src/app/core/common/abis/nftAbi.json');
const web3 = new Web3(window['ethereum']);


@Injectable({
    providedIn: 'root',
})

/**
 * Export Class
 */
export class ContractIntegrationsService {
    contract: any;
    private publicClient: any;
    account: any

    constructor(
        public publicClientConfig: publicClientConfig,
        private storageService: StorageService
    ) {
        this.onInit()

    }


    /**
     * init on
     */
    public onInit() {
        setTimeout(() => {
            this.accountConfig();
        }, 1000);
    }

    accountConfig() {
        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);
        this.publicClient = this.publicClientConfig.publicClient(this?.account?.state?.data?.chain?.id);
    }
    /**
     * Owners contract integrations service
     */
    public async owner() {
        await this.accountConfig();
        const chainId = this?.account?.state?.data?.chain?.id;
        return await readContract({
            address: environment[chainId].FACTORY_ADDRESS,
            abi: abi,
            chainId,
            functionName: 'owner',
            args: []
        });

    }

    /**
     * Gets user created collections
     * @param {string} accountAddress
     * @returns
     */
    public async getCollections(regulated: boolean) {
        await this.accountConfig();
        const chainId = this?.account?.state?.data?.chain?.id;
        return await readContract({
            address: environment[chainId].FACTORY_ADDRESS,
            abi: abi,
            chainId,
            functionName: regulated ? 'getAllCollectionsRegulated' : 'getAllCollectionsUnregulated',
            args: []
        });
    }

    /**
     * Gets fees
     * @param {boolean} regulated 
     * @returns  
     */
    async getFees(regulated: boolean) {
        return await readContract({
            address: environment[this?.account?.state?.data?.chain?.id].FACTORY_ADDRESS,
            abi: abi,
            functionName: regulated ? 'regulatedFees' : 'unRegulatedFees',
            args: []
        });

    }

    /**
     * Sets fees
     * @param {any} fees 
     * @param {string} walletAddress 
     * @param {boolean} regulated 
     * @returns  
     */
    async setFees(fees: any, walletAddress: string, regulated: boolean) {
        let { platformFee, royaltyFee, creatorRoyaltyFee, clientRoyaltyFee } = fees;
        let requiredGas;
        let feesAbi;
        if (regulated) {
            const contractParams = {
                address: environment[this?.account?.state?.data?.chain?.id].FACTORY_ADDRESS,
                abi: abi,
                functionName: 'setRegulatedFees',
                args: [platformFee, royaltyFee, creatorRoyaltyFee, clientRoyaltyFee],
            }

            requiredGas = await this.publicClient.estimateContractGas({
                address: environment[this?.account?.state?.data?.chain?.id].FACTORY_ADDRESS,
                account: walletAddress,
                ...contractParams
            });

            feesAbi = await encodeFunctionData({
                ...contractParams
            })
        }
        else {
            const contractParams = {
                address: environment[this?.account?.state?.data?.chain?.id].FACTORY_ADDRESS,
                abi: abi,
                functionName: 'setUnregulatedFees',
                args: [platformFee, royaltyFee, creatorRoyaltyFee, clientRoyaltyFee],
            }

            requiredGas = await this.publicClient.estimateContractGas({
                address: environment[this?.account?.state?.data?.chain?.id].FACTORY_ADDRESS,
                account: walletAddress,
                ...contractParams
            });

            feesAbi = await encodeFunctionData({
                ...contractParams
            })
        }
        requiredGas = requiredGas.toString();
        return { feesAbi, requiredGas };
    }

    async setPartnerFees(fees: any, walletAddress: string) {
        let requiredGas;
        let feesAbi;
        let { partner, platform_fee, royalty_fee, creator_royalty_fee, client_royalty_fee, use_partner_fee } = fees;

        const contractParams = {
            address: environment[this?.account?.state?.data?.chain?.id].FACTORY_ADDRESS,
            abi: abi,
            functionName: 'setPartnerFees',
            args: [partner, platform_fee, royalty_fee, creator_royalty_fee, client_royalty_fee, use_partner_fee],
        }

        requiredGas = await this.publicClient.estimateContractGas({
            address: environment[this?.account?.state?.data?.chain?.id].FACTORY_ADDRESS,
            account: walletAddress,
            ...contractParams
        });

        feesAbi = await encodeFunctionData({
            ...contractParams
        })

        requiredGas = requiredGas.toString();
        return { feesAbi, requiredGas };
    }

    /**
     * Collections platform fee
     * @param {string} collectionAddress
     */
    public async collectionPlatformFee(collectionAddress) {
        const chainId = this?.account?.state?.data?.chain?.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'platformFee',
            args: []
        });
    }

    /**
     * Collections royalty fee
     * @param {string} collectionAddress
     */
    public async collectionRoyaltyFee(collectionAddress) {
        const chainId = this?.account?.state?.data?.chain?.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'royaltyFee',
            args: []
        });
    }


    /**
     * Sets collection platform fee
     * @param {any} amount
     * @param {string} collectionAddress
     */
    public async setCollectionPlatformFee(amount: any, collectionAddress: string, walletAddress: string) {
        let requiredGas;
        let platformFeeAbi;

        const contractParams = {
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            functionName: 'setPlatformFee',
            args: [amount],
        }

        requiredGas = await this.publicClient.estimateContractGas({
            address: await getAddress(collectionAddress),
            account: walletAddress,
            ...contractParams
        });

        platformFeeAbi = await encodeFunctionData({
            ...contractParams
        })

        requiredGas = requiredGas.toString();
        return { platformFeeAbi, requiredGas };
    }

    /**
     * Sets collection platform fee
     * @param {any} amount
     * @param {string} collectionAddress
     */
    public async setCollectionRoyaltyFee(amount: any, collectionAddress: string, walletAddress: string) {
        let requiredGas;
        let royaltyformFeeAbi;

        const contractParams = {
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            functionName: 'setRoyaltyFee',
            args: [amount],
        }

        requiredGas = await this.publicClient.estimateContractGas({
            address: await getAddress(collectionAddress),
            account: walletAddress,
            ...contractParams
        });

        royaltyformFeeAbi = await encodeFunctionData({
            ...contractParams
        })
        requiredGas = requiredGas.toString();
        return { royaltyformFeeAbi, requiredGas }
    }

    /**
     * Destroys collection
     * @param {string} collectionAddress
     * @returns
     */
    public async destroyCollection(collectionAddress, walletAddress) {
        let requiredGas;
        let destroyedAbi;

        const contractParams = {
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            functionName: 'destroyCollection',
            args: [],
        }

        requiredGas = await this.publicClient.estimateContractGas({
            address: await getAddress(collectionAddress),
            account: walletAddress,
            ...contractParams
        });

        destroyedAbi = await encodeFunctionData({
            ...contractParams
        })
        requiredGas = requiredGas.toString();
        return { destroyedAbi, requiredGas };
    }

    /**
     * Determines whether collection destroyed is
     * @param {string} collectionAddress
     * @returns
     */
    public async isCollectionDestroyed(collectionAddress) {
        const chainId = this?.account?.state?.data?.chain?.id;
        return await readContract({
            address: environment[this?.account?.state?.data?.chain?.id].FACTORY_ADDRESS,
            abi: abi,
            chainId,
            functionName: 'isCollectionDestroyed',
            args: [collectionAddress]
        });
    }

    /**
     * Gets user created collections
     * @param {string} accountAddress
     * @returns
     */
    public async getCollectionName(collectionAddress) {
        const chainId = this?.account?.state?.data?.chain?.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'name',
            args: []
        });
    }

    public async getCollectionOwner(collectionAddress) {
        const chainId = this?.account?.state?.data?.chain?.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'owner',
            args: []
        });
    }
}
