import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { ContractIntegrationsService } from './contract-integrations.service';
import { defaultWagmiConfig } from '@web3modal/wagmi';
import { polygon } from 'viem/chains';
import { polygonAmoy } from '../shared/helpers/polygonAmoy';

const apiUrl = environment.API_BASE_URL;

@Injectable({
    providedIn: 'root',
})

/**
 * Export Class
 */
export class LoginService {
    /**
     * Constructor
     */
    constructor(
        private router: Router,
        private http: HttpClient,
        private storageService: StorageService,
        private contractService: ContractIntegrationsService
    ) {
        const metadata = {
            name: 'Real World',
            description: 'Real World - Market',
            url: environment.WEB_SITE_URL,
            icons: ['https://avatars.githubusercontent.com/u/37784886']
        }
        const chains = [polygon, polygonAmoy]
        const projectId = environment.WALLET_CONNECT_PROJECT_ID;
        defaultWagmiConfig({ chains, projectId, metadata })
    }


    /**
     *
     * @return {boolean}
     */
    async canActivate() {
        let account: any = JSON.parse(this.storageService.getItem('wagmi.store'))

        if (Object.keys(account.state.data).length > 0 && account.state.data.account != '') {
            const ownerAddress = await this.contractService.owner();
            if (ownerAddress === account.state.data.account)
                // logged in so return true
                return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['login']);
        return false;
    }


    /**
     * Signin
     * @param {data} data 
     * @returns  
     */
    public signin(data: object) {
        return this.http.post(`${apiUrl}/admin/signup-signin-admin`, data);
    }

}
