import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BorrowLendComponent } from './pages/borrow-lend/borrow-lend.component';
import { CollectionsManagementComponent } from './pages/collections-management/collections-management.component';
import { CurrencyManagementComponent } from './pages/currency-management/currency-management.component';
import { EditPlatformFeeComponent } from './pages/edit-platform-fee/edit-platform-fee.component';
import { EditRoyaltyFeeComponent } from './pages/edit-royalty-fee/edit-royalty-fee.component';
import { CategoryComponent } from './pages/faq/category/category.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FeesManagementComponent } from './pages/fees-management/fees-management.component';
import { KycManagementComponent } from './pages/kyc-management/kyc-management.component';
import { LoginComponent } from './pages/login/login.component';
import { ManageDocumentComponent } from './pages/manage-document/manage-document.component';
import { NftTraitComponent } from './pages/nft-trait/nft-trait.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PartnerFeesManagementComponent } from './pages/partner-fees-management/partner-fees-management.component';
import { PartnerManagementComponent } from './pages/partner-management/partner-management.component';
import { RoyaltySplitterComponent } from './pages/royalty-splitter/royalty-splitter.component';
import { SearchCollectionsComponent } from './pages/search-collections/search-collections.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { WhitelistManagementComponent } from './pages/whitelist-management/whitelist-management.component';
import { LoginService } from './services/login.service';
import { AdminComponent } from './shared/components/nav/admin.component';
import { EmailSubscriptionComponent } from './pages/email-subscription/email-subscription.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full'
    },
    {
        path: '',
        component: AdminComponent,
        canActivate: [LoginService],
        children: [
            {
                path: '',
                redirectTo: 'fees-management',
                pathMatch: 'full'
            },
            {
                path: 'fees-management',
                component: FeesManagementComponent,
            },
            {
                path: 'partner-management',
                component: PartnerManagementComponent
            },
            {
                path: 'partner-fees-management',
                component: PartnerFeesManagementComponent,
            },
            {
                path: 'whitelist-management',
                component: WhitelistManagementComponent,
            },
            {
                path: 'sales-royalty-splitter',
                component: RoyaltySplitterComponent,
            },
            {
                path: 'nft-trait',
                component: NftTraitComponent,
            },
            {
                path: 'collections-management',
                component: CollectionsManagementComponent,
            },
            {
                path: 'manage-document',
                component: ManageDocumentComponent,
            },
            {
                path: 'faq',
                component: FaqComponent,
            },
            {
                path: 'faq/category',
                component: CategoryComponent,
            },
            {
                path: 'borrow-and-lend',
                component: BorrowLendComponent,
            },
            {
                path: 'currency-management',
                component: CurrencyManagementComponent,
            },
            {
                path: 'fees-management/search-collections/:id',
                component: SearchCollectionsComponent,
            },
            {
                path: 'fees-management/edit-minting-fee/:address',
                component: EditPlatformFeeComponent,
            },
            {
                path: 'fees-management/edit-royalty-fee/:address',
                component: EditRoyaltyFeeComponent,
            },
            {
                path: 'kyc-management',
                component: KycManagementComponent,
            },
            {
                path: 'settings',
                component: SettingsComponent,
            },
            {
                path: 'website-visitors',
                component: EmailSubscriptionComponent,
            },
            { path: '**', component: NotFoundComponent }
        ],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
/**
 * Export Class
 */
export class AppRoutingModule { }
