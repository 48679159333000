<div class="main-wrap">
    <section class="section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-8 col-lg-7 mx-auto">
                    <div class="pagenotfound">
                        <img src="assets/images/page-not-found.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>