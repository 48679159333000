import { Component,ErrorHandler, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IApiResponse } from 'src/app/core/interfaces/app.interface';
import { NftTraitsService } from 'src/app/services/nft-traits.service';
import { StorageService } from 'src/app/services/storage.service';
import Web3 from 'web3';
import { GroupedNftTrait } from './lazymint-trait.interface';
import { CommonHelper } from 'src/app/shared/helpers/common-helper';
import { TagModel } from 'ngx-chips/core/tag-model';
import { Subscription } from 'rxjs';

const web3 = new Web3(window['ethereum']);

@Component({
  selector: 'app-lazymint-trait',
  templateUrl: './lazymint-trait.component.html',
  styleUrls: ['./lazymint-trait.component.scss']
})
export class LazymintTraitComponent {
  regulated: boolean = false
  public account: any = {};
  @ViewChild("longLazymintContent") editTriatLazymintModal: TemplateRef<any>;
  @ViewChild("contentLazymint") deleteTriatLazymintModal: TemplateRef<any>;
  locationInput:string = ''
  mandatoryCheckbox = false;
  public currencyUpdateStatus: boolean = false;
  public nftTrait: any = [];
  public nftTraitLazymintForm: FormGroup;
  public nftTraitLazymintFormSubmitted: boolean = false;
  public traitProcessing: boolean = false;
  public deleteTraitData: any = null;
  loader:boolean = true;
  nftTraitLazymint: any;
  nftCategory: any;
  normalMandatoryCheckbox = false;
  updateLazymintStatus = false;
  editData: any;
  groupedData: GroupedNftTrait[]=[];
  expandedCategory: string | null = null;

  isLocationAttribute:boolean = false //This flag is used to determine if the attribute input is recognized as 'location'.
  @Input() allNFTLocations:string[] = []
  filteredLocations:string[] = []
  nftTraitsRefreshSubscription:Subscription

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private nftTraitsService: NftTraitsService,
    private errorHandler: ErrorHandler,
    private commonHelper:CommonHelper
  ) {

  }
  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);
    

    this.nftTraitLazymintForm = this.formBuilder.group({
      category: ['', [Validators.required]],
      attribute: ['', [Validators.required]],
      value: ['',],
      mandatory:[''],
      _id: ['']
    });
    this.getAllTraits();
    this.initAttributeValueListener();
    this.subscribeToNftTraitsRefresh();
  }


  /**
   * Gets all traits
   */
  public getAllTraits() {
    this.loader = true;
    this.nftTraitsService.getAllTraits().subscribe(async (response: IApiResponse) => {
      this.nftCategory = response.data.filter((triat) => {  if(triat.attribute == 'Category'){ return triat.value; }})[0].value;
      this.nftTraitLazymint = response.data.filter((triat) => {  if(triat.type == 1){ return triat; }});
      this.allNFTLocations = response.data.find((trait:{value:string[],attribute:string}) => trait.attribute.toLowerCase() === 'location' ).value
      this.filteredLocations = this.allNFTLocations
      this.groupDataByCategory();
      this.nftTraitLazymint.map((triat, index) => this.getAttribute(triat, index));
      this.loader = false;
    },(_error) => {
      this.loader = false;
    });
  }


  /**
   * group data by category
   */
  groupDataByCategory() {
    const grouped = this.nftTraitLazymint.reduce((acc, item) => {
        (acc[item.category] = acc[item.category] || []).push(item);
        return acc;
    }, {});

    this.groupedData = Object.keys(grouped).map(category => ({
        category,
        data: grouped[category]
    }));
    console.log(this.groupedData);
    
  }
  
  /**
   * toggle row
   * @param{string} category 
   */
  toggleCategory(category: string) {
    if (this.expandedCategory === category) {
      this.expandedCategory = null;
    } else {
      this.expandedCategory = category;
    }
}

  /**
   * returns whether the row is expanded or not
   * @param{string} category 
   * @return{boolean}
   */
  isCategoryExpanded(category: string): boolean {
    return this.expandedCategory === category;
  }


  /**
   * Gets attribute
   * @param trait 
   */
  private getAttribute(trait: any, index: number) {
    trait.id = index;
    // (trait.attribute.toLowerCase() === 'appraisal value' || trait.attribute.toLowerCase() === 'markup fee' || trait.attribute.toLowerCase() === 'size' || trait.attribute.toLowerCase() === 'purity' || trait.attribute.toLowerCase() === 'type') ? trait.can_modify = true : trait.can_modify = false;
  }

  /**
  * Opens lazymint
  * @param content
  * @param [index]
  */
  openLazymint(content, id?: any) {
    
    if (id != null || id != undefined) {
      this.updateLazymintStatus = true;
      const editData = this.nftTraitLazymint.find((item) => item._id === id);
this.editData = editData;
this.mandatoryCheckbox = editData.mandatory;
      let valueArray = [];
      editData.value.forEach(element => {
        element = { display: element, value: element, readonly: this.mandatoryCheckbox ? true : false  }
        valueArray.push(element);
      });
      
      this.nftTraitLazymintForm.patchValue({ 'category': editData.category });
      this.nftTraitLazymintForm.patchValue({ 'attribute': editData.attribute });
      this.nftTraitLazymintForm.patchValue({ 'value': valueArray });
      this.nftTraitLazymintForm.patchValue({ '_id': editData._id });
      this.updateFilteredLocations();
    } else {
      this.updateLazymintStatus = false;
      this.nftTraitLazymintForm.reset();
    }
    this.modalService.open(content, { size: 'xl', centered: true, backdrop : 'static', keyboard : false });
  }

  /**
   * Deletes trait
   * @param {number} id
   */
  public deleteTrait(id: string) {
    this.deleteTraitData = id;
    this.modalService.open(this.deleteTriatLazymintModal, { centered: true, backdrop : 'static', keyboard : false });
  }
  
  /**
  * Nfts trait submit
  * @returns
  */
  public async nftTraitLazymintSubmit() {
    try {
      this.nftTraitLazymintFormSubmitted = true;
      this.traitProcessing = true;
      if (this.nftTraitLazymintForm.invalid) {
        this.traitProcessing = false;
        return;
      }
      let attributeValues = [];
      if (this.nftTraitLazymintForm.controls.value.value != undefined && this.nftTraitLazymintForm.controls.value.value != '' && this.nftTraitLazymintForm.controls.value.value != null) {

        await this.nftTraitLazymintForm.controls.value.value.forEach(element => {
          if (element.value != undefined || element.value != '' || element.value != null) {
            // element = { display: element, value: element }

            attributeValues.push(element.value);
          }
        });
      }
      const params = {
        attribute: this.nftTraitLazymintForm.value.attribute,
        value: attributeValues,
      }
      if(this.updateLazymintStatus && this.mandatoryCheckbox){
        params['mandatory'] = this.mandatoryCheckbox;
      }
        if(!this.updateLazymintStatus){
        params['type'] = 1;
        params['category'] = this.nftTraitLazymintForm.value.category;
        params['mandatory'] = this.mandatoryCheckbox;
      }
      // Create Trait
      if (!this.updateLazymintStatus) {
        this.nftTraitsService.addTrait(params).subscribe({
          next: async (data: any) => {
            this.traitProcessing = false;
            this.nftTraitLazymintFormSubmitted = false;
            this.toastr.success(data.message);
            this.modalService.dismissAll();
            this.getAllTraits();
          },
          error: (error: any) => {
            this.traitProcessing = false;
            this.nftTraitLazymintFormSubmitted = false;
          },
        });
      } else {
        // Update Trait
        this.nftTraitsService.updateTrait(this.nftTraitLazymintForm.value._id, params).subscribe({
          next: async (data: any) => {
            this.nftTraitLazymintFormSubmitted = false;
            this.traitProcessing = false;
            this.toastr.success(data.message);
            this.modalService.dismissAll();
            this.getAllTraits();
          },
          error: (error: any) => {
            this.nftTraitLazymintFormSubmitted = false;
            this.traitProcessing = false;
            this.toastr.error(error.error.message);
          },
        });
      }
    } catch (err) {
      this.errorHandler.handleError(err);
    }
  }

  /**
   * Gets nft trait lazymint controls
   */
  get nftTraitLazymintControls() {
    return this.nftTraitLazymintForm.controls;
  }

  /**
   * Mandatorys change
   */
  mandatoryChange(){
    this.mandatoryCheckbox = !this.mandatoryCheckbox;
    
  }

  /**
   * Edits lazymint trait
   * @param {number} id 
   */
  editLazymintTrait(id: string) {
    
    this.openLazymint(this.editTriatLazymintModal, id);
  }

  /**
  * Opens xl
  * @param content
  * @param [index]
  */
  openXl(content, id?: any) {
    this.mandatoryCheckbox = false;
    if (id != null || id != undefined) {
      this.updateLazymintStatus = true;
      const editData = this.nftTrait.find((item) => item._id === id);
  let valueArray = [];
      editData.value.forEach(element => {
        element = { display: element, value: element }
        valueArray.push(element);
      });
      this.nftTraitLazymintForm.patchValue({ 'attribute': editData.attribute });
      this.nftTraitLazymintForm.patchValue({ 'value': valueArray });
      this.nftTraitLazymintForm.patchValue({ '_id': editData._id });
      this.normalMandatoryCheckbox = editData.mandatory;
    } else {
      this.updateLazymintStatus = false;
      this.nftTraitLazymintForm.reset();
    }
    this.updateFilteredLocations();
    this.modalService.open(content, { size: 'xl', centered: true, backdrop : 'static', keyboard : false });
  }

  /**
   * Deletes attribute
   */
  public deleteAttribute() {
    this.traitProcessing = true;
    this.nftTraitsService.deleteTrait(this.deleteTraitData).subscribe({
      next: async (data: any) => {
        this.traitProcessing = false;
        this.toastr.success(data.message);
        this.modalService.dismissAll();
        this.deleteTraitData = null;
        this.getAllTraits();
      },
      error: (error: any) => {
        this.traitProcessing = false;
        this.toastr.error(error.error.message);
      },
    });
  }

  /**
   * Initializes a listener for changes in the 'attribute' form control.
   *
   * @returns {void}
   */
  initAttributeValueListener(): void {
    this.nftTraitLazymintForm.controls["attribute"].valueChanges.subscribe((attributeInput: string) => {
      // NOTE: 
      // When the attribute value changes, it checks the similarity of the input to the string 'location'
      // and sets a flag isLocationAttribute to true if the similarity is 80% or higher.
      if (attributeInput) {
        const similiarityPercentage = this.commonHelper.stringSimilarity(attributeInput.toLowerCase(), "location");
        this.isLocationAttribute = similiarityPercentage >= 0.8;
      } else {
        this.isLocationAttribute = false;
      }
    });
  }

  /**
   * Handles the selection of a location from the dropdown.
   * Resets the dropdown value, adds the selected location to the array,
   * updates the form control, and refreshes the filtered locations list.
   */
  locationSelected(){
    (document.getElementById('locationDropDown') as HTMLSelectElement).value = '';
    let selectedLocationsArr = this.getSelectedLocations();
    selectedLocationsArr.push({
      display: this.locationInput, value: this.locationInput 
    })

    this.nftTraitLazymintForm.patchValue({
      value:selectedLocationsArr
    });
    this.updateFilteredLocations();
  }

  /**
   * Retrieves the currently selected locations from the form control.
   * 
   * @returns {Array<{ display: string, value: string }>} 
   * An array of selected locations with display and value properties.
   */
  getSelectedLocations():[{ display:string,value: string; }]{
    return this.nftTraitLazymintControls['value'].value ?? []
  }

  /**
   * Handles the removal of a location from the selected list.
   * Updates the filtered locations and clears the location input.
   * 
   * @param {any} event - The event triggered when a location is removed.
   */
  locationRemoved(event:any){
    this.updateFilteredLocations();
    this.locationInput = '';
  }

  /**
   * Updates the list of filtered locations by excluding the selected locations.
   */
  updateFilteredLocations():void{
    const selectedValues = this.getSelectedLocations().map(location => location.value);
    this.filteredLocations = this.allNFTLocations.filter(location => !selectedValues.includes(location))
  }

  /**
   * Subscribes to the NFT traits refresh observable
   */
  subscribeToNftTraitsRefresh(): void {
    // Subscribe to the NFT traits refresh observable
    this.nftTraitsRefreshSubscription = this.nftTraitsService.nftTraitsRefreshFlag$.subscribe((shouldRefresh: boolean) => {
      if (shouldRefresh) {
        // Fetch all the NFT traits when refresh is triggered
        this.getAllTraits();
        // Emit a null value to reset the refresh flag after fetching the traits
        this.nftTraitsService.emitNftTraitsRefreshFlag(null);
      }
    });
  }


  ngOnDestroy(){
    this.nftTraitsRefreshSubscription.unsubscribe();
  }
}
