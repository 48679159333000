import { Component, ErrorHandler, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

import { ClipboardService } from 'ngx-clipboard';
import { AccessControlContractService } from 'src/app/services/access-control-contract.service';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import Web3 from 'web3';

const web3 = new Web3(window['ethereum']);


@Component({
  selector: 'app-whitelist-management',
  templateUrl: './whitelist-management.component.html',
  styleUrls: ['./whitelist-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WhitelistManagementComponent {
  @ViewChild("removeWhitelist", {static: false}) removeWhitelist: TemplateRef<any>;
  regulated: boolean = false
  public whiteListForm: FormGroup;
  public whiteListFormSubmitted: boolean = false;
  public account: any = {};
  public transactionWhiteListHash: string = '';
  public whiteListAddress: any = [];
  public splitPattern = /[,; ]/;
  public whiteListLoader: boolean = false;
  public transhUrl: string = '';
  whitelisted: any[] = [];
  whitelistedAddress: any;
  public searchValue:string;
  excludeWhitelistLoader: boolean = false;
  loader:boolean = true;
  
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  
  dtWhitelistTrigger: Subject<any> = new Subject();

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private accessControlContractService: AccessControlContractService,
    private clipboardservice:ClipboardService,
    private manageTransactionsDirective:ManageTransactionsDirective,
    private errorHandler: ErrorHandler
  ){

  }
   /**
   * Initial Loader
   */
   public ngOnInit(): void {
    this.regulated = JSON.parse(this.storageService.getItem('regulated'));
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);

    this.whiteListForm = this.formBuilder.group({
      walletAddress: ['', [Validators.required]],
    }); 
setTimeout(() => {
  this.getWhitelistedAddress();
  this.transhUrl = environment[this.account.state.data.chain.id].TRANSH_URL;
}, 1000);

  }

  /*** 
   * modal view
  */
  public whitelistModalOpen(content:any) {
    this.modalService.open(content, {size: 'lg', centered: true, backdrop : 'static', keyboard : false });
  }

  get whiteListFormGet() {
    return this.whiteListForm.controls;
  }

  /**
   * Whites list collection submit
   * @returns
   */
  public async whiteListSubmit() {
    this.whiteListFormSubmitted = true;
    if (this.whiteListForm.invalid) {
      return;
    }
    this.whiteListLoader = true;
    let totalLength = this.whiteListForm.controls.walletAddress.value.length;
    let whiteListAddress = [];

    await this.whiteListForm.controls.walletAddress.value.forEach(async (element, index) => {
      let addressTrim = await element.value.trim();
      let addressCheck = await web3.utils.toChecksumAddress(addressTrim).toString();
      whiteListAddress.push(addressCheck)
      if (addressCheck != '' && addressCheck != undefined && addressCheck != null) {
        if ((index + 1) == totalLength) {
          this.whiteListProcess(whiteListAddress);
        }
      }
    });

  }

  /**
   * Whites list process
   * @param {array} walletAddress
   */
  public async whiteListProcess(walletAddress) {
    try{
    let { whiteListAbi, requiredGas } = await this.accessControlContractService.includeAllInWhiteList(walletAddress, this.account.state.data.account);
    const message = {
      to: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
      data: whiteListAbi,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(requiredGas * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async (receipt) => {
        this.toastr.success('Whitelisted Successfully.');
        this.whiteListLoader = false;
        this.whiteListFormSubmitted = false;
        this.whiteListAddress = [];
        this.modalService.dismissAll();
        this.getWhitelistedAddress();
        
      })
      .catch((error) => {
        this.whiteListLoader = false;
      });
    }
    catch(error){
      this.whiteListLoader = false;
      this.errorHandler.handleError(error);
    }

  }

  /**
     * Track by function for ngFor loops
     * @param index
     * @param item
     */
  public trackByFn(index: number, item: any): any {
    return item._id || index;
  }

  /**
   * Gets whitelisted address
   */
  getWhitelistedAddress() {
    this.loader = true;
    this.whitelisted = [];
    this.accessControlContractService.getWhitelistedAddress().then(async(response) => {
      for (let index = 0; index < response.length; index++) {
        let params = {
          id: index+1,
          address: response[index],
          loading: false
        }
        this.whitelisted.push(params);
      }
      this.loader = false;
    }).catch((_error) => {
      this.loader = false;
    });
    
   
  }

  /**
   * copy collection wallet address
   */
  copyAddress(add:string){
    this.clipboardservice.copyFromContent(add);
    this.toastr.success('Copied address');
  }

  /**
   * Removes whitelist address
   * @param {number} id 
   */
  removeWhitelistAddress(id:number){
    this.whitelistedAddress = this.whitelisted[id - 1];
    this.modalService.open(this.removeWhitelist, { centered: true, backdrop : 'static', keyboard : false });
  }

  async excludeFromWhitelist() {
    try{
    let whitelisted = this.whitelistedAddress;
    whitelisted.loading = true;
    this.excludeWhitelistLoader = true;
    let { whiteListAbi, requiredGas } = await this.accessControlContractService.excludeFromWhitelist(whitelisted.address, this.account.state.data.account);
    const message = {
      to: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
      data: whiteListAbi,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(requiredGas * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async () => {
        this.toastr.success('Excluded from whitelist.');
        this.excludeWhitelistLoader = false;
        this.modalService.dismissAll();
        this.getWhitelistedAddress();
      })
      .catch((error) => {
        this.excludeWhitelistLoader = false;
      });
    }
    catch(error){
      this.excludeWhitelistLoader = false;
      this.errorHandler.handleError(error);
    }
  }

}
