import { Injectable } from '@angular/core';
import Web3 from 'web3';
import { environment } from 'src/environments/environment';
import {  readContract} from '@wagmi/core';
import { encodeFunctionData } from 'viem';
import { publicClientConfig } from './publicClientConfig.service';
import { StorageService } from './storage.service';


const accessControlAbi = require('src/app/core/common/abis/access.json');
const web3 = new Web3(window['ethereum']);


@Injectable({
  providedIn: 'root'
})
export class AccessControlContractService {
  contract: any;
  account: any
  private publicClient:any;
  
  constructor(
    public publicClientConfig:publicClientConfig,
    private storageService:StorageService
    ) {

   this.onInit();
  }

  /**
   * init on
   */
  public onInit() {
    setTimeout(() => {
      this.accountConfig();
    }, 1000);
  }

  /**
   * Accounts config
   */
  accountConfig(){
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.publicClient = this.publicClientConfig.publicClient(this.account.state.data.chain.id);
  }

  async getWhitelistedAddress() {
    await this.accountConfig();
    const chainId = this.account?.state?.data?.chain?.id;
    return await readContract({
      address: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
      abi: accessControlAbi,
      chainId,
      functionName: 'getWhitelistedAddress',
      args: []
    });
  }

  /**
   * Includes all in white list
   * @param {array} collectionAddress
   * @returns
   */
  public async includeAllInWhiteList(collectionAddress, walletAddress: string) {
    let requiredGas;
    let whiteListAbi;

    const contractParams = {
      address: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
      abi: accessControlAbi,
      functionName: 'includeAllInWhitelist',
      args: [collectionAddress],
    }

    requiredGas = await this.publicClient.estimateContractGas({
      address: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
      account: walletAddress,
      ...contractParams
    });

    whiteListAbi = await encodeFunctionData({
      ...contractParams
    })

    requiredGas = requiredGas.toString();
    return { whiteListAbi, requiredGas };
  }
  
  /**
   * Remove from whitelist
   * @param collectionAddress
   * @returns
   */
  public async excludeFromWhitelist(collectionAddress, walletAddress: string) {
    let requiredGas;
    let whiteListAbi;
    const contractParams = {
      address: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
      abi: accessControlAbi,
      functionName: 'excludeFromWhitelist',
      args: [collectionAddress],
    }
     requiredGas = await this.publicClient.estimateContractGas({
      address: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
      account: walletAddress,
      ...contractParams
    });

    whiteListAbi = await encodeFunctionData({
      ...contractParams
    })

    requiredGas = requiredGas.toString();
    return { whiteListAbi, requiredGas };
  }
}
