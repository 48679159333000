<div class="section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <app-card cardTitle="Contract details (common to Custodial and Self-Custody environments)." [options]="false">
          <span></span>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <td width="30%">Owner address.</td>
                <td>
                  <div *ngIf="!ownerAddress" class="mb-0 pt-1">
                    <ngx-skeleton-loader [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'12px'}"
                    ></ngx-skeleton-loader>
                  </div>
                  <div *ngIf="ownerAddress" class="d-flex gap-2">
                    <a href="{{explorerUrl}}{{ownerAddress}}" target="_blank" class="contract-text">{{ownerAddress}}</a>
                    <span class="cursor-pointer copyfile" (click)="copyAddress(ownerAddress)"><svg viewBox="0 0 17 16"
                        width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                        xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                        anim:duration="1" anim:ease="ease-in-out">
                        <g id="Frame">
                          <path id="Vector"
                            d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path id="Vector_2"
                            d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg></span>
                  </div> 
                </td>
              </tr>
              <tr>
                <td>Access control contract.</td>
                <td>
                  <div *ngIf="!accessControlAddress" class="mb-0 pt-1">
                    <ngx-skeleton-loader [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'12px'}"
                    ></ngx-skeleton-loader>
                  </div>
                  <div *ngIf="accessControlAddress" class="d-flex gap-2">
                    <a href="{{explorerUrl}}{{accessControlAddress}}" target="_blank"
                      class="contract-text">{{accessControlAddress}}</a>
                    <span class="cursor-pointer copyfile" (click)="copyAddress(accessControlAddress)"><svg
                        viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                        xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                        anim:duration="1" anim:ease="ease-in-out">
                        <g id="Frame">
                          <path id="Vector"
                            d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path id="Vector_2"
                            d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Factory contract.</td>
                <td>
                  <div *ngIf="!factoryAddress" class="mb-0 pt-1">
                    <ngx-skeleton-loader [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'12px'}"
                    ></ngx-skeleton-loader>
                  </div>
                  <div *ngIf="factoryAddress" class="d-flex gap-2">
                    <a href="{{explorerUrl}}{{factoryAddress}}" target="_blank"
                      class="contract-text">{{factoryAddress}}</a>
                    <span class="cursor-pointer copyfile" (click)="copyAddress(factoryAddress)"><svg viewBox="0 0 17 16"
                        width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                        xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                        anim:duration="1" anim:ease="ease-in-out">
                        <g id="Frame">
                          <path id="Vector"
                            d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path id="Vector_2"
                            d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Borrow & Lend contract.</td>
                <td>
                  <div *ngIf="!borrowLendAddress" class="mb-0 pt-1">
                    <ngx-skeleton-loader [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'12px'}"
                    ></ngx-skeleton-loader>
                  </div>
                  <div *ngIf="borrowLendAddress" class="d-flex gap-2">
                    <a href="{{explorerUrl}}{{borrowLendAddress}}" target="_blank"
                      class="contract-text">{{borrowLendAddress}}</a>
                    <span class="cursor-pointer copyfile" (click)="copyAddress(borrowLendAddress)"><svg
                        viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                        xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                        anim:duration="1" anim:ease="ease-in-out">
                        <g id="Frame">
                          <path id="Vector"
                            d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path id="Vector_2"
                            d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Exchange contract.</td>
                <td>
                  <div *ngIf="!exchangeAddress" class="mb-0 pt-1">
                    <ngx-skeleton-loader [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'12px'}"
                    ></ngx-skeleton-loader>
                  </div>
                  <div *ngIf="exchangeAddress" class="d-flex gap-2">
                    <a href="{{explorerUrl}}{{exchangeAddress}}" target="_blank"
                      class="contract-text">{{exchangeAddress}}</a>
                    <span class="cursor-pointer copyfile" (click)="copyAddress(exchangeAddress)"><svg
                        viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                        xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                        anim:duration="1" anim:ease="ease-in-out">
                        <g id="Frame">
                          <path id="Vector"
                            d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path id="Vector_2"
                            d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Lazymint contract.</td>
                <td>
                  <div *ngIf="!lazyMintAddress" class="mb-0 pt-1">
                    <ngx-skeleton-loader [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'12px'}"
                    ></ngx-skeleton-loader>
                  </div>
                  <div *ngIf="lazyMintAddress" class="d-flex gap-2">
                    <a href="{{explorerUrl}}{{lazyMintAddress}}" target="_blank"
                      class="contract-text">{{lazyMintAddress}}</a>
                    <span class="cursor-pointer copyfile" (click)="copyAddress(lazyMintAddress)"><svg
                        viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                        xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                        anim:duration="1" anim:ease="ease-in-out">
                        <g id="Frame">
                          <path id="Vector"
                            d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path id="Vector_2"
                            d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>ERC721 contract.</td>
                <td>
                  <div *ngIf="!erc721Address" class="mb-0 pt-1">
                    <ngx-skeleton-loader [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'12px'}"
                    ></ngx-skeleton-loader>
                  </div>
                  <div *ngIf="erc721Address" class="d-flex gap-2">
                    <a href="{{explorerUrl}}{{erc721Address}}" target="_blank"
                      class="contract-text">{{erc721Address}}</a>
                    <span class="cursor-pointer copyfile" (click)="copyAddress(erc721Address)"><svg viewBox="0 0 17 16"
                        width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                        xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                        anim:duration="1" anim:ease="ease-in-out">
                        <g id="Frame">
                          <path id="Vector"
                            d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path id="Vector_2"
                            d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg></span>
                  </div>
                </td>
              </tr> 
            </table>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</div>