import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  constructor(private http: HttpClient) { }

  /**
   * Gets collections
   * @param page 
   * @param limit 
   * @returns  
   */
  public getCollections(page:number,limit:number) {
    return this.http.get(`${environment.API_BASE_URL}/admin/collections?page=${page}&limit=${limit}`);
  }

  public getCollectionsByAddress(address: string) {
    return this.http.get(`${environment.API_BASE_URL}/admin/get-collection?address=${address}`);
    
  }
}
